import * as TYPES from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  collections: [],
  totalCollections: 0,
  collection: {},
  collectionItems: 0,
};

const Collections = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Collection */
    case TYPES.GET_COLLECTION:
    case TYPES.GET_COLLECTION_BY_ADDRESS:
      return {
        ...state,
        error: "",
        loading: true,
        collection: {},
      };

    case TYPES.GET_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        collection: {},
      };

    case TYPES.GET_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        collection: action.payload.data,
        collectionItems: action.payload.collectionItems,
      };

    default:
      return state;
  }
};

export default Collections;
