import React from "react";

import Header from "../Component/Header/Header";
import Inventory from "../Pages/Inventory/Inventory";
import Footer from "../Component/Footer/Footer";

export default function Inventorytheme() {
  return (
    <>
      <Header />
      <Inventory />
      <Footer/>
    </>
  );
}
