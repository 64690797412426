// Login Actions Types
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_WITH_WALLET = "LOGIN_WITH_WALLET";
export const LOGIN_WITH_WALLET_SUCCESS = "LOGIN_WITH_WALLET_SUCCESS";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//Logout Admin
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

// Settings
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

// Update user
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

// Update user
export const GET_PAGE_CONTENT = "GET_PAGE_CONTENT";
export const GET_PAGE_CONTENT_SUCCESS = "GET_PAGE_CONTENT_SUCCESS";
export const GET_PAGE_CONTENT_FAIL = "GET_PAGE_CONTENT_FAIL";
