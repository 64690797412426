import * as TYPES from "./actionTypes";

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}"
);

const initialState = {
  error: "",
  loading: false,
  user: data || {},
  authToken: null,
  settings: null,
  pageContent: {},
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN_USER:
      case TYPES.LOGIN_WITH_WALLET:
    case TYPES.GET_PAGE_CONTENT:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.LOGIN_SUCCESS:
      case TYPES.LOGIN_WITH_WALLET_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data.Token,
        loading: false,
      };

    case TYPES.UPDATE_USER:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.LOGOUT_USER:
      return { ...state };

    case TYPES.LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, authToken: null };

    case TYPES.API_ERROR:
      return { ...state, error: action.payload, loading: false };

    case TYPES.GET_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    // case TYPES.GET_CURRENT_USER_WALLET_ADDRESS:
    //   return {
    //     ...state,
    //     currentWalletAddress: action.payload,
    //   };

    case TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };

    case TYPES.GET_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        pageContent: action.payload,
      };

    case TYPES.GET_SETTINGS_FAIL:
    case TYPES.GET_PAGE_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return { ...state };
  }
  return state;
};

export default login;
