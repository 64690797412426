import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "../actions";
import * as API from "../../helpers/backend_helper";

function* fetchCollection({ payload: { id, callback } }) {
  try {
    const response = yield call(API.getCollection, id);
    
    if (response.status === "failure") {
      return yield put(ACTIONS.getCollectionFail(response.message));
    }
    
    callback && callback(response);
    
    yield put(ACTIONS.getCollectionSuccess(response));
  } catch (error) {
    console.log("error", error);
    console.log(error,45544545);
    yield put(ACTIONS.getCollectionFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(TYPES.GET_COLLECTION, fetchCollection);
}

export default CollectionsSaga;
