import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function Logoutmodal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal logout_mod"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={props.onhide}
            className="close-btn"
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>

          <div className="modal_buy-cont">
            <h4>Log Out</h4>
            <hr class="custom_hr"></hr>
            <p>Are you sure to log out?</p>
            <div className="modal_buy_btn">
              <Button className="mod_cancelbtn" onClick={props.onhide}>Cancel</Button>
              <Button className="mod_confirmbtn" onClick={props.logoutclick}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
