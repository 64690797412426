import React from "react";

import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Withdraw from "Pages/Withdraw";

export default function Withdrawtheme() {
  return (
    <>
      <Header />
      <Withdraw/>
      <Footer/>
    </>
  );
}
