import { combineReducers } from "redux";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";

//User
import User from "./user/reducer";

//Items
import Items from "./items/reducer";

//Categories
import Category from "./category/reducer";

//Collection
import Collection from "./collection/reducer";

//File
import File from "./File/reducer";

const rootReducer = combineReducers({
  Alert,
  Login,
  File,
  Register,
  User,
  Items,
  Category,
  Collection,
});

export default rootReducer;
