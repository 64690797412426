import React from "react";

import Header from "../Component/Header/Header";
import EditProfile from "../Pages/EditProfile/Editprofile";
import Footer from "../Component/Footer/Footer";

export default function Profiletheme() {
  return (
    <>
      <Header />
      <EditProfile />
      <Footer/>
    </>
  );
}
