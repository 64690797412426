import * as TYPES from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  categories: [],
  totalCategories: 0,
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get categories */
    case TYPES.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.data || [],
        totalCategories: action?.payload?.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /* Get categories END */

    default:
      return state;
  }
};

export default Items;
