import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import History from "./history";
import { Link, useNavigate } from "react-router-dom";
import Recievemodal from "../../Component/Modals/Recievemodal";
import Sendmodal from "../../Component/Modals/Sendmodal";
import Tranferconfirm from "../../Component/Modals/Tranferconfirm";
import Succestransfer from "../../Component/Modals/Succestransfer";
import Swapmodal from "../../Component/Modals/Swapmodal";
import { copyToClipboard } from "../../helpers/constants";
import VerifyMPIN from "../../Component/Modals/VerifyMPIN";
import wallet from "../../hooks/metamask";

import { useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function SendRecive() {
  const navigate = useNavigate();
  const [recievemod, setrecievemod] = useState(false);
  const [swapmodal, setswapmodal] = useState(false);
  const [verifyPin, setverifyPin] = useState(false);
  const [types, settypes] = useState("");
  const [sendmod, setsendmod] = useState(false);
  const { userDetails } = useSelector((state) => state.User);
  const { settings } = useSelector((state) => state.Login);

  const [sendconfirmed, setsendconfirmed] = useState(false);
  const [successtranfer, setsuccesstranfer] = useState(false);
  const [balance, setbalance] = useState({ PPT_balance: 0, balance: 0 });
  const [walletaddresscopy, setwalletaddresscopy] = useState(true);
  const [tokenAddress, setTokenAddress] = useState("");
  const [addresscopy, setaddress] = useState(false);
  const userWallet = wallet();

  const addrecpoied = (address) => {
    setwalletaddresscopy(false);
    copyToClipboard(address);
    setaddress(true);
    setTimeout(() => {
      setwalletaddresscopy(true);
      setaddress(false);
    }, 1000);
  };

  const togmodconfirm = () => {
    setsendconfirmed(!sendconfirmed);
    setTimeout(() => {
      setsendmod(!sendmod);
    }, 400);
  };

  const sendconfirmedtog = () => {
    setsendconfirmed(!sendconfirmed);
    setTimeout(() => {
      setsuccesstranfer(!successtranfer);
    }, 400);
    setTimeout(() => {
      setsuccesstranfer(false);
    }, 3000);
  };

  // let fetchBalance = async () => {
  //   console.log(userWallet, "userWallet")
  //   let balance = await userWallet?.balance;
  //   setbalance({...balance, balance: balance});
  // };
  // useEffect(() => {
  //   // userWallet.connect("metamask", 97);
  //   fetchBalance();
  // }, [userWallet]);
  
  useEffect(() => {
    const contractAddress = settings?.token?.contractAddress?.find(addr => addr.type == "BNB");
    setTokenAddress(contractAddress && contractAddress[settings?.blockchainNetworkMode])
  }, [settings])

  const importPPTToken = async(e) => {
    try {
      const { ethereum } = window;
      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress, // ERC20 token address
            symbol: settings?.token?.symbol,
            decimals: 18,
            image:
              "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
          }
        }
      });
    } catch (ex) {
      console.error(ex);
    }
  };
  return (
    <>
      {/* <Recievemodal show={recievemod} onhide={() => setrecievemod(false)} />
      <Sendmodal
        show={sendmod}
        onhide={() => setsendmod(false)}
        togmodconfirm={togmodconfirm}
      />

      <Succestransfer
        show={successtranfer}
        onhide={() => setsuccesstranfer(false)}
      />
      <VerifyMPIN
        show={verifyPin}
        type={types}
        setswapmodal={setswapmodal}
        setsendmod={setsendmod}
        isMpinExist={userDetails?.mpin}
        onHide={() => setverifyPin(!verifyPin)}
      />
      <Swapmodal show={swapmodal} onhide={() => setswapmodal(false)} /> */}
      <div className="mainpage-wrap position-relative">
        <div className="overlay_col"></div>

        <Fade>
          <section className="SendRecive_main position-relative common_padding_main">
            <Container>
              <div className="sendrecieve_main position-relative">
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(-1)}
                  className="wallet_back"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g filter="url(#filter0_di_1_7208)">
                      <path
                        d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                        fill="url(#paint0_linear_1_7208)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_di_1_7208"
                        x="0"
                        y="0"
                        width="28"
                        height="28"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_7208"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_7208"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect2_innerShadow_1_7208"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_7208"
                        x1="14"
                        y1="1"
                        x2="14"
                        y2="23"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#46C7A8" />
                        <stop
                          offset="1"
                          stop-color="#46C7A8"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  Back
                </a>

                <div className="cryto_name_top position-relative">
                  <div className="chain_name">{userWallet.blockchain=="polygon"?"Polygon" : "BNB"}</div>
                  <div className="cryto_name">
                    <p>
                      <span className="wallet_balnce_onmobile">
                        {parseFloat(Number(balance?.balance).toFixed(5))}{" "}
                      </span>
                      {userWallet.blockchain=="polygon"?"MATIC" : "BNB"}
                    </p>
                  </div>
                  <div className="copy_Code">
                    <div className="code_here position-relative">
                      <p>
                        {userWallet.account.slice(0, 8)}...
                        {userWallet.account.slice(35)}
                      </p>

                      <div className="adress-here">
                        {addresscopy === true ? (
                          <div className="copuaddress_msg">
                            <p>Address Copied</p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {walletaddresscopy === true ? (
                      <>
                        <Button
                          className="copy_code_btn"
                          onClick={() => addrecpoied(userWallet.account)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1_10707)">
                              <g filter="url(#filter0_d_1_10707)">
                                <path
                                  d="M12.8253 1.5H9.67531C7.08781 1.5 6.03781 2.5275 6.00781 5.0625H8.32531C11.4753 5.0625 12.9378 6.525 12.9378 9.675V11.9925C15.4728 11.9625 16.5003 10.9125 16.5003 8.325V5.175C16.5003 2.55 15.4503 1.5 12.8253 1.5Z"
                                  fill="#79E7FB"
                                />
                              </g>
                              <g filter="url(#filter1_d_1_10707)">
                                <path
                                  d="M8.325 6H5.175C2.55 6 1.5 7.05 1.5 9.675V12.825C1.5 15.45 2.55 16.5 5.175 16.5H8.325C10.95 16.5 12 15.45 12 12.825V9.675C12 7.05 10.95 6 8.325 6ZM9.2175 10.2375L6.435 13.02C6.38497 13.0704 6.32536 13.1104 6.25967 13.1374C6.19398 13.1645 6.12354 13.1781 6.0525 13.1775C5.98146 13.1781 5.91102 13.1645 5.84533 13.1374C5.77964 13.1104 5.72003 13.0704 5.67 13.02L4.275 11.625C4.22507 11.5754 4.18544 11.5164 4.1584 11.4514C4.13136 11.3864 4.11744 11.3167 4.11744 11.2463C4.11744 11.1758 4.13136 11.1061 4.1584 11.0411C4.18544 10.9761 4.22507 10.9171 4.275 10.8675C4.485 10.6575 4.8225 10.6575 5.0325 10.8675L6.045 11.88L8.4525 9.4725C8.6625 9.2625 9 9.2625 9.21 9.4725C9.42 9.6825 9.4275 10.0275 9.2175 10.2375Z"
                                  fill="#53BCCF"
                                />
                              </g>
                            </g>
                            <defs>
                              <filter
                                id="filter0_d_1_10707"
                                x="2.00781"
                                y="1.5"
                                width="18.4922"
                                height="18.4926"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_1_10707"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_1_10707"
                                  result="shape"
                                />
                              </filter>
                              <filter
                                id="filter1_d_1_10707"
                                x="-2.5"
                                y="6"
                                width="18.5"
                                height="18.5"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_1_10707"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_1_10707"
                                  result="shape"
                                />
                              </filter>
                              <clipPath id="clip0_1_10707">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Copy
                        </Button>
                      </>
                    ) : (
                      <>
                        <div className="address_copyicon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 28 24"
                            fill="none"
                          >
                            <g filter="url(#filter0_d_14_139)">
                              <path
                                d="M16.65 0H10.35C5.1 0 3 2.1 3 7.35V13.65C3 18.9 5.1 21 10.35 21H16.65C21.9 21 24 18.9 24 13.65V7.35C24 2.1 21.9 0 16.65 0ZM18.435 8.475L12.87 14.04C12.7699 14.1409 12.6507 14.2207 12.5193 14.2748C12.388 14.3289 12.2471 14.3562 12.105 14.355C11.9629 14.3562 11.822 14.3289 11.6907 14.2748C11.5593 14.2207 11.4401 14.1409 11.34 14.04L8.55 11.25C8.45013 11.1507 8.37088 11.0327 8.3168 10.9027C8.26272 10.7727 8.23487 10.6333 8.23487 10.4925C8.23487 10.3517 8.26272 10.2123 8.3168 10.0823C8.37088 9.95228 8.45013 9.83426 8.55 9.735C8.97 9.315 9.645 9.315 10.065 9.735L12.09 11.76L16.905 6.945C17.325 6.525 18 6.525 18.42 6.945C18.84 7.365 18.855 8.055 18.435 8.475Z"
                                fill="#46C7A8"
                              />
                            </g>
                            <defs>
                              <filter
                                id="filter0_d_14_139"
                                x="-1"
                                y="0"
                                width="29"
                                height="29"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_14_139"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_14_139"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <hr className="custom_hr" />

                <div className="send_reciece_btns">
                  {/* <div className="btn-one">
                    <Button
                      className={
                        recievemod ? "btn-common active" : "btn-common"
                      }
                      onClick={() => setrecievemod(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="51"
                        viewBox="0 0 55 51"
                        fill="none"
                      >
                        <path
                          d="M21.7715 27.9375C21.7715 30.12 23.4902 31.875 25.5986 31.875H29.9069C31.7402 31.875 33.2298 30.345 33.2298 28.4325C33.2298 26.385 32.3132 25.6425 30.9611 25.17L24.0632 22.8075C22.7111 22.335 21.7944 21.615 21.7944 19.545C21.7944 17.655 23.284 16.1025 25.1173 16.1025H29.4257C31.534 16.1025 33.2527 17.8575 33.2527 20.04M27.5007 13.875V34.125"
                          stroke="#46C7A8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <g filter="url(#filter0_d_135_2)">
                          <path
                            d="M50.4173 24C50.4173 36.42 40.1506 46.5 27.5006 46.5C14.8507 46.5 4.58398 36.42 4.58398 24C4.58398 11.58 14.8507 1.5 27.5006 1.5"
                            stroke="#46C7A8"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            shape-rendering="crispEdges"
                          />
                        </g>
                        <path
                          d="M38.959 3.75V12.75M38.959 12.75H48.1257M38.959 12.75L50.4173 1.5"
                          stroke="#46C7A8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_135_2"
                            x="-0.416016"
                            y="0.5"
                            width="55.834"
                            height="55"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.0866667 0 0 0 0 0.202381 0 0 0 0 0.266667 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_135_2"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_135_2"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </Button>
                    <p>Recieve</p>
                  </div> */}

                  {/* <div className="btn-one">
                    <Button
                      className={sendmod ? "btn-common active" : "btn-common"}
                      onClick={() => {
                        if (userDetails?.mpin == "none") {
                          toast.error(
                            "Please Set a MPIN first from your profile"
                          );
                        } else {
                          setverifyPin(true);
                          settypes("send");
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_10721)">
                          <path
                            d="M20.9785 28.3646C20.9785 30.5067 22.6348 32.2292 24.6664 32.2292H28.8181C30.5848 32.2292 32.0202 30.7275 32.0202 28.8504C32.0202 26.8408 31.1368 26.1121 29.8339 25.6483L23.1868 23.3296C21.8839 22.8658 21.0006 22.1592 21.0006 20.1275C21.0006 18.2725 22.436 16.7487 24.2027 16.7487H28.3543C30.386 16.7487 32.0423 18.4712 32.0423 20.6133M26.4993 14.5625V34.4375"
                            stroke="#46C7A8"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M48.5827 13.25V4.41663M48.5827 4.41663H39.7493M48.5827 4.41663L37.541 15.4583"
                            stroke="#46C7A8"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <g filter="url(#filter0_d_1_10721)">
                            <path
                              d="M48.1667 24.0833C48.1667 36.2733 38.2733 46.1667 26.0833 46.1667C13.8933 46.1667 4 36.2733 4 24.0833C4 11.8933 13.8933 2 26.0833 2"
                              stroke="#46C7A8"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              shape-rendering="crispEdges"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1_10721"
                            x="-1"
                            y="1"
                            width="54.166"
                            height="54.1666"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1_10721"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1_10721"
                              result="shape"
                            />
                          </filter>
                          <clipPath id="clip0_1_10721">
                            <rect width="53" height="53" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Button>
                    <p>Send</p>
                  </div> */}
                  {/* <div className="btn-one">
                    <Button
                      className={sendmod ? "btn-common active btn-import" : "btn-common btn-import"}
                      onClick={importPPTToken}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_10721)">
                          <path
                            d="M20.9785 28.3646C20.9785 30.5067 22.6348 32.2292 24.6664 32.2292H28.8181C30.5848 32.2292 32.0202 30.7275 32.0202 28.8504C32.0202 26.8408 31.1368 26.1121 29.8339 25.6483L23.1868 23.3296C21.8839 22.8658 21.0006 22.1592 21.0006 20.1275C21.0006 18.2725 22.436 16.7487 24.2027 16.7487H28.3543C30.386 16.7487 32.0423 18.4712 32.0423 20.6133M26.4993 14.5625V34.4375"
                            stroke="#46C7A8"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M48.5827 13.25V4.41663M48.5827 4.41663H39.7493M48.5827 4.41663L37.541 15.4583"
                            stroke="#46C7A8"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <g filter="url(#filter0_d_1_10721)">
                            <path
                              d="M48.1667 24.0833C48.1667 36.2733 38.2733 46.1667 26.0833 46.1667C13.8933 46.1667 4 36.2733 4 24.0833C4 11.8933 13.8933 2 26.0833 2"
                              stroke="#46C7A8"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              shape-rendering="crispEdges"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1_10721"
                            x="-1"
                            y="1"
                            width="54.166"
                            height="54.1666"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1_10721"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1_10721"
                              result="shape"
                            />
                          </filter>
                          <clipPath id="clip0_1_10721">
                            <rect width="53" height="53" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Button>
                    <p>Import PPT</p>
                  </div> */}
                  <div className="btn-one">
                    {/* <Button
                      className={swapmodal ? "btn-common active" : "btn-common"}
                      onClick={() => {
                        if (userDetails?.mpin == "none") {
                          toast.error(
                            "Please Set a MPIN first from your profile"
                          );
                        } else {
                          setverifyPin(true);
                          settypes("swap");
                        }
                      }}
                    > */}
                    {/* <Link to={`https://pancakeswap.finance/swap?chain=bsc${settings?.blockchainNetworkMode=="testnet" && "Testnet"}&outputCurrency=${tokenAddress}`} target={"_blank"} className={sendmod ? "btn-common active" : "btn-common"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_10725)">
                          <g filter="url(#filter0_d_1_10725)">
                            <path
                              d="M45.2702 33.1029L34.2064 44.1888M7.72852 33.1029H45.2702M7.72852 19.8971L18.7923 8.81128M45.2702 19.8971H7.72852"
                              stroke="#46C7A8"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              shape-rendering="crispEdges"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1_10725"
                            x="2.72852"
                            y="7.81128"
                            width="47.541"
                            height="45.3774"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1_10725"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1_10725"
                              result="shape"
                            />
                          </filter>
                          <clipPath id="clip0_1_10725">
                            <rect width="53" height="53" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                    <p>Swap</p> */}
                  </div>
                </div>
{/* 
                <hr className="custom_hr" />

                <History /> */}
              </div>
            </Container>
          </section>
        </Fade>
      </div>
    </>
  );
}
