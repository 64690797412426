import React, { useState, useEffect } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//store
import { getInventoryItemsRequest } from "../../store/actions";

export default function Inventorydash() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { inventoryItems, loading } = useSelector((state) => state.Items);
  const { userDetails } = useSelector((state) => state.User);
  console.log(userDetails, userDetails?._id, "userDetails");

  const [state, setState] = useState({
    tab: !!searchParams.get("type") ? searchParams.get("type") : "All",
  });
  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "_id",
    order: 1,
    page: 1,
    levels: 4,
    subcategory_id: "",
    collectionName: !!searchParams.get("type")
      ? searchParams.get("type")
      : "All",
    shipsLevelStart: 1,
    shipsLevelEnd: 49,
    price: "",
    current_owner: userDetails?._id,
  });
  useEffect(() => {
    if (userDetails?.walletAddress) {
      dispatch(getInventoryItemsRequest({ ...filter, current_owner: userDetails?.walletAddress }));
    }
  }, [filter, userDetails]);


  const style = (item)=> {
    console.log("item", item?.level, item?.category_id?.catName)
    if(item?.category_id?.catName === "Ships"){
      if(item.level < 10){
        return { backgroundImage: `url(/images/lvl1Ships.png`}
      }
      else if(item.level >= 10 && item.level < 20){
        return { backgroundImage: `url(/images/lvl2Ships.png`}
      }
      else if(item.level >= 20 && item.level < 30){
        return { backgroundImage: `url(/images/lvl3Ships.png`}
      }
      else if(item.level >= 30 && item.level < 40){
        return { backgroundImage: `url(/images/lvl4Ships.png`}
      }
      else if(item.level >= 40 && item.level < 50){
        return { backgroundImage: `url(/images/lvl5Ships.png`}
      }
    }
    else if (item?.category_id?.catName === "Pirates"){
      if(item.level === 1){
        return { backgroundImage: `url(/images/lvl1pirate.png`}
      }
      else if(item.level === 2){
        return { backgroundImage: `url(/images/lvl2pirate.png`}
      }
      else if(item.level === 3){
        return { backgroundImage: `url(/images/lvl3pirate.png`}
      }
      else if(item.level === 4){
        return { backgroundImage: `url(/images/lvl4pirate.png`}
      }
      else if(item.level === 5){
        return { backgroundImage: `url(/images/lvl5pirate.png`}
      }
    }
    else if (item?.category_id?.catName === "Others"){
      return { backgroundImage: `url(/images/lvl1Ships.png)`}
    }
  }

  const mainStyle = (item)=> {
    if(item?.category_id?.catName === "Ships"){
      return { background: `url(../../images/${item.level}.png)`}
      // if(item.level < 10){
      //   return { background: `url(/images/Framelvl1.png`}
      // }
      // else if(item.level >= 10 && item.level < 20){
      //   return { background: `url(/images/Framelvl2.png`}
      // }
      // else if(item.level >= 20 && item.level < 30){
      //   return { background: `url(/images/Framelvl3.png`}
      // }
      // else if(item.level >= 30 && item.level < 40){
      //   return { background: `url(/images/Framelvl4.png`}
      // }
      // else if(item.level >= 40 && item.level < 50){
      //   return { background: `url(/images/Framelvl5.png`}
      // }
    }
    else if (item?.category_id?.catName === "Pirates"){
      // if(item.level === 1){
      //   return { background: `url(/images/Framelvl1.png`}
      // }
      // else if(item.level === 2){
      //   return { background: `url(/images/Framelvl2.png`}
      // }
      // else if(item.level === 3){
      //   return { background: `url(/images/Framelvl3.png`}
      // }
      // else if(item.level === 4){
      //   return { background: `url(/images/Framelvl4.png`}
      // }
      // else if(item.level === 5){
      //   return { background: `url(/images/Framelvl5.png`}
      // }
      if(item.level === 1){
        return { background: `url(../../images/Piratebg1.png)`}
      }
      else if(item.level === 2){
        return { background: `url(../../images/Piratebg2.png)`}
      }
      else if(item.level === 3){
        return { background: `url(../../images/Piratebg3.png)`}
      }
      else if(item.level === 4){
        return { background: `url(../../images/Piratebg4.png)`}
      }
      else if(item.level === 5){
        return { background: `url(../../images/Piratebg5.png)`}
      }
    }
    else if (item?.category_id?.catName === "Others"){
      return { background: `url(../../images/Framelvl1.png)`}
    }
  }

  return (
    <section className="market_dash inventory-dash position-relative">
      <>
        <div>
          <h3 className="inventory_heading">Inventory</h3>
        </div>
        <div className="market_dash_tab">
          <div className="send-tabling">
            <div className="tab-frame market_tabs">
              <div className="with_fillte-wrap">
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(-1)}
                  className="inventory_back"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g filter="url(#filter0_di_1_7208)">
                      <path
                        d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                        fill="url(#paint0_linear_1_7208)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_di_1_7208"
                        x="0"
                        y="0"
                        width="28"
                        height="28"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_7208"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_7208"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect2_innerShadow_1_7208"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_7208"
                        x1="14"
                        y1="1"
                        x2="14"
                        y2="23"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#46C7A8" />
                        <stop
                          offset="1"
                          stop-color="#46C7A8"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  Back
                </a>
                <div className="clearfix">
                  <input
                    type="radio"
                    name="tab"
                    id="All"
                    checked={state.tab == "All"}
                    onClick={() => (
                      setState({ tab: "All" }),
                      navigate("/inventory?type=All"),
                      setfilter((prev) => ({
                        ...prev,
                        current_owner: userDetails?._id,
                        collectionName: "All",
                      }))
                    )}
                  />
                  <label for="All">All</label>

                  <input
                    type="radio"
                    name="tab"
                    id="Pirates"
                    checked={state.tab == "Pirates"}
                    onClick={() => (
                      setState({ tab: "Pirates" }),
                      navigate("/inventory?type=Pirates"),
                      setfilter((prev) => ({
                        ...prev,
                        collectionName: "Pirates",
                      }))
                    )}
                  />
                  <label for="Pirates">Pirates</label>

                  <input
                    type="radio"
                    name="tab"
                    id="Ships"
                    checked={state.tab == "Ships"}
                    onClick={() => (
                      setState({ tab: "Ships" }),
                      navigate("/inventory?type=Ships"),
                      setfilter((prev) => ({
                        ...prev,
                        collectionName: "Ships",
                      }))
                    )}
                  />
                  <label for="Ships" className="ship_label">
                    Ships
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="Others"
                    checked={state.tab == "Others"}
                    onClick={() => (
                      setState({ tab: "Others" }),
                      navigate("/inventory?type=Others"),
                      setfilter((prev) => ({
                        ...prev,
                        collectionName: "Others",
                      }))
                    )}
                  />
                  <label for="Others">Others</label>
                </div>
              </div>

              {state.tab == "All" && (
                <Fade>
                  <div
                  // className="left_Market"
                  >
                    {inventoryItems.length > 0 ? (
                      <div className="invenPirates_here common_market_tab all_inventory">
                        <div className="parates_list">
                          <ul>
                            {inventoryItems.map((item) => {
                              return (
                                <li
                                  key={item._id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/item-detail/${item?._id}?fromLocation=inventory`
                                    )
                                  }
                                >
                                  <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                  {/* <div className="parates_label" 
                                  style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                    <img
                                      src={
                                        item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          All inventory
                        </div>
                      </div>
                    ) : (
                      <div className="no_data">No data found</div>
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Pirates" && (
                <Fade>
                  <div
                  // className="left_Market"
                  >
                    {inventoryItems.length > 0 ? (
                      <div className="invenPirates_here common_market_tab">
                        <div className="parates_list">
                          <ul>
                            {inventoryItems.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/item-detail/${item?._id}?fromLocation=inventory`
                                    )
                                  }
                                >
                                  <div className="parates_div_main position-relative"  style={mainStyle(item)}>
                                  {/* <div className="parates_label" 
                                    style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                    <img
                                      src={
                                        item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      !loading && (
                        <div className="no_data" style={{ paddingLeft: "0" }}>
                          No data found
                        </div>
                      )
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Ships" && (
                <Fade>
                  <div>
                    {inventoryItems.length > 0 ? (
                      <div className="invenPirates_here common_market_tab">
                        <div className="parates_list">
                          <ul>
                            {inventoryItems.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/item-detail/${item?._id}?fromLocation=inventory`
                                    )
                                  }
                                >
                                  <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                  {/* <div className="parates_label" 
                                    style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                    <img
                                      src={
                                        item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      !loading && (
                        <div className="no_data" style={{ paddingLeft: "0" }}>
                          No data found
                        </div>
                      )
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Others" && (
                <Fade>
                  <div>
                    <div className="other_tab Pirates_here common_market_tab">
                      <div className="parates_list">
                        {inventoryItems.length > 0 ? (
                          <ul>
                            {inventoryItems.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/item-detail/${item?._id}?fromLocation=inventory`
                                    )
                                  }
                                >
                                  <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                  {/* <div className="parates_label" 
                                    style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                    <img
                                      src={
                                        item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <div className="no_data">No data found</div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </>
    </section>
  );
}
