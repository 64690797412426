import * as TYPES from "./actionTypes"

export const checkEmail = (data, callback) => {
  return {
    type: TYPES.CHECK_EMAIL,
    payload: { data, callback },
  }
}

export const checkEmailSuccessful = email => {
  return {
    type: TYPES.CHECK_EMAIL_SUCCESSFUL,
    payload: email,
  }
}

export const checkEmailFailed = error => {
  return {
    type: TYPES.CHECK_EMAIL_FAILED,
    payload: error,
  }
}

export const registerUserEmailVerify = (data, callback) => {
  return {
    type: TYPES.REGISTER_USER_EMAIL_VERIFY,
    payload: { data, callback },
  }
}

export const registerUserEmailVerifySuccessful = user => {
  return {
    type: TYPES.REGISTER_USER_EMAIL_VERIFY_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserEmailVerifyFailed = user => {
  return {
    type: TYPES.REGISTER_USER_EMAIL_VERIFY_FAILED,
    payload: user,
  }
}

export const registerUser = (data, callback) => {
  return {
    type: TYPES.REGISTER_USER,
    payload: { data, callback },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: TYPES.REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: TYPES.REGISTER_USER_FAILED,
    payload: user,
  }
}
// forget passsword
export const forgetPassword = (data, callback) => {
  return {
    type: TYPES.FORGET_PASSWORD,
    payload: { data, callback },
  }
}

export const forgetPasswordSuccess = user => {
  return {
    type: TYPES.FORGET_PASSWORD_SUCCESS,
    payload: user,
  }
}

export const forgetPasswordFail = user => {
  return {
    type: TYPES.FORGET_PASSWORD_FAIL,
    payload: user,
  }
}
// new passsword
export const setnewPassword = (data, callback) => {
  return {
    type: TYPES.SET_NEW_PASSWORD,
    payload: { data, callback },
  }
}

export const setnewPasswordSuccess = user => {
  return {
    type: TYPES.SET_NEW_PASSWORD_SUCCESS,
    payload: user,
  }
}

export const setnewPasswordFail = user => {
  return {
    type: TYPES.SET_NEW_PASSWORD_FAIL,
    payload: user,
  }
}
