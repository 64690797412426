import {
  VIEW_PROFILE,
  VIEW_PROFILE_SUCCESSFUL,
  VIEW_PROFILE_FAILED,
  REMOVE_PROFILE_IMAGE,
  REMOVE_PROFILE_IMAGE_FAILED,
  REMOVE_PROFILE_IMAGE_SUCCESSFUL,
} from "./actionTypes";
import * as CONST from "./actionTypes";

const initialState = {
  loading: false,
  error: null,
  imgLoading: false,
  userDetails: {},
  balance: {},
  items: [],
  totalItems: 0,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_PROFILE:
      return {
        ...state,
        userDetails: {},
        loading: true,
        registrationError: null,
      };

    case VIEW_PROFILE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        userDetails: action.payload.data,
        error: null,
      };

    case VIEW_PROFILE_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload.message,
      };

    case REMOVE_PROFILE_IMAGE:
      return {
        ...state,
        imgLoading: true,
      };
    case REMOVE_PROFILE_IMAGE_FAILED:
    case REMOVE_PROFILE_IMAGE_SUCCESSFUL:
      return {
        ...state,
        imgLoading: false,
      };
    case CONST.SEND_OTP:
      return {
        ...state,
        loading: true,
      };

    case CONST.SEND_OTP_SUCCESSFUL:
      return {
        ...state,
        loading: false,
      };
    case CONST.SEND_OTP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CONST.CHANGE_MPIN:
      return {
        ...state,
        loading: true,
      };

    case CONST.CHANGE_MPIN_SUCCESSFUL:
      return {
        ...state,
        loading: false,
      };
    case CONST.CHANGE_MPIN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CONST.VERIFY_MPIN:
      return {
        ...state,
        loading: true,
      };

    case CONST.VERIFY_MPIN_SUCCESSFUL:
      return {
        ...state,
        loading: false,
      };
    case CONST.VERIFY_MPIN_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CONST.GET_USER_BALANCE:
      return {
        ...state,
        loading: true,
        registrationError: null,
      };

    case CONST.GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        balance: action.payload.data,
        error: null,
      };

    case CONST.GET_USER_BALANCE_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload.message,
      };

    case CONST.POST_CREATE_WITHDRAW:
      return {
        ...state,
        loading: true,
      };

    case CONST.POST_CREATE_WITHDRAW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CONST.POST_CREATE_WITHDRAW_FAILED:
      return {
        ...state,
        loading: false,
      };


    case CONST.GET_WITHDRAW_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_WITHDRAW_HISTORY_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case CONST.GET_WITHDRAW_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.LIST_CRYSTAL_PACKAGE:
      return {
        ...state,
        loading: true,
      };

    case CONST.LIST_CRYSTAL_PACKAGE_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case CONST.LIST_CRYSTAL_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.GET_PACKAGE_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_PACKAGE_HISTORY_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.total || 0,
        loading: false,
        error: "",
      };

    case CONST.GET_PACKAGE_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };



      case CONST.POST_BUY_PACKAGE:
        return {
          ...state,
          loading: true,
        };
  
      case CONST.POST_BUY_PACKAGE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case CONST.POST_BUY_PACKAGE_FAILED:
        return {
          ...state,
          loading: false,
        };


    default:
      return state;
  }
};

export default User;
