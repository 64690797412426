import React from "react";

import Header from "../Component/Header/Header";
import Productsingle4 from "../Pages/Productsingle4/Productsingle4";
import Footer from "Component/Footer/Footer";

export default function Paratestheme() {
  return (
    <>
      <Header />
      <Productsingle4 />
      <Footer/>
    </>
  );
}
