import * as TYPES from "./actionTypes";

export const apiFails = (error) => ({
  type: TYPES.ITEM_API_FAIL,
  payload: error,
});

/* Get items */
export const getItems = (data) => ({
  type: TYPES.GET_ITEMS,
  payload: data,
});

export const getItemsSuccess = (items) => ({
  type: TYPES.GET_ITEMS_SUCCESS,
  payload: items,
});

export const getItemsFail = (error) => ({
  type: TYPES.GET_ITEMS_FAIL,
  payload: error,
});
/* Get Items END */
/* Count pirates buy */
export const countPirateBuy = (data) => ({
  type: TYPES.COUNT_PIRATE_BUY,
  payload: data,
});

export const countPirateBuySuccess = (items) => ({
  type: TYPES.COUNT_PIRATE_BUY_SUCCESS,
  payload: items,
});

export const countPirateBuyFail = (error) => ({
  type: TYPES.COUNT_PIRATE_BUY_FAIL,
  payload: error,
});
/* Count pirates buy END */
/* Get items */
export const getItems2 = (data) => ({
  type: TYPES.GET_ITEMS2,
  payload: data,
});

export const getItems2Success = (items) => ({
  type: TYPES.GET_ITEMS2_SUCCESS,
  payload: items,
});

export const getItems2Fail = (error) => ({
  type: TYPES.GET_ITEMS2_FAIL,
  payload: error,
});
/* Get Items END */

/* Get Item */
export const getItem = (id) => ({
  type: TYPES.GET_ITEM,
  payload: id,
});

export const resetItem = (id) => ({
  type: TYPES.RESET_ITEM,
  payload: {},
});

export const getItemByToken = (token) => ({
  type: TYPES.GET_ITEM_BY_TOKEN,
  payload: token,
});

export const getItemFail = (error) => ({
  type: TYPES.GET_ITEM_FAIL,
  payload: error,
});

export const getItemSuccess = (item) => ({
  type: TYPES.GET_ITEM_SUCCESS,
  payload: item,
});
/* Get Item END */

/* Post Item Buy */
export const postItemBuy = (data, callback) => ({
  type: TYPES.POST_ITEM_BUY,
  payload: { data, callback },
});

export const postItemBuyFail = (error) => ({
  type: TYPES.POST_ITEM_BUY_FAIL,
  payload: error,
});

export const postItemBuySuccess = (data) => ({
  type: TYPES.POST_ITEM_BUY_SUCCESS,
  payload: data,
});
/* Post Item Buy END */

export const addHistory = (data, callback, updateBalanceOf) => ({
  type: TYPES.ADD_TRANSACTION_HISTORY,
  payload: { data, callback, updateBalanceOf },
});

export const addHistorySuccess = (setting) => ({
  type: TYPES.ADD_TRANSACTION_HISTORY_SUCCESS,
  payload: setting,
});

export const addHistoryFail = () => ({
  type: TYPES.ADD_TRANSACTION_HISTORY_FAIL,
});

//Get transaction history
export const getHistory = (data, callback) => ({
  type: TYPES.GET_TRANSACTION_HISTORY,
  payload: { data, callback },
});

export const getHistorySuccess = (setting) => ({
  type: TYPES.GET_TRANSACTION_HISTORY_SUCCESS,
  payload: setting,
});

export const getHistoryFail = () => ({
  type: TYPES.GET_TRANSACTION_HISTORY_FAIL,
});

//Get ppt price
export const getPptPrice = (callback) => ({
  type: TYPES.GET_PPT_PRICE,
  callback: callback,
});

export const getPptPriceSuccess = (setting) => ({
  type: TYPES.GET_PPT_PRICE_SUCCESS,
  payload: setting,
});

export const getPptPriceFail = () => ({
  type: TYPES.GET_PPT_PRICE_FAIL,
});

//Swap api
export const swapAPI = (data, callback) => ({
  type: TYPES.SWAP_API,
  payload: { data, callback },
});

export const swapAPISuccess = (setting) => ({
  type: TYPES.SWAP_API_SUCCESS,
  payload: setting,
});

export const swapAPIFail = () => ({
  type: TYPES.SWAP_API_FAIL,
});

//Get ppt price For BNB
export const getPptPricePancake = (data, callback) => ({
  type: TYPES.GET_PPT_PRICE_PANCAKE,
  payload: { data, callback },
});

export const getPptPricePancakeSuccess = (setting) => ({
  type: TYPES.GET_PPT_PRICE_PANCAKE_SUCCESS,
  payload: setting,
});

export const getPptPricePancakeFail = () => ({
  type: TYPES.GET_PPT_PRICE_PANCAKE_FAIL,
});

//Swap api BNB
export const pancakeSwapAPI = (data, callback) => ({
  type: TYPES.PANCAKE_SWAP_API,
  payload: { data, callback },
});

export const pancakeSwapAPISuccess = (setting) => ({
  type: TYPES.PANCAKE_SWAP_API_SUCCESS,
  payload: setting,
});

export const pancakeSwapAPIFail = () => ({
  type: TYPES.PANCAKE_SWAP_API_FAIL,
});


//inverntory items
/* Get items */
export const getInventoryItemsRequest = (data) => ({
  type: TYPES.GET_INVENTORY_ITEMS_REQUEST,
  payload: data,
});

export const getInventoryItemsSuccess = (items) => ({
  type: TYPES.GET_INVENTORY_ITEMS_SUCCESS,
  payload: items,
});

export const getInventoryItemsFail = (error) => ({
  type: TYPES.GET_INVENTORY_ITEMS_FAIL,
  payload: error,
});