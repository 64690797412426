import * as TYPES from "./actionTypes";

//Login Admin
export const loginUser = (user, naviagte) => {
  return {
    type: TYPES.LOGIN_USER,
    payload: { user, naviagte },
  };
};

export const loginSuccess = (user) => {
  return {
    type: TYPES.LOGIN_SUCCESS,
    payload: user,
  };
};

//Login with wallet
export const loginWithWallet = (user, naviagte) => {
  return {
    type: TYPES.LOGIN_WITH_WALLET,
    payload: { user, naviagte },
  };
};

export const loginWithWalletSuccess = (user) => {
  return {
    type: TYPES.LOGIN_WITH_WALLET_SUCCESS,
    payload: user,
  };
};

//Logout Admin
export const logoutUser = (history, role) => {
  return {
    type: TYPES.LOGOUT_USER,
    payload: { history, role },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: TYPES.LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

//Api error
export const apiError = (error) => {
  return {
    type: TYPES.API_ERROR,
    payload: error,
  };
};
// Settings
export const getSettings = () => ({
  type: TYPES.GET_SETTINGS,
});

export const getSettingsSuccess = (setting) => ({
  type: TYPES.GET_SETTINGS_SUCCESS,
  payload: setting,
});

export const getSettingsFail = () => ({
  type: TYPES.GET_SETTINGS_FAIL,
});

// Update user
export const updateUser = (data, callback) => ({
  type: TYPES.UPDATE_USER,
  payload: { data, callback },
});
export const updateUserSuccess = (data) => ({
  type: TYPES.UPDATE_USER_SUCCESS,
  payload: data,
});
export const updateUserFail = (error) => ({
  type: TYPES.UPDATE_USER_FAIL,
  payload: error,
});

// Update user
export const getPageContent = (data) => ({
  type: TYPES.GET_PAGE_CONTENT,
  payload: data,
});
export const getPageContentSuccess = (data) => ({
  type: TYPES.GET_PAGE_CONTENT_SUCCESS,
  payload: data,
});
export const getPageContentFail = (error) => ({
  type: TYPES.GET_PAGE_CONTENT_FAIL,
  payload: error,
});
