import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";



export default function Holdrevealmodal(props) {
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod change_pass holdreveal_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={props.onhide}
            className="close-btn"
            style={{ top: 11, right: 22 }}
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>
          <div className="hold_key_modal login_inner position-relative">
               <h5>Keep your Private Key safe </h5>
               <p>Your Private Key provides <strong>full access to your account and funds.</strong> </p>
               <p><strong>Do not shore this with anyone.</strong> Meta Mask Support will not request this. 
                <a href="javascript:void(0)"> but phishers might</a>
               </p>
              <Button className="btn_reveal" onClick={props.togglemodal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"/></svg>
               Hold to reveal Private Key
              </Button>

            
           </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
