import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import toastr from "toastr";
import { convertHexToString, convertNumberToHex } from "./web3Intraction";
import { ethers } from "ethers";

export const MetaMaskContext = React.createContext(null);

export const MetaMaskProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [account, setaccount] = useState("");
  const [chainId, setchainId] = useState();
  const [blockchain, setBlockchain] = useState("BNB")
  const { settings } = useSelector(state => state.Login)

  // Init Loading
  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      addWalletListener();
      addNetworkListener();

      if (localStorage.getItem("isMetamaskConnected") === "yes") {
        console.log("isMEtamaskconnected checkConnection")
        checkConnection();
      }

    } else {
      // You must install Metamask, a virtual Ethereum wallet, in your browser.
      setIsActive(false);
    }
  }, []);


  const checkConnection = async () => {

    // Check if browser is running Metamask
    try {
      // let web3 = new Web3(window.ethereum);
      // // Check if User is already connected by retrieving the accounts
      // const accounts =  await web3.eth.getAccounts()
      //   console.log("aacounts",accounts,"wallet connected")
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const accounts = await provider.listAccounts();
      console.log("accounts", accounts[0]);

      const address = Array.isArray(accounts) ? accounts[0] : "";
      // Set User account into state
      console.log("address", address);
      if (address) {
        setIsActive(true);
        setaccount(address || "");
      }

    } catch (e) {
      console.log("error while connection check", e);
    }

  };

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }
  // Connect to MetaMask wallet
  const connect = async () => {
    const walletResponse = await connectWallet();
    if (localStorage.getItem("isMetamaskConnected") == "yes") {
      if (settings && settings?.blockchain) {
        let blockchain = await settings?.blockchain?.filter(data => data.type == "BNB");
        blockchain = blockchain[0];
        let networkUrl = await blockchain?.networkUrl?.filter(data => data.type == settings?.blockchainNetworkMode)
        networkUrl = networkUrl[0];
        var params = {
          chainId: convertNumberToHex(networkUrl?.chainId),
        };
        try {
          await switchChain(params);
        } catch (error) {
          if (error.code === 4902) {
            try {
              console.log(networkUrl)
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              await provider.send('wallet_addEthereumChain', [
                {
                  chainName: networkUrl.chainName,
                  chainId: convertNumberToHex(networkUrl?.chainId),
                  nativeCurrency: networkUrl.nativeCurrency,
                  rpcUrls: [networkUrl.url]
                }
              ]);

              // await window.ethereum.request({
              //   method: 'wallet_addEthereumChain',
              //   params: [
              //     {
              //       chainName: networkUrl.chainName,
              //       chainId: convertNumberToHex(networkUrl?.chainId),
              //       nativeCurrency: networkUrl.nativeCurrency,
              //       rpcUrls: [networkUrl.url]
              //     }
              //   ]
              // });
            } catch (error) {
              console.log("error in add chain")
            }
          } else {
            if (isMobileDevice()) {
              try {
                console.log(networkUrl)
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send('wallet_addEthereumChain', [
                  {
                    chainName: networkUrl.chainName,
                    chainId: convertNumberToHex(networkUrl?.chainId),
                    nativeCurrency: networkUrl.nativeCurrency,
                    rpcUrls: [networkUrl.url]
                  }
                ]);

                // await window.ethereum.request({
                //   method: 'wallet_addEthereumChain',
                //   params: [
                //     {
                //       chainName: networkUrl.chainName,
                //       chainId: convertNumberToHex(networkUrl?.chainId),
                //       nativeCurrency: networkUrl.nativeCurrency,
                //       rpcUrls: [networkUrl.url]
                //     }
                //   ]
                // });
              } catch (error) {
                console.log("error in add chain")
              }
            }
          }
          console.log("error in switch chain");
        }
      }
    }
    setaccount(walletResponse.address);
    setIsActive(true);
  };

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    setaccount("");
    setIsActive(false);
    localStorage.setItem(
      "isMetamaskConnected",
      "no"
    );
    if (window.ethereum && window.ethereum.disconnect) {
      window.ethereum.disconnect();
    }
  };

  const switchNetwork = async (chainId) => {
    const params = {
      chainId: convertNumberToHex(chainId),
    };
    await switchChain(params);
  }

  function addWalletListener() {

    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setIsActive(true);
        setaccount(accounts[0]);
      } else {
        setIsActive(false);
        setaccount("");
      }
    });
  }

  async function addNetworkListener() {
    setchainId(
      convertHexToString(
        await window.ethereum.request({
          method: "eth_chainId",
          params: [],
        })
      )
    );
    window.ethereum.on("chainChanged", (res) => {
      setchainId(convertHexToString(res));
    });
  }

  const values = useMemo(
    () => ({
      isActive,
      account,
      connect,
      disconnect,
      switchNetwork,
      chainId,
      setBlockchain,
      blockchain
    }),
    [isActive, account, chainId, blockchain]
  );

  return (
    <MetaMaskContext.Provider value={values}>
      {children}
    </MetaMaskContext.Provider>
  );
};

export const connectWallet = async () => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = { address: addressArray[0] };
      localStorage.setItem(
        "isMetamaskConnected",
        "yes"
      );
      return obj;
    } catch (err) {
      return { address: "" };
    }
  } else {
    window.open("https://metamask.io/download.html", "_blank");
    toastr.error(
      "You must install Metamask, a virtual Ethereum wallet, in your browser."
    );

    return { address: "" };
  }
};

export const getCurrentWalletConnected = async () => {
  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (addressArray.length > 0) {
      return { address: addressArray[0] };
    }

    return { address: "" };
  } catch (err) {
    return { address: "" };
  }
};
export const onAccountChange = (callback) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on("accountsChanged", callback);
  }
};

export const getCurrentChain = () => {
  try {
    return window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
  } catch (err) { }
};

export const onChainChange = (callback) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on("chainChanged", callback);
  }
};
export const switchChain = (params) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [params],
    });
  }
};

export const addChain = (params) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [params],
    });
  }
};
export const sendTransaction = (params) => {
  try {
    return window.ethereum.request({
      method: "eth_sendTransaction",
      params: [params],
    });
  } catch (err) { }
};
