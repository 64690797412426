import React from "react";
import { Form } from "reactstrap";

export default function Marketfillter(props) {
  const { list, filter, setfilter } = props;

  const checkSelectedFilters = (selectedList, value) => {
    // console.log("selectedList", selectedList, "value", value)
    try {
      return !!(selectedList || [])?.includes(value);
    } catch (e) {
      return false;
    }
  }

  const onSelectFilters = (key, value) => {
    try {
      console.log("key", key);
      if(key === "subcategories" || key === "levels"){
        filter["isMore"] = false;
        filter["page"] = 1;
      }
      if (!value) {
        setfilter((prev) => ({ ...prev, [key]: [] }));
        return;
      }
      if (!filter[key]?.includes(value)) { 
        setfilter((prev) => ({ ...prev, [key]: [...prev[key], value] }))
      } else {
        const updatedList = filter[key]?.filter((item) => item !== value);
        setfilter((prev) => ({ ...prev, [key]: updatedList }))
      }
    } catch (e) {
      return;
    }
  }

  return (
    <section className="filter_here">
      <a
        href="javascript:void(0)"
        className="close_fillter"
        onClick={props.onClose}
      >
        <img src="/images/closere.png" alt="" className="img-fluid" />
      </a>
      <h5 className="filter-head">Filter</h5>

      <div className="bottom_filter_pirates mt-2">
        <h5 className="filter-head-class">Class</h5>

        <div className="input_Wrap mt-4">
          <ul class="radio-a">
            <li>
              <input
                name="radio-a"
                id="radio-a087"
                type="checkbox"
                checked={!filter?.levels?.length}
                onClick={() => {
                  onSelectFilters("levels", 0);
                }}
              />
              <label class="radio-a-label" for="radio-a087">
                All
              </label>
            </li>
            <li>
              <input
                name="radio-a"
                id="radio-a06"
                type="checkbox"
                checked={checkSelectedFilters(filter?.levels, 1)}
                onClick={() => {
                  onSelectFilters("levels", 1);
                }}
              />
              <label class="radio-a-label" for="radio-a06">
                Level 1
              </label>
            </li>
            <li>
              <input
                name="radio-a"
                id="radio-a07"
                type="checkbox"
                checked={checkSelectedFilters(filter?.levels, 2)}
                onClick={() => {
                  onSelectFilters("levels", 2);
                }}
              />
              <label class="radio-a-label" for="radio-a07">
                Level 2
              </label>
            </li>

            <li>
              <input
                name="radio-a"
                id="radio-a08"
                type="checkbox"
                checked={checkSelectedFilters(filter?.levels, 3)}
                onClick={() => {
                  onSelectFilters("levels", 3);
                }}
              />
              <label class="radio-a-label" for="radio-a08">
                Level 3
              </label>
            </li>

            <li>
              <input
                name="radio-a"
                id="radio-a09"
                type="checkbox"
                checked={checkSelectedFilters(filter?.levels, 4)}
                onClick={() => {
                  onSelectFilters("levels", 4);
                }}
              />
              <label class="radio-a-label" for="radio-a09">
                Level 4
              </label>
            </li>

            <li>
              <input
                name="radio-a"
                id="radio-a010"
                type="checkbox"
                checked={checkSelectedFilters(filter?.levels, 5)}
                onClick={() => {
                  onSelectFilters("levels", 5);
                }}
              />
              <label class="radio-a-label" for="radio-a010">
                Level 5
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="bottom_filter_pirates mt-4">
        <h5 className="filter-head">Pirates</h5>
        <Form>
          <div className="input_Wrap scroll_Col mt-4">
            <ul class="radio-a">
              <li>
                <input
                  name="radio-a"
                  id="radio-sub-all"
                  type="checkbox"
                  onClick={() => {
                    console.log("not clickable")
                    onSelectFilters("subcategories", null)
                  }
                  }
                  // checked={filter?.subcategory_id == "none"}
                  checked={!filter?.subcategories?.length}
                />
                <label class="radio-a-label" for="radio-sub-all">
                  All
                </label>
              </li>
              {list.length > 0 &&
                list
                  .filter((e) => e.catName == "Pirates")
                  .map((cat) =>
                    cat.subcategories.map((subcat, index) => {
                      return (
                        <li
                          key={"subcat" + index}

                        >
                          <input
                            name="radio-a"
                            id={`radio-sub${index}`}
                            type="checkbox"
                            checked={checkSelectedFilters(filter?.subcategories, subcat._id)}
                            onClick={() =>
                              onSelectFilters("subcategories", subcat._id)
                            }
                          />
                          <label class="radio-a-label" for={`radio-sub${index}`}>
                            {subcat.catName}
                          </label>
                        </li>
                      );
                    })
                  )}
            </ul>
          </div>
        </Form>
      </div>
    </section>
  );
}
