import React from "react";

import Header from "../Component/Header/Header";
import ForgotPasswordScreen from "../Pages/ForgotPassword/ForgotPassword";

export default function Loginregtheme() {
  return (
    <>
      <Header extraClass="no_menu"/>
      <ForgotPasswordScreen />
    </>
  );
}
