import React from "react";

import Header from "../Component/Header/Header";
import Privacy from "../Pages/Privacy";

export default function PrivacyTheme() {
  return (
    <>
      <Privacy />
    </>
  );
}
