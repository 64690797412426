import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { AES, enc } from "crypto-js";

// store
import { registerUserEmailVerify, setnewPassword } from "../../store/actions";

export default function ChangePassword({ changePassTitle, hideModal,userDetails}) {

  const dispatch = useDispatch();
  const {
    Register: { loading },
  } = useSelector((store) => store);

  const navigate = useNavigate();
  const [getOTP, setgetOTP] = useState(false);
  const [counter, setCounter] = React.useState(60);

  const [optEncrypt, setOtpEncrypt] = useState("");

  const [show, setShow] = useState({ show1: false, show2: false });
  const [showPassword, setshowPassword] = useState(false);

  const [emailsection, setemailsection] = useState(false);

  const [fields, setfields] = useState({
    email: "",
    password: "",
  });

  const handleChange = (name) => (event) => {
    setfields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  useEffect(()=>{
    if(userDetails !== ""){
      setfields({
        ...fields,
        email:userDetails
      })
    }
  },[])

  const sendEmailVerification = (e) => {
    e.preventDefault();
    const callBack = (res) => {
      if (res.status == "failure") {
        toast.error(res.message);
        setfields((prev) => ({ ...prev, otp: "" }));
      } else {
        setgetOTP(true);
        setCounter(60);
        toast.success(
          `Verification Email OTP send on ${fields.email} successfully!!`
        );
        setOtpEncrypt(res.data);
        setemailsection(true);
      }
    };
    const data = {
      email: fields.email,
      type: "forgotPassword",
    };
    dispatch(registerUserEmailVerify(data, callBack));
  };

  const setPassowrd = (e) => {
    e.preventDefault();
    const callBack = (res) => {
      if (res.status == "failure") {
        return toast.error(res.message);
      } else {
        toast.success(res.message);
        changePassTitle ? hideModal() : navigate("/login");
      }
    };
    if (!fields.password) {
      toast.error("New Password is required!");
      return false;
    }
    if (!fields.repeatPassword) {
      toast.error("Repeat Password is required!");
      return false;
    }
    if (fields.password != fields.repeatPassword) {
      toast.error("Password and confirm password not match!");
      return false;
    }
    if (!fields.verifyCode) {
      toast.error("Verify Code is required!");
      return false;
    }
    // const bytes = AES.decrypt(optEncrypt, fields.email);
    // const decrypted = bytes.toString(enc.Utf8);
    // console.log(fields.verifyCode, decrypted, "decrypted");
    // if (fields.verifyCode != decrypted) {
    //   toast.error("Verify Code is not match!");
    //   return false;
    // }
    const data = {
      email: fields.email,
      password: fields.password,
      verifyCode: fields.verifyCode,
    };
    dispatch(setnewPassword(data, callBack));
  };

  // Second Attempts
  React.useEffect(() => {
    if (getOTP) {
      counter > 0
        ? setTimeout(() => setCounter(counter - 1), 1000)
        : setgetOTP(false);
    }
  }, [counter, getOTP]);
  return (
    <div className="mainpage-wrap position-relative">
      <div className="overlay_col"></div>

      <section className="login-theme position-relative">
        <Container>
          <div className="login_inner change_pass2">
            <h5 className="log-head">{changePassTitle || "Forgot Password"}</h5>
            <>
              <Fade>
                <Form
                  className="form_login form_registration"
                  onSubmit={setPassowrd}
                >
                  <FormGroup className="mb-4">
                    <Input
                      type="email"
                      name="email"
                      value={fields.email}
                      id="exampleEmail"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      disabled={userDetails !== "" && true}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative mb-4">
                    <Input
                      type={show.show1 === false ? "password" : "text"}
                      name="password"
                      id="examplePassword"
                      autoComplete="new"
                      placeholder="Old Password"
                      onChange={handleChange("oldPassword")}
                    />
                    <div
                      className="eye-icon"
                      onClick={() =>
                        setShow((prev) => ({ ...prev, show1: !prev.show1 }))
                      }
                    >
                      {show.show1 === false ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M3 2.5L13 13.5"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.68098 9.8499C9.22219 10.2702 8.62193 10.5023 7.99973 10.4999C7.49515 10.4998 7.00239 10.3471 6.58624 10.0618C6.17008 9.77642 5.85001 9.37182 5.66811 8.90117C5.4862 8.43051 5.45097 7.91583 5.56705 7.42477C5.68313 6.93372 5.94509 6.48929 6.31848 6.1499"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.625 4.2876C2.075 5.5751 1 8.0001 1 8.0001C1 8.0001 3 12.5001 8 12.5001C9.17161 12.5096 10.3286 12.2397 11.375 11.7126"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.0383 10.5688C14.4008 9.35002 15.0008 8.00002 15.0008 8.00002C15.0008 8.00002 13.0008 3.50002 8.00078 3.50002C7.56732 3.49916 7.13455 3.5347 6.70703 3.60627"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.46875 5.5437C9.0004 5.64444 9.48487 5.91537 9.84903 6.31561C10.2132 6.71584 10.4373 7.22367 10.4875 7.76245"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#81BFB8"
                              d="M4 12.001V12c.003-.016.017-.104.095-.277c.086-.191.225-.431.424-.708c.398-.553.993-1.192 1.745-1.798C7.777 7.996 9.812 7 12 7c2.188 0 4.223.996 5.736 2.216c.752.606 1.347 1.245 1.745 1.798c.2.277.338.517.424.708c.078.173.092.261.095.277V12c-.003.016-.017.104-.095.277a4.251 4.251 0 0 1-.424.708c-.398.553-.993 1.192-1.745 1.798C16.224 16.004 14.188 17 12 17c-2.188 0-4.223-.996-5.736-2.216c-.752-.606-1.347-1.245-1.745-1.798a4.226 4.226 0 0 1-.424-.708A1.115 1.115 0 0 1 4 12.001ZM12 5C9.217 5 6.752 6.254 5.009 7.659c-.877.706-1.6 1.474-2.113 2.187a6.157 6.157 0 0 0-.625 1.055C2.123 11.23 2 11.611 2 12c0 .388.123.771.27 1.099c.155.342.37.7.626 1.055c.513.713 1.236 1.48 2.113 2.187C6.752 17.746 9.217 19 12 19c2.783 0 5.248-1.254 6.991-2.659c.877-.706 1.6-1.474 2.113-2.187c.257-.356.471-.713.625-1.055c.148-.328.271-.71.271-1.099c0-.388-.123-.771-.27-1.099a6.197 6.197 0 0 0-.626-1.055c-.513-.713-1.236-1.48-2.113-2.187C17.248 6.254 14.783 5 12 5Zm-1 7a1 1 0 1 1 2 0a1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                            />
                          </g>
                        </svg>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="position-relative mb-4">
                    <Input
                      type={show.show2 === false ? "password" : "text"}
                      name="password"
                      id="examplePassword"
                      autoComplete="new54"
                      placeholder="New Password"
                      onChange={handleChange("password")}
                    />
                    <div
                      className="eye-icon"
                      onClick={() =>
                        setShow((prev) => ({ ...prev, show2: !prev.show2 }))
                      }
                    >
                      {show.show2 === false ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M3 2.5L13 13.5"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.68098 9.8499C9.22219 10.2702 8.62193 10.5023 7.99973 10.4999C7.49515 10.4998 7.00239 10.3471 6.58624 10.0618C6.17008 9.77642 5.85001 9.37182 5.66811 8.90117C5.4862 8.43051 5.45097 7.91583 5.56705 7.42477C5.68313 6.93372 5.94509 6.48929 6.31848 6.1499"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.625 4.2876C2.075 5.5751 1 8.0001 1 8.0001C1 8.0001 3 12.5001 8 12.5001C9.17161 12.5096 10.3286 12.2397 11.375 11.7126"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.0383 10.5688C14.4008 9.35002 15.0008 8.00002 15.0008 8.00002C15.0008 8.00002 13.0008 3.50002 8.00078 3.50002C7.56732 3.49916 7.13455 3.5347 6.70703 3.60627"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.46875 5.5437C9.0004 5.64444 9.48487 5.91537 9.84903 6.31561C10.2132 6.71584 10.4373 7.22367 10.4875 7.76245"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#81BFB8"
                              d="M4 12.001V12c.003-.016.017-.104.095-.277c.086-.191.225-.431.424-.708c.398-.553.993-1.192 1.745-1.798C7.777 7.996 9.812 7 12 7c2.188 0 4.223.996 5.736 2.216c.752.606 1.347 1.245 1.745 1.798c.2.277.338.517.424.708c.078.173.092.261.095.277V12c-.003.016-.017.104-.095.277a4.251 4.251 0 0 1-.424.708c-.398.553-.993 1.192-1.745 1.798C16.224 16.004 14.188 17 12 17c-2.188 0-4.223-.996-5.736-2.216c-.752-.606-1.347-1.245-1.745-1.798a4.226 4.226 0 0 1-.424-.708A1.115 1.115 0 0 1 4 12.001ZM12 5C9.217 5 6.752 6.254 5.009 7.659c-.877.706-1.6 1.474-2.113 2.187a6.157 6.157 0 0 0-.625 1.055C2.123 11.23 2 11.611 2 12c0 .388.123.771.27 1.099c.155.342.37.7.626 1.055c.513.713 1.236 1.48 2.113 2.187C6.752 17.746 9.217 19 12 19c2.783 0 5.248-1.254 6.991-2.659c.877-.706 1.6-1.474 2.113-2.187c.257-.356.471-.713.625-1.055c.148-.328.271-.71.271-1.099c0-.388-.123-.771-.27-1.099a6.197 6.197 0 0 0-.626-1.055c-.513-.713-1.236-1.48-2.113-2.187C17.248 6.254 14.783 5 12 5Zm-1 7a1 1 0 1 1 2 0a1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                            />
                          </g>
                        </svg>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup className="position-relative mb-4">
                    <Input
                      type={showPassword === false ? "password" : "text"}
                      name="repeatPassword"
                      id="exampleRepeatPassword"
                      placeholder="Repeat Password"
                      onChange={handleChange("repeatPassword")}
                    />
                    <div
                      className="eye-icon"
                      onClick={() => setshowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M3 2.5L13 13.5"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.68098 9.8499C9.22219 10.2702 8.62193 10.5023 7.99973 10.4999C7.49515 10.4998 7.00239 10.3471 6.58624 10.0618C6.17008 9.77642 5.85001 9.37182 5.66811 8.90117C5.4862 8.43051 5.45097 7.91583 5.56705 7.42477C5.68313 6.93372 5.94509 6.48929 6.31848 6.1499"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.625 4.2876C2.075 5.5751 1 8.0001 1 8.0001C1 8.0001 3 12.5001 8 12.5001C9.17161 12.5096 10.3286 12.2397 11.375 11.7126"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.0383 10.5688C14.4008 9.35002 15.0008 8.00002 15.0008 8.00002C15.0008 8.00002 13.0008 3.50002 8.00078 3.50002C7.56732 3.49916 7.13455 3.5347 6.70703 3.60627"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.46875 5.5437C9.0004 5.64444 9.48487 5.91537 9.84903 6.31561C10.2132 6.71584 10.4373 7.22367 10.4875 7.76245"
                            stroke="#81BFB8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#81BFB8"
                              d="M4 12.001V12c.003-.016.017-.104.095-.277c.086-.191.225-.431.424-.708c.398-.553.993-1.192 1.745-1.798C7.777 7.996 9.812 7 12 7c2.188 0 4.223.996 5.736 2.216c.752.606 1.347 1.245 1.745 1.798c.2.277.338.517.424.708c.078.173.092.261.095.277V12c-.003.016-.017.104-.095.277a4.251 4.251 0 0 1-.424.708c-.398.553-.993 1.192-1.745 1.798C16.224 16.004 14.188 17 12 17c-2.188 0-4.223-.996-5.736-2.216c-.752-.606-1.347-1.245-1.745-1.798a4.226 4.226 0 0 1-.424-.708A1.115 1.115 0 0 1 4 12.001ZM12 5C9.217 5 6.752 6.254 5.009 7.659c-.877.706-1.6 1.474-2.113 2.187a6.157 6.157 0 0 0-.625 1.055C2.123 11.23 2 11.611 2 12c0 .388.123.771.27 1.099c.155.342.37.7.626 1.055c.513.713 1.236 1.48 2.113 2.187C6.752 17.746 9.217 19 12 19c2.783 0 5.248-1.254 6.991-2.659c.877-.706 1.6-1.474 2.113-2.187c.257-.356.471-.713.625-1.055c.148-.328.271-.71.271-1.099c0-.388-.123-.771-.27-1.099a6.197 6.197 0 0 0-.626-1.055c-.513-.713-1.236-1.48-2.113-2.187C17.248 6.254 14.783 5 12 5Zm-1 7a1 1 0 1 1 2 0a1 1 0 0 1-2 0Zm1-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                            />
                          </g>
                        </svg>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="position-relative mb-4">
                    <Input
                      type="number"
                      name="verifyCode"
                      id="exampleVerifyCode"
                      placeholder="Email Verification Code"
                      onChange={handleChange("verifyCode")}
                    />
                    <div
                      className="eye-icon verify-otp-send"
                      onClick={(e) => {
                        if (!getOTP) {
                          sendEmailVerification(e);
                        }
                      }}
                    >
                      {!getOTP && "Get"} {getOTP && counter + "s"}
                    </div>
                    {getOTP && (
                      <p className="email_codes mt-2">
                        Email Verification code sent successfully
                      </p>
                    )}
                  </FormGroup>

                  <div className="login-btn-wrap mt-5">
                    <Button className="btn-form" type="submit">
                      {loading ? <Spinner color="primary" /> : "Save"}
                    </Button>
                  </div>
                </Form>
              </Fade>
            </>
          </div>
        </Container>
      </section>
    </div>
  );
}
