import React from "react";


import Header from "../Component/Header/Header";
import Productsingle from "../Pages/Productsingle/Productsingle";
import Footer from "../Component/Footer/Footer";


export default function ProductSingletheme() {
  return (
    <>
      <Header />
      <Productsingle/>
      <Footer/>
    </>
  );
}
