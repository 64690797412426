import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import ChangePasswords from "../../Pages/ForgotPassword/ChangePassword";

import { updateUser, uploadFile } from "../../store/actions";

export default function ChangePassword(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod change_pass"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={props.onHide}
            className="close-btn"
            style={{ top: 11, right: 22 }}
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>
          <div className="profile_inner">
            <>
              <ChangePasswords
                changePassTitle={"Change password"}
                hideModal={props.onHide}
                userDetails={props.userDetails}
              />
            </>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
