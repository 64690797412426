import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  FormText,
} from "reactstrap";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { Navigate, useNavigate } from "react-router-dom";
import Holdrevealmodal from "Component/Modals/Holdrevealmodal";
import Keytabbing from "./keytabbing";
import Safetyalertmodal from "Component/Modals/Safetyalertmodal";

const Privatekeypage = () => {
  const navigate = useNavigate();
  const [holdrevealmodal, setHoldrevealmodal] = useState(false);
  const [safetyalert, setsafetyalert] = useState(false);

  const [stepone, setStepone] = useState(true);
  const togglemodal = (e) => {
     e.preventDefault();
     setHoldrevealmodal(false)
     setInterval(() => {
        setStepone(false) 
     }, 500);
  }


  useEffect(() => {
    setTimeout(() => {
        setsafetyalert(true)
    }, 3000);
  }, [])
  
  return (
    <>
      <Holdrevealmodal
        show={holdrevealmodal}
        onhide={() => setHoldrevealmodal(false)}
        togglemodal={togglemodal}
      />

      <Safetyalertmodal
        show={safetyalert}
        onhide={() => setsafetyalert(false)}
      />

      <div className="mainpage-wrap position-relative">
        <div className="overlay_col"></div>

        <section className="login-theme privatekey_hold position-relative">
          <Container>
            <a
              href="javascript:void(0)"
              class="inventory_back private_back"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 28 28"
                fill="none"
              >
                <g filter="url(#filter0_di_1_7208)">
                  <path
                    d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                    fill="url(#paint0_linear_1_7208)"
                  ></path>
                </g>
                <defs>
                  <filter
                    id="filter0_di_1_7208"
                    x="0"
                    y="0"
                    width="28"
                    height="28"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1_7208"
                    ></feBlend>
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1_7208"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    ></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect2_innerShadow_1_7208"
                    ></feBlend>
                  </filter>
                  <linearGradient
                    id="paint0_linear_1_7208"
                    x1="14"
                    y1="1"
                    x2="14"
                    y2="23"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#46C7A8"></stop>
                    <stop
                      offset="1"
                      stop-color="#46C7A8"
                      stop-opacity="0"
                    ></stop>
                  </linearGradient>
                </defs>
              </svg>
            </a>
            <div className="login_inner position-relative">
              <>
                <h5 className="log-head">Show private key</h5>

                <p className="save_info mt-4">
                  Save it somewhere safe and secret
                </p>

                <div className="warning_msg_here mt-3">
                  <div className="eyeclosing">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#d73948"
                        d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54L2 5.27M12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.79 11.79 0 0 1-4 5.19l-1.42-1.43A9.862 9.862 0 0 0 20.82 12A9.821 9.821 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.821 9.821 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13Z"
                      />
                    </svg>
                  </div>

                  <p>
                    Never disclose this key. Anyone with your private key can
                    fully control your account, including transferring away any
                    of your fun
                  </p>
                </div>

                {stepone ? (
                  <>
                    <Form className="form_login mt-4">
                      <FormGroup className="mb-4">
                        <Label for="exampleEmail">
                          Enter password to continue{" "}
                        </Label>
                        <Input
                          type="password"
                          name="email"
                          id="exampleEmail"
                          placeholder="Password"
                        />
                      </FormGroup>

                      <div className="sinlge_page_btn">
                        <Button className="cancel_Single">Cancel</Button>
                        <Button
                          className="buy_single"
                          onClick={() => setHoldrevealmodal(true)}
                        >
                          Next
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <Keytabbing/>
                   </>
                )}
              </>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Privatekeypage;
