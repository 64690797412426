import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import {
  updateUser,
  removeProfileImage,
  uploadFile,
  viewProfile,
} from "../../store/actions";

export default function Profileeditmod(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, imgLoading } = useSelector((state) => state.User);
  const { loading } = useSelector((state) => state.File);

  const [fields, setfields] = useState({
    name: "",
    profileImage: null,
  });
  const [images, setimages] = useState({
    profileImage: null,
  });

  useEffect(() => {
    setfields((prev) => ({
      ...prev,
      name: userDetails?.username,
      profileImage: userDetails?.profileImage?._id,
    }));
    setimages({ profileImage: userDetails?.profileImage?.link });
  }, [userDetails]);
  const uploadFileSuccess = (name, evt) => (response) => {
    const { _id } = response.data;
    handleChange(name)({ target: { value: _id } });
    evt.target.value = ''
  };

  const handleImageChange = (name) => (evt) => {
    console.log("coming")
    const image = evt.target.files;
    if (image && image[0]) {
      dispatch(uploadFile({ image: image[0] }, uploadFileSuccess(name, evt)));
      setimages((prevState) => ({
        ...prevState,
        [name]: URL.createObjectURL(image[0]),
      }));
    }
  };
  const handleChange = (name) => (event) => {
    setfields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const submit = (e) => {
    e.preventDefault();
    const callBack = (res) => {
      console.log(res, "hghghg");
      if (res.status == "failure") {
        return toast.error(res.message);
      } else {
        toast.success(res.message);
        props.onhide();
        dispatch(viewProfile());
      }
    };

    const data = {
      username: fields.name,
      profileImage: fields.profileImage,
    };
    dispatch(updateUser(data, callBack));
  };
  const removeProfileImgs = (e) => {
    if (!fields?.profileImage) {
      return toast.error("Please select image first.");
    }
    let data = {
      profileImage: fields?.profileImage,
    };
    const callback = (res) => {
      setimages({ profileImage: null });
      setfields((prev) => ({ ...prev, profileImage: null }));
      if (res.status == "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    };
    dispatch(removeProfileImage(data, callback));
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={props.onhide}
            className="close-btn"
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>
          <div className="profile_inner">
            <>
              <Form className="form_login" onSubmit={submit}>
                <FormGroup>
                  <div className="uploding_img position-relative">
                    <img
                      src={images?.profileImage || "/images/profileuser.png"}
                      className="img-fluid"
                    />

                    <div className="upload_wrapping">
                      <div className="file_upload_icon position-relative">
                        <input
                          type="file"
                          onChange={handleImageChange("profileImage")}
                          className="opacity-0 cursor-pointer profile_pic"
                        />
                        <Button className="upload_btn">Upload</Button>
                        
                        <p
                          onClick={() => {
                            !loading && removeProfileImgs();
                          }}
                          className="text-center text-msg"
                          style={{ color: "#ff2323", cursor: "pointer" }}
                        >
                          {fields?.profileImage ? (
                            imgLoading ? (
                              "Removing"
                            ) : (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="#fff"
                                    d="M8.5 4h3a1.5 1.5 0 0 0-3 0Zm-1 0a2.5 2.5 0 0 1 5 0h5a.5.5 0 0 1 0 1h-1.054l-1.194 10.344A3 3 0 0 1 12.272 18H7.728a3 3 0 0 1-2.98-2.656L3.554 5H2.5a.5.5 0 0 1 0-1h5ZM5.741 15.23A2 2 0 0 0 7.728 17h4.544a2 2 0 0 0 1.987-1.77L15.439 5H4.561l1.18 10.23ZM8.5 7.5A.5.5 0 0 1 9 8v6a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5ZM12 8a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V8Z"
                                  />
                                </svg>
                              </>
                            )
                          ) : (
                            loading && "Image uploading"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="mb-4">
                  <Input
                    type="text"
                    name="name"
                    id="exampleName"
                    placeholder="Name"
                    onChange={handleChange("name")}
                    value={fields.name}
                    required
                    readOnly
                    disabled
                  />
                </FormGroup>
                {/* <FormGroup className="mb-4">
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      value={fields.email}
                      required
                      readOnly={true}
                    />
                  </FormGroup> */}

                <div className="login-btn-wrap mt-5">
                  <Button className="btn-form" onSubmit={submit}>
                    Update
                  </Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
