import React from "react";


import Header from "../Component/Header/Header";
import LoginScreen from "../Pages/Login/Login";



export default function Loginregtheme() {
  return (
    <>
      <Header extraClass="no_menu"/>
      <LoginScreen/>
    </>
  );
}
