import React, { useRef, useState, useEffect, memo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Offcanvas from "react-bootstrap/Offcanvas";
import pirateBg1 from "../../images/Piratebg1.png"
import Buymod from "Component/Modals/Buymod";

import Successmodal from "../../Component/Modals/Successmodal";
// Hooks
import wallet from "../../hooks/metamask";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";

import Marketfillter from "./Marketfillter";
import Shipfillter from "./Shipfillter";

//store
import {
  getItems,
  getCategories,
  addHistorySuccess,
  postItemBuy,
  countPirateBuy,
} from "../../store/actions";
import { showNotification, stringShortner } from "helpers/constants";
import toast from "react-hot-toast";
import Web3Intraction from "utils/web3Intraction";

const propsAreEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps?.filter == JSON.stringify(nextProps?.filter)))
    return true;
};

function Marketdash() {
  const [draweropen, setdraweropen] = useState(false);
  const toggleDrawer = () => {
    setdraweropen((prevState) => !prevState);
  };

  useEffect(() => {
    let ele = document.getElementsByClassName("App");
    if (draweropen) {
      ele[0].classList.add("mystyle");
    } else {
      ele[0].classList.remove("mystyle");
    }
  }, [draweropen]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldLog = useRef(true);
  const { items, totalItems, loading, buyPirateCounts } = useSelector(
    (state) => state.Items,
  );

  const { categories } = useSelector((state) => state.Category);
  const { settings, user } = useSelector((state) => state.Login);
  const { userDetails } = useSelector((state) => state.User);
  const [searchParams, setSearchParams] = useSearchParams();
  const [Successshow, setSuccessshow] = useState(false);
  const [internalLoading, setinternalLoading] = useState(false);
  const [state, setState] = useState({
    tab: !!searchParams.get("type") ? searchParams.get("type") : "Pirates",
  });

  // console.log("searchParams", searchParams.get("type"));
  const [selectedItem, setSelectedItem] = useState({ item: {}, idx: null });
  const userWallet = wallet();
  const [Buyshow, setBuyshow] = useState(false);
  const [itemList, setitemList] = useState([]);
  const initialState = {
    limit: 15,
    orderBy: "_id",
    order: -1,
    subcategory_id: "none",
    page: 1,
    callFilterOnBuy: "",
    collectionName: !!searchParams.get("type")
      ? searchParams.get("type")
      : "Pirates",
    shipsLevelStart: 1,
    shipsLevelEnd: 49,
    price: "",
    isMore: false,
    levels: [],
    subcategories: [],
  };
  const [filter, setfilter] = useState(initialState);
  const [catfilter, setcatfilter] = useState({
    limit: 20,
    order: 1,
    status: "active",
    orderBy: "_id",
    page: 1,
  });

  useEffect(() => {
    let filterObj = { ...filter };

    //new code
    console.log(filter, "filter")
      dispatch(
        getItems({
          ...filterObj,
        }),
      );
  }, [JSON.stringify(filter)]);
  useEffect(() => {
    if (userDetails) {
      dispatch(countPirateBuy({ current_owner: userDetails.walletAddress }));
    }
  }, [userDetails]);

  useEffect(() => {
    if (filter.isMore) {
      setitemList((prev) => [...prev, ...items]);
    } else {
      setitemList(items);
    }
  }, [items]);

  useEffect(() => {
    dispatch(getCategories(catfilter));
  }, [catfilter]);


  const showMoreItems = (evt) => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }));
  };
  const togmodl = () => {
    setBuyshow(!Buyshow);
    setTimeout(() => {
      setSuccessshow(false);
    }, 1000);
  };

  const onBuy = async (evt, buyType) => {
    evt.preventDefault()
    evt.stopPropagation();
    togmodl();
    if (
      settings &&
      selectedItem?.item &&
      JSON.stringify(selectedItem?.item) !== "{}"
    ) {
      if (
        buyPirateCounts.buyCount > 0 &&
        selectedItem?.item?.collection_id?.collectionType == "pirates"
      ) {
        return toast.error("You already bought pirates!");
      }
      const web3Intraction = new Web3Intraction("BNB", settings);
      await userWallet.switchNetwork(web3Intraction.networkUrl.chainId);

      setinternalLoading(true);

      const getCollectionData = async (response) => {
        console.log(response, "response");
        try {
          const receipt = await web3Intraction.sendTransaction(
            selectedItem?.item,
            buyType,
            userWallet?.account,
          );
          const data = {
            type: selectedItem?.item?.blockchain || "BNB",
            itemId: selectedItem?.item?._id,
            transactionHash: receipt.transactionHash,
            token_id: selectedItem?.item?.voucher.tokenId,
          };

          let updateBalanceOf = {
            name: buyType == 1 ? "PPT_balance" : "balance",
            value: buyType == 1 ? receipt.buyAmount : selectedItem?.item?.price,
          };

          showNotification(
            receipt.transactionHash,
            userWallet?.blockchain,
            settings?.blockchainNetworkMode,
          );
          setSuccessshow(true);
          setTimeout(() => {
            setSuccessshow(false);
          }, 3000);
          dispatch(addHistorySuccess(updateBalanceOf));
          dispatch(
            postItemBuy(data, () => {
              setfilter((prev) => ({
                ...prev,
                callFilterOnBuy: "item.voucher.tokenId",
              }));
              setinternalLoading(false);
              dispatch(
                countPirateBuy({ current_owner: userDetails.walletAddress }),
              );
            }),
          );
        } catch (error) {
          if (error.code == -32603) {
            toast.error("Insufficient funds for gas * price + value");
          } else {
            toast.error(error?.message);
          }
          setinternalLoading(false);
        }
      };
      getCollectionData();
    }
  };

  const reloadData = () => {
    setfilter(initialState);
  };


  const style = (item)=> {
    console.log("item", item?.level, item?.category_id?.catName)
    if(item?.category_id?.catName === "Ships"){
      if(item.level < 10){
        return { backgroundImage: `url(/images/lvl1Ships.png`}
      }
      else if(item.level >= 10 && item.level < 20){
        return { backgroundImage: `url(/images/lvl2Ships.png`}
      }
      else if(item.level >= 20 && item.level < 30){
        return { backgroundImage: `url(/images/lvl3Ships.png`}
      }
      else if(item.level >= 30 && item.level < 40){
        return { backgroundImage: `url(/images/lvl4Ships.png`}
      }
      else if(item.level >= 40 && item.level < 50){
        return { backgroundImage: `url(/images/lvl5Ships.png`}
      }
    }
    else if (item?.category_id?.catName === "Pirates"){
      if(item.level === 1){
        return { backgroundImage: `url(/images/lvl1pirate.png`}
      }
      else if(item.level === 2){
        return { backgroundImage: `url(/images/lvl2pirate.png`}
      }
      else if(item.level === 3){
        return { backgroundImage: `url(/images/lvl3pirate.png`}
      }
      else if(item.level === 4){
        return { backgroundImage: `url(/images/lvl4pirate.png`}
      }
      else if(item.level === 5){
        return { backgroundImage: `url(/images/lvl5pirate.png`}
      }
    }
    else if (item?.category_id?.catName === "Others"){
      return { backgroundImage: `url(/images/lvl1Ships.png`}
    }
  }
  const mainStyle = (item)=> {
    if(item?.category_id?.catName === "Ships"){
      // if(item.level < 10){
        return { background: `url(../../images/${item.level}.png)`}
      // }
      // else if(item.level >= 10 && item.level < 20){
      //   return { background: `url(/images/Framelvl2.png`}
      // }
      // else if(item.level >= 20 && item.level < 30){
      //   return { background: `url(/images/Framelvl3.png`}
      // }
      // else if(item.level >= 30 && item.level < 40){
      //   return { background: `url(/images/Framelvl4.png`}
      // }
      // else if(item.level >= 40 && item.level < 50){
      //   return { background: `url(/images/Framelvl5.png`}
      // }
    }
    else if (item?.category_id?.catName === "Pirates"){
      if(item.level === 1){
        return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329670523Piratebg1.png)`}
      }
      else if(item.level === 2){
        return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329743119Piratebg2.png)`}
      }
      else if(item.level === 3){
        return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329809244Piratebg3.png)`}
      }
      else if(item.level === 4){
        return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329825687Piratebg4.png)`}
      }
      else if(item.level === 5){
        return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329783890Piratebg5.png)`}
      }
    }
    else if (item?.category_id?.catName === "Others"){
      return { background: `url(https://d1ihmhsgmb4un6.cloudfront.net/1713329476301Framelvl1.png)`}
    }
  }
  return (
    <>
      {Buyshow &&
        <Buymod
          show={Buyshow}
          title={selectedItem?.item?.name}
          item={selectedItem?.item}
          onhide={() => setBuyshow(false)}
          togmodl={togmodl}
          internalLoading={internalLoading}
          submit={onBuy}
        />}
      {Successshow &&

        <Successmodal show={Successshow} onHide={() => setSuccessshow(false)} />
      }
      <section className="market_dash market_level">
        <div className="market_dash_tab">
          <div className="send-tabling">
            <div className="tab-frame market_tabs">
              <div className="with_fillte-wrap">
                <div className="filter_add position-relative">
                  <div className="down_arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.375 5.25L7 9.625L2.625 5.25"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle>
                      {filter?.orderBy == "create_date"
                        ? "Latest"
                        : filter?.type == "low"
                          ? "Lowest Price"
                          : filter?.type == "high"
                            ? "Highest Price"
                            : "Sort by"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() =>
                          setfilter((prev) => ({
                            ...prev,
                            order: 1,
                            type: "low",
                            orderBy: "_id",
                          }))
                        }
                      >
                        Lowest Price
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          setfilter((prev) => ({
                            ...prev,
                            order: -1,
                            type: "high",
                            orderBy: "_id",
                          }))
                        }
                      >
                        Highest Price
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          setfilter((prev) => ({
                            ...prev,
                            orderBy: "create_date",
                          }))
                        }
                      >
                        Latest
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <Button className="nft_refresh" onClick={() => reloadData()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M12 20q-3.35 0-5.675-2.325T4 12q0-3.35 2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V5q0-.425.288-.713T19 4q.425 0 .713.288T20 5v5q0 .425-.288.713T19 11h-5q-.425 0-.713-.288T13 10q0-.425.288-.713T14 9h3.2q-.8-1.4-2.188-2.2T12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.7 0 3.113-.863t2.187-2.312q.2-.35.563-.487t.737-.013q.4.125.575.525t-.025.75q-1.025 2-2.925 3.2T12 20Z"
                    />
                  </svg>
                </Button>

                <div className="clearfix">
                  
                  <input
                    type="radio"
                    name="tab"
                    id="Pirates"
                    checked={state.tab == "Pirates"}
                    onClick={() => (
                      setState({ tab: "Pirates" }),
                      navigate("/?type=Pirates"),
                      setfilter((prev) => ({
                        ...prev,
                        isMore: false,
                        page: 1, subcategories: [], levels: [],
                        collectionName: "Pirates",
                        subcategory_id: "none",
                      }))
                    )}
                  />
                  <label for="Pirates">Pirates</label>

                  <input
                    type="radio"
                    name="tab"
                    id="Ships"
                    checked={state.tab == "Ships"}
                    onClick={() => (
                      navigate("/?type=Ships"),
                      setState({ tab: "Ships" }),
                      setfilter((prev) => ({
                        ...prev,
                        isMore: false,
                        page: 1, subcategories: [], levels: [],
                        collectionName: "Ships",
                        // catName:'All',
                        subcategory_id: "none",
                      }))
                    )}
                  />
                  <label for="Ships" className="ship_label">
                    Ships
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="Others"
                    checked={state.tab == "Others"}
                    onClick={() => (
                      setState({ tab: "Others" }),
                      navigate("/?type=Others"),
                      setfilter((prev) => ({
                        ...prev,
                        isMore: false,
                        page: 1, subcategories: [], levels: [],
                        collectionName: "Others",
                        subcategory_id: "none",
                      }))
                    )}
                  />
                  <label for="Others">Others</label>
                </div>

                <div className="mobile_drawer">
                  {(state.tab == "Ships" || state.tab == "Pirates") && (
                    <Button
                      onClick={toggleDrawer}
                      className="mobile_drawer_btn"
                    >
                      <h5 className="fillte_toggle_head position-relative">
                        <input
                          type="checkbox"
                          checked={
                            filter?.subcategories?.length ||
                            filter?.levels?.length
                          }
                          className="check_fillter"
                          onClick={(e) => e.preventDefault()}
                        />
                        Filter
                      </h5>
                    </Button>
                  )}
                  <Offcanvas
                    placement="end"
                    show={draweropen}
                    onhide={toggleDrawer}
                    // direction="right"
                    className={
                      state.tab == "Pirates"
                        ? "recieve_modal_main filtter_offcanvas"
                        : "recieve_modal_main"
                    }
                  >
                    <Offcanvas.Body>
                      <div className="filltler_mod position-relative">
                        {state.tab == "Pirates" && (
                          <Marketfillter
                            list={categories}
                            filter={filter}
                            onClose={toggleDrawer}
                            setfilter={setfilter}
                          />
                        )}

                        {state.tab == "Ships" && (
                          <>
                            {draweropen && (
                              <>
                                <Shipfillter
                                  list={categories}
                                  filter={filter}
                                  onClose={toggleDrawer}
                                  setfilter={setfilter}
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                  {/* <Drawer
                    open={draweropen}
                    onClose={toggleDrawer}
                    direction="right"
                    className="bla bla bla"
                  >
                    {state.tab == "Pirates" && (
                      <Marketfillter
                        list={categories}
                        filter={filter}
                        onClose={toggleDrawer}
                        setfilter={setfilter}
                      />
                    )}

                    {state.tab == "Ships" && (
                      <>
                        {draweropen && (
                          <>
                            <Shipfillter
                              list={categories}
                              filter={filter}
                              setfilter={setfilter}
                              onClose={toggleDrawer}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Drawer> */}
                </div>
              </div>

              {/* {state.tab == "All" && (
                <Fade>
                  <div className="left_Market">
                    <div className="Pirates_here common_market_tab all_marektplace">
                      <div className="parates_list">
                        {items.length > 0 ? (
                          <ul>
                            {itemList.map((item, idx) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/item-detail/${item?._id}`)
                                  }
                                >
                                  <div className="parates_div_main position-relative">
                                    <div className="nft_nameidhere mt-2">
                                      <p>{item?.name}</p>
                                    </div>
                                    <img
                                      src={
                                        item?.home_image_url ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />
                                    <div className="btnup_card">
                                      <Button className="card-btnone">
                                        <div>
                                          {item.price} {""}
                                          {item.blockchain == "polygon"
                                            ? "MTC"
                                            : "ETH"}
                                        </div>
                                      </Button>
                                      <Button
                                        className="card-btnbuy"
                                        disabled={internalLoading || loading}
                                        loading={internalLoading || loading}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedItem({
                                            item: item,
                                            idx,
                                          });
                                          setBuyshow(true);
                                        }}
                                      >
                                        {selectedItem?.idx == idx &&
                                        internalLoading ? (
                                          <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        ) : (
                                          "Buy"
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          !loading && (
                            <div
                              className="no_data"
                              style={{ paddingLeft: "15rem" }}
                            >
                              No data found
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              )} */}

              {state.tab == "Pirates" && (
                <Fade>
                  <div className="left_Market">
                    <div className="Pirates_here common_market_tab">
                      <div className="parates_list ship_list">
                        {/* {!filter?.isMore && loading ? (
                          <div className="text-center">
                            <Spinner color="primary" className="mt-5" />{" "}
                          </div>
                        ) : ( */}
                        <>
                          {itemList?.length > 0 &&
                            itemList[0]?.collection_id?.collectionType ==
                            "pirates" ? (
                            <ul>
                              {itemList.map((item, idx) => {
                                return (
                                  <li
                                    key={item?._id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!localStorage.getItem("authUser")) {
                                        setinternalLoading(false);
                                        toast.error("Please login first!");
                                        return;
                                      }
                                      if (!userWallet.isActive) {
                                        setinternalLoading(false);
                                        toast.error(
                                          "Please connect metamask first!",
                                        );
                                        return;
                                      }
                                      navigate(`/item-detail/${item?._id}`);
                                    }}
                                  >
                                    <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                    {/* <div className="parates_label" 
                                  style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                      <div className="nft_nameidhere mt-2">
                                        <p title={item?.name}>
                                          {stringShortner(item?.name, 12)}
                                        </p>
                                      </div>
                                      <img
                                        src={
                                          item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                          "/images/pirate_level4_bgimg.png"
                                        }
                                        alt=""
                                        className="img-fluid level_bg"
                                      />
                                      <div className="btnup_card">
                                        <Button className="card-btnone">
                                          <div>
                                            {parseFloat(
                                              Number(item.price).toFixed(3),
                                            )}{" "}
                                            {""}
                                            {item.blockchain == "polygon" ? (
                                              <>
                                                <img
                                                  src="/images/polygon_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : item.blockchain == "BNB" ? (
                                              <>
                                                <img
                                                  src="/images/bnb_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 256 417"
                                                >
                                                  <path
                                                    fill="#343434"
                                                    d="m127.961 0l-2.795 9.5v275.668l2.795 2.79l127.962-75.638z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 0L0 212.32l127.962 75.639V154.158z"
                                                  />
                                                  <path
                                                    fill="#3C3C3B"
                                                    d="m127.961 312.187l-1.575 1.92v98.199l1.575 4.601l128.038-180.32z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 416.905v-104.72L0 236.585z"
                                                  />
                                                  <path
                                                    fill="#141414"
                                                    d="m127.961 287.958l127.96-75.637l-127.96-58.162z"
                                                  />
                                                  <path
                                                    fill="#393939"
                                                    d="m.001 212.321l127.96 75.637V154.159z"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </div>
                                        </Button>
                                        <Button
                                          className="card-btnbuy"
                                          disabled={internalLoading || loading}
                                          loading={internalLoading || loading}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedItem({
                                              item: item,
                                              idx,
                                            });
                                            if (
                                              !localStorage.getItem("authUser")
                                            ) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please login first!",
                                              );
                                              return;
                                            }
                                            if (!userWallet.isActive) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please connect metamask first!",
                                              );
                                              return;
                                            }
                                            setBuyshow(true);
                                          }}
                                        >
                                          {selectedItem?.idx == idx &&
                                            internalLoading ? (
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            // <Spinner sm="sm" />
                                          ) : (
                                            "Buy"
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            !loading &&
                            !itemList && (
                              <div
                                className="no_data"
                                style={{ paddingLeft: "15rem" }}
                              >
                                No data found
                              </div>
                            )
                          )}
                        </>
                        {/* // )} */}
                      </div>
                    </div>
                    <div className="right_filleer desktop_drawer ">
                      <div>
                        <Marketfillter
                          list={categories}
                          filter={filter}
                          setfilter={setfilter}
                          // toggleDrawer={toggleDrawer}
                          onClose={toggleDrawer}
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
              )}

              {state?.tab == "Ships" && (
                <Fade>
                  <div className="left_Market">
                    <div className="Ships-main common_market_tab">
                      <div className="parates_list ship_list">
                        {/* {!filter?.isMore && loading ? (
                          <div className="text-center">
                            <Spinner color="primary" className="mt-5" />{" "}
                          </div>
                        ) : ( */}
                        <>
                          {itemList?.length > 0 &&
                            itemList[0]?.collection_id?.collectionType ==
                            "ships" ? (
                            <ul>
                              {items.map((item, idx) => {
                                return (
                                  <li
                                    key={item?._id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!localStorage.getItem("authUser")) {
                                        setinternalLoading(false);
                                        toast.error("Please login first!");
                                        return;
                                      }
                                      if (!userWallet.isActive) {
                                        setinternalLoading(false);
                                        toast.error(
                                          "Please connect metamask first!",
                                        );
                                        return;
                                      }
                                      !internalLoading
                                        ? navigate(`/item-detail/${item?._id}`)
                                        : console.log("err");
                                    }}
                                  >
                                    <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                    {/* <div className="parates_label" 
                                  style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                      <div className="nft_nameidhere mt-2">
                                        <p title={item?.name}>
                                          {stringShortner(item?.name, 16)}
                                        </p>
                                      </div>
                                      <img
                                        src={
                                          item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                          "/images/ship_level37_bgimg.png"
                                        }
                                        alt=""
                                        className="img-fluid level_bg"
                                      />
                                      <div className="btnup_card">
                                        <Button className="card-btnone">
                                          <div>
                                            {parseFloat(
                                              Number(item.price).toFixed(3),
                                            )}
                                            {""}
                                            {item.blockchain == "polygon" ? (
                                              <>
                                                <img
                                                  src="/images/polygon_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : item.blockchain == "BNB" ? (
                                              <>
                                                <img
                                                  src="/images/bnb_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 256 417"
                                                >
                                                  <path
                                                    fill="#343434"
                                                    d="m127.961 0l-2.795 9.5v275.668l2.795 2.79l127.962-75.638z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 0L0 212.32l127.962 75.639V154.158z"
                                                  />
                                                  <path
                                                    fill="#3C3C3B"
                                                    d="m127.961 312.187l-1.575 1.92v98.199l1.575 4.601l128.038-180.32z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 416.905v-104.72L0 236.585z"
                                                  />
                                                  <path
                                                    fill="#141414"
                                                    d="m127.961 287.958l127.96-75.637l-127.96-58.162z"
                                                  />
                                                  <path
                                                    fill="#393939"
                                                    d="m.001 212.321l127.96 75.637V154.159z"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </div>
                                        </Button>
                                        <Button
                                          className="card-btnbuy"
                                          disabled={internalLoading || loading}
                                          loading={internalLoading || loading}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedItem({
                                              item: item,
                                              idx,
                                            });
                                            console.log(
                                              item?.blockchain,
                                              userWallet.blockchain,
                                              "userWallet.blockchain",
                                            );
                                            if (!localStorage.getItem("authUser")) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please login first!"
                                              );
                                              return;
                                            }
                                            if (!userWallet.isActive) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please connect metamask first!"
                                              );
                                              return;
                                            }
                                            setBuyshow(true);
                                          }}
                                        >
                                          {selectedItem?.idx == idx &&
                                            internalLoading ? (
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            // <Spinner
                                            //   size={"sm"}
                                            // />
                                          ) : (
                                            "Buy"
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            !loading &&
                            !itemList && (
                              <div
                                className="no_data"
                                style={{ paddingLeft: "15rem" }}
                              >
                                No data found
                              </div>
                            )
                          )}
                        </>
                        {/* // )} */}
                      </div>
                    </div>
                    <div className="right_filleer desktop_drawer shipfilter_height_onmob ">
                      {/* <div
                          className={`right_filleer desktop_drawer shipfilter_height_onmob ${
                            draweropen && "drawer_active"
                          }`}
                        > */}
                      <div className={``}>
                        <Shipfillter
                          list={categories}
                          filter={filter}
                          // toggleDrawer={toggleDrawer}
                          setfilter={setfilter}
                          onClose={toggleDrawer}
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
              )}

              {state.tab == "Others" && (
                <Fade>
                  <div className="left_Market">
                    <div className="Ships-main other_main common_market_tab">
                      <div className="parates_list ship_list other_tab">
                        {/* {!filter?.isMore && loading ? (
                          <div className="text-center">
                            <Spinner color="primary" className="mt-5" />{" "}
                          </div>
                        ) : ( */}
                        <>
                          {itemList?.length > 0 ? (
                            <ul>
                              {items.map((item, idx) => {
                                return (
                                  <li
                                    key={item?._id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!localStorage.getItem("authUser")) {
                                        setinternalLoading(false);
                                        toast.error(
                                          "Please login first!"
                                        );
                                        return;
                                      }
                                      if (!userWallet.isActive) {
                                        setinternalLoading(false);
                                        toast.error(
                                          "Please connect metamask first!"
                                        );
                                        return;
                                      }
                                      !internalLoading
                                        ? navigate(`/item-detail/${item?._id}`)
                                        : console.log("err");
                                    }}
                                  >
                                    <div className="parates_div_main position-relative" style={mainStyle(item)}>
                                    {/* <div className="parates_label" 
                                  style={style(item)}
                                  >
                                         <small>LVL</small>
                                         <h5>{item?.level}</h5>
                                      </div> */}
                                      <div className="nft_nameidhere mt-2">
                                        <p title={item?.name}>
                                          {stringShortner(item?.name, 16)}
                                        </p>
                                      </div>
                                      <img
                                        src={
                                          item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net") ||
                                          "/images/ship_level37_bgimg.png"
                                        }
                                        alt=""
                                        className="img-fluid level_bg other-pirates-pets"
                                      />
                                      <div className="btnup_card">
                                        <Button className="card-btnone">
                                          <div>
                                            {parseFloat(
                                              Number(item.price).toFixed(3),
                                            )}
                                            {""}
                                            {item.blockchain == "polygon" ? (
                                              <>
                                                <img
                                                  src="/images/polygon_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : item.blockchain == "BNB" ? (
                                              <>
                                                <img
                                                  src="/images/bnb_icon.png"
                                                  alt=""
                                                  className="img-fluid btn_img"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 256 417"
                                                >
                                                  <path
                                                    fill="#343434"
                                                    d="m127.961 0l-2.795 9.5v275.668l2.795 2.79l127.962-75.638z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 0L0 212.32l127.962 75.639V154.158z"
                                                  />
                                                  <path
                                                    fill="#3C3C3B"
                                                    d="m127.961 312.187l-1.575 1.92v98.199l1.575 4.601l128.038-180.32z"
                                                  />
                                                  <path
                                                    fill="#8C8C8C"
                                                    d="M127.962 416.905v-104.72L0 236.585z"
                                                  />
                                                  <path
                                                    fill="#141414"
                                                    d="m127.961 287.958l127.96-75.637l-127.96-58.162z"
                                                  />
                                                  <path
                                                    fill="#393939"
                                                    d="m.001 212.321l127.96 75.637V154.159z"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </div>
                                        </Button>
                                        <Button
                                          className="card-btnbuy"
                                          disabled={internalLoading || loading}
                                          loading={internalLoading || loading}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedItem({
                                              item: item,
                                              idx,
                                            });
                                            console.log(
                                              item?.blockchain,
                                              userWallet.blockchain,
                                              "userWallet.blockchain",
                                            );
                                            if (!localStorage.getItem("authUser")) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please login first!"
                                              );
                                              return;
                                            }
                                            if (!userWallet.isActive) {
                                              setinternalLoading(false);
                                              toast.error(
                                                "Please connect metamask first!"
                                              );
                                              return;
                                            }
                                            setBuyshow(true);
                                          }}
                                        >
                                          {selectedItem?.idx == idx &&
                                            internalLoading ? (
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            // <Spinner
                                            //   size={"sm"}
                                            // />
                                          ) : (
                                            "Buy"
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            !loading && (
                              <div
                                className="no_data"
                                style={{ paddingLeft: "15rem" }}
                              >
                                No data found
                              </div>
                            )
                          )}
                        </>
                        {/* // )} */}
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
              {itemList?.length < totalItems && (
                <div className="text-center d-flex align-items-center justify-content-center">
                  <Button className="card-btnbuy" onClick={showMoreItems}>
                    Load More
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Marketdash, propsAreEqual);
