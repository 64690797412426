import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText ,Spinner} from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { createWithdraw } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Web3Intraction from "utils/web3Intraction";
import wallet from "../../hooks/metamask";
import { buyPackage } from "../../store/actions";

export default function BuyPackagemod(props) {
  const dispatch = useDispatch();

  const {
    selectedPackage,
    crystalCommission,
    recievedAmt,
    walletAddress,
    adminWalletAddress

  } = props;
  const {
    Register: { loading },
    User,
  } = useSelector((store) => store);
  const { user } = useSelector((state) => state?.Login);
  const userWallet = wallet();

  const { settings } = useSelector((state) => state.Login);
  const { userDetails } = useSelector((state) => state.User);
  const [finalValue, setFinalValue] = useState(0);
  const [internalLoading, setinternalLoading] = useState(false);
  const calculatedAmt = (crystalCommission * recievedAmt) / 100;

  const finalAmt = Number(recievedAmt) + Number(calculatedAmt?.toFixed(2));

  const onBuy = async (evt, buyType) => {
    evt.preventDefault();
    setinternalLoading(true);

    if (settings !== "{}") {
      const web3Intraction = new Web3Intraction(
        "BNB",
        settings
      );
      console.log("web3", web3Intraction);
      if(!web3Intraction.SIGNER){
        setinternalLoading(false);
        toast.error("Please connect metamask wallet!");
      }
      else if(!userWallet || !userWallet?.account){
        setinternalLoading(false);
        toast.error("Please connect metamask wallet!");
      }
      else{
        await userWallet.switchNetwork(web3Intraction?.networkUrl?.chainId);
        const getCollectionData = async (response) => {
          try {
            const receipt = await web3Intraction.buyCrystalPackage(walletAddress, adminWalletAddress, finalAmt ,userWallet);
            const data = {
              packageId: selectedPackage?._id,
              transactionHash: receipt?.transactionHash,
              walletAddress: receipt?.from,
              adminCommission: calculatedAmt
            };
            const callback = (res) => {
              setinternalLoading(false);
              if (res.status == "success") {
                toast.success("Package buy successfully");
                props.onhide();
                window.location.reload();
              } else {
                toast.error(res?.response?.data?.message);
                props.onhide();
              }
            }
            dispatch(buyPackage({ data }, callback));
          } catch (error) {
            if (error.code == -32603) {
              toast.error("Insufficient funds for gas * price + value");
            } else {
              toast.error(error?.message);
            }
            setinternalLoading(false);
          }
        };
        getCollectionData();
      }
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod change_pass"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={(event) => {
              event.preventDefault();
              props.onhide();
            }}
            className="close-btn"
            style={{ top: 11, right: 22 }}
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>

          <div className="modal_buy-cont login_inner mobile_pad">
            <h4 className="text-center">Buy</h4>
            <hr class="custom_hr"></hr>

            <div className="withdraw_info my-5">
              <ul>
                <li>
                  <p>Amount: </p>
                  <div className="span_wrap d-flex align-items-center gap-3">
                    <span className="color_diff d-flex align-items-center gap-1">
                      <div className="img_span">
                        <img
                          src="/images/diamond.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {selectedPackage?.crystalAmount}
                    </span>
                    <span className="color_diff">=</span>
                    <span className="color_diff d-flex align-items-center gap-1">
                      <div className="img_span">
                        <img
                          src="/images/busd_icon.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {recievedAmt} BUSD
                    </span>
                  </div>
                </li>

                <li>
                  <p>Fee:</p>
                  <span className="text-white">{crystalCommission}%</span>
                </li>

                <li>
                  <p>Total:</p>
                  <span className="color_diff d-flex align-items-center gap-1">
                    <div className="img_span">
                      <img
                        src="/images/busd_icon.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    {finalAmt} BUSD
                  </span>
                </li>

                {/* <li>
                  <p>Address: </p>
                  <span className="text-white address_span">
                    {walletAddress}
                  </span>
                </li> */}
              </ul>
            </div>

            {/* <Form className="form_login form_registration mt-4">
              <FormGroup className="mb-4 d-flex align-items-center gap-10 justify-content-between">
                <h5 className="text-white">Amount: </h5>
                <Input
                  type="number"
                  name="amount"
                  id="idamount"
                  placeholder="0.00"
                  className="w-75"
                />
              </FormGroup>
            </Form> */}

            <div className="modal_buy_btn">
              <Button onClick={onBuy} className="mod_confirmbtn "
                disabled={internalLoading}


              >
                {internalLoading ? <Spinner color="primary" /> : "Confirm"}
              </Button>

              <Button
                className="mod_cancelbtn"
                onClick={(e) => {
                  e.preventDefault();
                  props.onhide();
                }}
                disabled={internalLoading}

              >
                Cancel
                
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
