import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPackageHistory } from "../../store/actions";
import { toast } from "react-hot-toast";
import moment from "moment";

export default function Buyhistory() {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.User);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    if (userDetails?._id) {
      getHistory();
    }
  }, [userDetails]);

  const getHistory = () => {
    const callback = (res) => {
      if (res.status == "success") {
        setHistoryList(res?.data);
      } else {
        toast.error(res.message);
      }
    };
    dispatch(getPackageHistory({ userId: userDetails?._id }, callback));
  };

  return (
    <section className="History-main">
      <div className="history-head" onClick={getHistory}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M15 9.375V15"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.875 17.8125L15 15"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.41406 11.6836H3.72656V6.99609"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.71094 22.2891C9.15283 23.7321 10.9903 24.7151 12.991 25.1137C14.9916 25.5123 17.0656 25.3086 18.9504 24.5283C20.8353 23.748 22.4464 22.4263 23.5799 20.7302C24.7134 19.0341 25.3185 17.04 25.3185 15C25.3185 12.96 24.7134 10.9659 23.5799 9.26981C22.4464 7.57375 20.8353 6.25197 18.9504 5.47169C17.0656 4.69141 14.9916 4.48768 12.991 4.88627C10.9903 5.28486 9.15283 6.26787 7.71094 7.71094L3.72656 11.6836"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>History</p>
      </div>

      <div className="history_list amount_table mt-4">
        <Table responsive>
          <tbody>
            {historyList.map((item, idx) => {
              return (
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div className="table_dimond">
                        <img
                          src="/images/diamond.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {item?.crystalAmount}
                    </div>
                  </td>
                  <td className="desktop_adrees">
                      <p className="buy_his_add">
                      {item?.transactionHash}
                      {/* <Button
                          className="copy_code_btn p-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1_10707)">
                              <g filter="url(#filter0_d_1_10707)">
                                <path
                                  d="M12.8253 1.5H9.67531C7.08781 1.5 6.03781 2.5275 6.00781 5.0625H8.32531C11.4753 5.0625 12.9378 6.525 12.9378 9.675V11.9925C15.4728 11.9625 16.5003 10.9125 16.5003 8.325V5.175C16.5003 2.55 15.4503 1.5 12.8253 1.5Z"
                                  fill="#79E7FB"
                                />
                              </g>
                              <g filter="url(#filter1_d_1_10707)">
                                <path
                                  d="M8.325 6H5.175C2.55 6 1.5 7.05 1.5 9.675V12.825C1.5 15.45 2.55 16.5 5.175 16.5H8.325C10.95 16.5 12 15.45 12 12.825V9.675C12 7.05 10.95 6 8.325 6ZM9.2175 10.2375L6.435 13.02C6.38497 13.0704 6.32536 13.1104 6.25967 13.1374C6.19398 13.1645 6.12354 13.1781 6.0525 13.1775C5.98146 13.1781 5.91102 13.1645 5.84533 13.1374C5.77964 13.1104 5.72003 13.0704 5.67 13.02L4.275 11.625C4.22507 11.5754 4.18544 11.5164 4.1584 11.4514C4.13136 11.3864 4.11744 11.3167 4.11744 11.2463C4.11744 11.1758 4.13136 11.1061 4.1584 11.0411C4.18544 10.9761 4.22507 10.9171 4.275 10.8675C4.485 10.6575 4.8225 10.6575 5.0325 10.8675L6.045 11.88L8.4525 9.4725C8.6625 9.2625 9 9.2625 9.21 9.4725C9.42 9.6825 9.4275 10.0275 9.2175 10.2375Z"
                                  fill="#53BCCF"
                                />
                              </g>
                            </g>
                            <defs>
                              <filter
                                id="filter0_d_1_10707"
                                x="2.00781"
                                y="1.5"
                                width="18.4922"
                                height="18.4926"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_1_10707"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_1_10707"
                                  result="shape"
                                />
                              </filter>
                              <filter
                                id="filter1_d_1_10707"
                                x="-2.5"
                                y="6"
                                width="18.5"
                                height="18.5"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_1_10707"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_1_10707"
                                  result="shape"
                                />
                              </filter>
                              <clipPath id="clip0_1_10707">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          
                        </Button> */}
                      </p>
                      
              </td>
                  <td>

                    <div className="d-flex align-items-center gap-2">
                      <div className="table_dimond">
                        <img
                          src="/images/bnb_icon.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {item?.totalPaid}
                    </div>
                  </td>
                  <td>{moment(item?.created_date).local().format("DD MMM YYYY hh:mm A")}</td>

                  <td>
                    <Button className="btn_history_show">
                      <span className="text-capitalize his-confirmed">{item?.status}</span>

                    </Button>
                  </td>
                 
                  <p className="mobile_address">
                  {item?.transactionHash}
                </p>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </section>
  );
}
