import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawHistory } from "../../store/actions";
import { toast } from "react-hot-toast";
import moment from "moment";
export default function History() {

  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.User);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {

    if (userDetails?._id) {
      getHistory()
    }
  }, [userDetails])


  const getHistory =()=>{
    const callback = (res) => {
      if (res.status == "success") {
        setHistoryList(res?.data)
      } else {
        toast.error(res.message);
      }
    };
    dispatch(getWithdrawHistory({ user_id: userDetails?._id }, callback));
  }

  return (
    <section className="History-main">
      <div className="history-head" onClick={getHistory} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M15 9.375V15"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.875 17.8125L15 15"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.41406 11.6836H3.72656V6.99609"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.71094 22.2891C9.15283 23.7321 10.9903 24.7151 12.991 25.1137C14.9916 25.5123 17.0656 25.3086 18.9504 24.5283C20.8353 23.748 22.4464 22.4263 23.5799 20.7302C24.7134 19.0341 25.3185 17.04 25.3185 15C25.3185 12.96 24.7134 10.9659 23.5799 9.26981C22.4464 7.57375 20.8353 6.25197 18.9504 5.47169C17.0656 4.69141 14.9916 4.48768 12.991 4.88627C10.9903 5.28486 9.15283 6.26787 7.71094 7.71094L3.72656 11.6836"
            stroke="#46C7A8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>History</p>
      </div>

      <div className="history_list amount_table mt-4">
        <Table responsive>
          <tbody>
            {historyList.map((item, idx) => {
              return (
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div className="table_dimond">
                        <img
                          src="/images/diamond.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {item?.crystalAmount}
                    </div>
                  </td>
                  <td>{moment(item?.created_date).local().format("DD MMM YYYY hh:mm A")}</td>
                  
                  <td>
                    <Button className="btn_history_show">
                      <span className="text-capitalize his-confirmed">{item?.status}</span>
                      {/* <span className="his-pending">Pending</span>
                      <span className="his-rejected">Rejected</span> */}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </section>
  );
}
