export const ITEM_API_FAIL = "ITEM_API_FAIL";

// ITEMs
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

export const GET_ITEMS2 = "GET_ITEMS2";
export const GET_ITEMS2_FAIL = "GET_ITEMS2_FAIL";
export const GET_ITEMS2_SUCCESS = "GET_ITEMS2_SUCCESS";
export const RESET_ITEM = "RESET_ITEM";

// Get ITEM
export const GET_ITEM = "GET_ITEM";
export const GET_ITEM_BY_TOKEN = "GET_ITEM_BY_TOKEN";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";

// Buy ITEM
export const POST_ITEM_BUY = "POST_ITEM_BUY";
export const POST_ITEM_BUY_FAIL = "POST_ITEM_BUY_FAIL";
export const POST_ITEM_BUY_SUCCESS = "POST_ITEM_BUY_SUCCESS";

// Get PPT Price
export const GET_PPT_PRICE = "GET_PPT_PRICE";
export const GET_PPT_PRICE_FAIL = "GET_PPT_PRICE_FAIL";
export const GET_PPT_PRICE_SUCCESS = "GET_PPT_PRICE_SUCCESS";

// Get PPT Price
export const SWAP_API = "SWAP_API";
export const SWAP_API_FAIL = "SWAP_API_FAIL";
export const SWAP_API_SUCCESS = "SWAP_API_SUCCESS";

// Get PPT Price
export const GET_PPT_PRICE_PANCAKE = "GET_PPT_PRICE_PANCAKE";
export const GET_PPT_PRICE_PANCAKE_FAIL = "GET_PPT_PRICE_PANCAKE_FAIL";
export const GET_PPT_PRICE_PANCAKE_SUCCESS = "GET_PPT_PRICE_PANCAKE_SUCCESS";

// Get PPT Price
export const PANCAKE_SWAP_API = "PANCAKE_SWAP_API";
export const PANCAKE_SWAP_API_FAIL = "PANCAKE_SWAP_API_FAIL";
export const PANCAKE_SWAP_API_SUCCESS = "PANCAKE_SWAP_API_SUCCESS";

//Add history
export const ADD_TRANSACTION_HISTORY = "ADD_TRANSACTION_HISTORY";
export const ADD_TRANSACTION_HISTORY_SUCCESS =
  "ADD_TRANSACTION_HISTORY_SUCCESS";
export const ADD_TRANSACTION_HISTORY_FAIL = "ADD_TRANSACTION_HISTORY_FAIL";

export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_HISTORY_SUCCESS =
  "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAIL = "GET_TRANSACTION_HISTORY_FAIL";

export const COUNT_PIRATE_BUY = "COUNT_PIRATE_BUY";
export const COUNT_PIRATE_BUY_SUCCESS =
  "COUNT_PIRATE_BUY_SUCCESS";
export const COUNT_PIRATE_BUY_FAIL = "COUNT_PIRATE_BUY_FAIL";


// INVENTORY ITEMS
export const GET_INVENTORY_ITEMS_REQUEST = "GET_INVENTORY_ITEMS_REQUEST";
export const GET_INVENTORY_ITEMS_FAIL = "GET_INVENTORY_ITEMS_FAIL";
export const GET_INVENTORY_ITEMS_SUCCESS = "GET_INVENTORY_ITEMS_SUCCESS";