import Web3 from "web3";
import { ContractFactory, Contract, ethers , utils} from "ethers";
import {
  getNetworkUrl,
  getContractDetails,
  SendTokenAbi,
  busdLiveContractAddress,
  busdTestNetContractAddress
} from "../helpers/constants";
import toast from "react-hot-toast";
import busdabi from '../utils/busdabi.json';

class Web3Intraction {

  constructor(blockchain, settings) {
    const networkUrl = getNetworkUrl(blockchain || "BNB", settings);
    // console.log(networkUrl, "networkUrl")
    const provider = (window.ethereum != null) ? ethers.providers.getDefaultProvider(networkUrl?.url) : null;

    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        window.ethereum,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );
      console.log(this.PROVIDER.getSigner(), "provvvider")
      this.SIGNER = this.PROVIDER.getSigner();
    }
    else {
      console.log("no provider");
    }

    this.settings = settings;
    this.networkUrl = networkUrl;
    this.adminContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings
    );
  }

  convertPriceToEther = (price) => {
    return ethers.utils.parseEther(price?.toString())._hex;
    // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
  };

  getContract = (abi, address) => {
    try {
      let contract = new Contract(address, JSON.parse(abi), this.SIGNER);
      return contract;
    } catch (error) {
      console.log("error1", error);
      return null;
    }
  };

  getAccountBalance = async () => {
    try {
      const adminContract = this.getContract(
        JSON.stringify(this.adminContractSetting.abi),
        this.adminContractSetting.contractAddress
      );
      let PPT_balance = await adminContract.balanceOf(this.SIGNER.address);
      PPT_balance = ethers.utils.formatEther(PPT_balance);
      let balance = await this.PROVIDER.getBalance(this.SIGNER.address);
      return { balance: ethers.utils.formatEther(balance), PPT_balance };
    } catch (error) {
      return null;
    }
  };
  /**
   * Deploy collection contract.
   *
   * @param {object} collectionData Collection Details (ie. abi, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployContract = (collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factory = new ContractFactory(
          JSON.parse(collection?.data?.abi),
          collection.data.bytecode,
          this.SIGNER
        );

        const contract = await factory.deploy();
        let receipt = await contract.deployTransaction.wait();

        console.log(receipt, "receipt");
        callback &&
          callback(null, { txHash: receipt.transactionHash, receipt });
        resolve({ txHash: receipt.transactionHash, receipt });
      } catch (error) {
        // callback && callback(error.message);
        reject(error.message);
      }
    });
  };

  /**
   * Check user approved contract transactions, if not then make transaction to approve.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */

  verifyApproved = (userWallet, collection, callback, type = "") => {
    console.log(userWallet, collection, "hereeeeeeeeeee");

    return new Promise(async (resolve, reject) => {
      if (
        (collection.abi || collection.data.contractAbi) &&
        collection.data.contractAddress
      ) {
        console.log(collection, "<==collection");
        try {
          const contract = this.getContract(
            collection.abi ? collection.abi : collection.data.contractAbi,
            collection.data.contractAddress
          );
          console.log(contract, "<==settings");

          if (!contract) {
            const error_message = "Invalid Contract";
            callback && callback(error_message);
            reject(error_message);
            return;
          }

          const isApproved = await contract.isApprovedForAll(
            userWallet,
            this.settings.walletAddress.publicKey
          );
          console.log(isApproved, "<==isApproved");
          if (isApproved) {
            callback && callback(null, collection);
            resolve(collection);
            return;
          }

          try {
            const transaction = await contract.setApprovalForAll(
              this.settings.walletAddress.publicKey,
              true
            );

            const receipt = await transaction.wait();
            console.log(receipt, "receipt");

            callback && callback(null, receipt);
            resolve(receipt);
          } catch (error) {
            callback && callback(error.message);
            reject(error.message);
          }
        } catch (error) {
          reject(error.message);
        }
      } else {
        const error_message = "No Collection Data!";
        callback && callback(error_message);
        reject(error_message);
      }
    });
  };
  /**
   * Mint NFT
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  mintNFT = (userWallet, collection, item, callback = null) => {
    return new Promise(async (resolve, reject) => {
      if (collection.abi && collection.data.contractAddress) {
        const contract = this.getContract(
          collection.abi,
          collection.data.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        try {
          const transaction = await contract.mintNFT(
            userWallet,
            item.token_uri
          );
          const receipt = await transaction.wait();

          if (!!receipt?.logs && !!receipt.logs[0]) {
            receipt.token_id = Web3.utils.hexToNumberString(
              receipt.logs[0].topics[3]
            );
          }
          callback && callback(null, receipt);
          resolve(receipt);
        } catch (error) {
          callback && callback(error);
          reject(error);
        }
      }
    });
  };

  buyCrystalPackage = (walletAddress, adminWalletAddress, amount, userWallet) => {
    return new Promise(async (resolve, reject) => {

      let networkMode = this.settings?.blockchainNetworkMode;

      let item = this.settings?.blockchain[0].networkUrl.filter((item) => {
        return item.type == networkMode;
      });
      await userWallet.switchNetwork(item[0].chainId);

      const contract = this.getContract(
        JSON.stringify(busdabi),
        networkMode == "testnet" ? busdTestNetContractAddress : busdLiveContractAddress
      );

      if (!contract) {
        const error_message = "Invalid Contract";
        // callback && callback(error_message);
        reject(error_message);
        return;
      }
      try {
        const isConformed = await contract.increaseAllowance(walletAddress, Web3.utils.toWei(amount.toString()));
        const isConformedreceipt = await isConformed.wait();

        if (isConformedreceipt.status == 1) {
          const transaction = await contract.transferFrom(
            walletAddress,
            adminWalletAddress,
            Web3.utils.toWei(amount.toString()), {
            gasLimit: "3000000"
          }
          );
          const receipt = await transaction.wait();
          if (!!receipt?.logs && !!receipt.logs[0]) {
            receipt.token_id = Web3.utils.hexToNumberString(
              receipt.logs[0].topics[3]
            );
          }
          resolve(receipt);
        } else {
          reject("increaseAllowance failed")
        }
      } catch (error) {
        if (error?.code == "ACTION_REJECTED") {
          reject({ message: "Transaction has been rejected" })
        } else {
          console.log("error", error)
          reject(error);
        }
      }
    });
  };
  getNonce = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        let nonce = await this.PROVIDER.getTransactionCount(address);
        resolve(nonce);
      } catch (error) {
        console.log(error, "<==== err in getNonce");
        reject(error);
      }
    });
  };

  /**
   * Transfer token to Other account
   *
   * @param {object} contract Contract
   * @param {Object} data Data
   * @param {string} amount amount
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */

  transaferToken = (contract, data, amount) => {
    return new Promise(async (resolve, reject) => {
      try {
        let balance = await contract.balanceOf(data?.from);
        if (Number(balance.toString()) <= Number(amount)) {
          reject({
            message: `You Don't have enough ${this.networkUrl.symbol}`,
          });
        }

        let numberOfTokens = convertToDecimal(amount, 18);

        let tokenTransferRes = await contract.transfer(
          data.to,
          numberOfTokens,
          {
            gasLimit: convertToHex("0x100000"), // 100000
            gasPrice: data.gasPrice,
          }
        );

        // let reciept = await tokenTransferRes.wait();

        resolve(tokenTransferRes);
      } catch (error) {
        console.log(error, "Error in transaferToken func");
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      }
    });
  };

  sendEVMChainTransaction = (toAddress, amount, sendType) => {
    return new Promise(async (resolve, reject) => {
      try {
        //get Nonce
        let nonce = await this.getNonce(this.SIGNER.address);
        //get Gas price START
        let gas_price = await this.PROVIDER.getGasPrice();
        let gasPriceInEth = formatEther(gas_price);
        gas_price = convertToHex(parseInt(gas_price));

        const tx = {
          from: this.SIGNER.address,
          to: toAddress,
          nonce: nonce,
          value: convertPriceToEther(amount),
          gasLimit: convertToHex("0x100000"), // 3000000
          gasPrice: gas_price,
        };

        if (sendType == "PPT") {
          const contract = this.getContract(
            JSON.stringify(SendTokenAbi),
            this.adminContractSetting.contractAddress
          );

          let tokenTransferRes = await this.transaferToken(
            contract,
            tx,
            amount
          );

          resolve({
            ...tokenTransferRes,
            nonce: tx?.nonce,
            gasLimit: tx.gasLimit,
            gasPrice: tx.gasPrice,
          });

          // ether send
        } else {
          const transaction = await this.SIGNER.sendTransaction(tx);
          let receipt = await transaction.wait();
          resolve({
            ...transaction,
            nonce: tx?.nonce,
            gasLimit: tx.gasLimit,
            gasPrice: gasPriceInEth,
          });
        }
      } catch (error) {
        console.log(error, 547455454);
        reject(JSON.parse(JSON.stringify(error))?.reason);
      }
    });
  };

  /**
   * Transfer balance to NFT owner account
   *
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  sendTransaction = (item, buyType, userWallet = '') => {
    console.log("send transaction", item, buyType, userWallet)
    return new Promise(async (resolve, reject) => {
      const adminContract = this.getContract(
        JSON.stringify(item?.collection_id.contractAbi),
        item?.collection_id.contractAddress
      );
      const erc20 = this.getContract(
        JSON.stringify(this.adminContractSetting.abi),
        this.adminContractSetting.contractAddress
      );
      let transaction = "";

      //get Gas price START
      // let gas_price = await this.PROVIDER.getGasPrice();
      // gas_price = convertToHex(parseInt(gas_price));
      // console.log(gas_price, "gasprice")
      if (buyType == 1) {
        item = { ...item, price: 0.05 / item.price };
      }

      try {
        const options = {
          value: ethers.utils
            .parseUnits(item.price?.toString() || "0", "ether")
            .toString(),
        };

        buyType == 1 &&
          (await erc20.increaseAllowance(
            item?.collection_id.contractAddress,
            JSON.stringify(item.price * 10 ** 18)
          ));

        let balance = 0;

        if (buyType == 1) {
          balance = await erc20.balanceOf(this.SIGNER.address);
          balance = ethers.utils.formatEther(balance);
          if (Number(balance.toString()) <= Number(item.price)) {
            return toast.error(`You don't have enough PPT`);
          }

          if (item?.collection_id?.collectionType == "ships") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              item?.collection_id?.collectionType,
              buyType == 0
                ? ethers.utils.parseUnits(
                  item.price?.toString() || "0",
                  "ether"
                ).toString()
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              item.attributes.Durablity,
              // item.attributes._storm,
              // item.attributes.Fire,
              // item.attributes.Ice,
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
              }
            );
          }
          else if (item?.collection_id?.collectionType == "pirates") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              buyType == 0
                ? ethers.utils.parseUnits(
                  item.price?.toString() || "0",
                  "ether"
                ).toString()
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              item.attributes.level,
              item.attributes.Durablity,
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
              }
            );
          }
          else {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              buyType == 0
                ? ethers.utils.parseUnits(
                  item.price?.toString() || "0",
                  "ether"
                ).toString()
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
              }
            );
          }
        }
        else {
          if (item?.collection_id?.collectionType == "ships") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              item?.collection_id?.collectionType,
              item?.subcategory_id?.catName || "Galleon",
              "UserMint",
              ethers.utils.parseUnits(item.price.toString() || "0", "ether").toString(),
              item.attributes.level,
              item.attributes.Durablity,
              // item.attributes.storm,
              // item.attributes.Fire,
              // item.attributes.Ice,
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
                ...options,
              }
            );
          }
          else if (item?.collection_id?.collectionType == "pirates") {
            console.log(item?.collection_id.contractAddress, "pirates");
            console.log(adminContract, "adminContract");
            const valueInEther = item?.price?.toString();
            console.log("price", valueInEther)
            const valueInWei = ethers.utils.parseEther(valueInEther);
            console.log("item price", valueInWei.toString())
            console.log(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              valueInWei.toString(),
              ethers.utils.parseUnits(item.price?.toString() || "0", "ether").toString(),
              "UserMint",
              item.attributes.level,
              item.attributes.Durablity,
              { ...options }
            )

            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              ethers.utils.parseEther(item.price?.toString() || "0", "ether").toString(),
              "UserMint",
              item.attributes.level,
              item.attributes.Durablity,
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
                ...options,
              }
            );
          }
          else {
            console.log(item?.collection_id.contractAddress, "others");
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              ethers.utils.parseUnits(item.price?.toString() || "0", "ether").toString(),
              "UserMint",
              {
                // gasLimit: 300000,
                // gasPrice: gas_price,
                ...options,
              }
            );
          }
        }
        console.log(transaction, "transaction")
        const receipt = await transaction.wait();
        resolve({ ...receipt, buyAmount: Number(balance.toString()) });
      } catch (error) {
        console.log("catch error", error);
        if (error.code == -32603) {
          reject("Insufficient funds for gas * price + value");
        }
        else if (error.code == "ACTION_REJECTED") {
          reject("Transaction request rejected!");
        }
        else {
          reject(error.code || error.data?.message || error.message || error);
        }
      }
    });
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  console.log(price, "fdfdgf");
  return ethers.utils.parseEther(price?.toString())._hex;

  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};
export const convertToHex = (value) => ethers.utils.hexlify(parseInt(value));

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToDecimal = (value, decimal) => {
  return ethers.utils.parseUnits(value, decimal);
};
export const formatEther = (value) => {
  return ethers.utils.formatEther(value);
};

export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) => Web3.utils.fromWei(number, unit || "ether");