import { call, put, takeEvery } from "redux-saga/effects";

// Item Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

import * as API from "../../helpers/backend_helper";

function* fetchCategories({ payload }) {
  try {
    const response = yield call(API.getCategories, payload);
    if (response.status === "failure") {
      return yield put(ACTIONS.getCategoriesFail(response.message));
    }
    yield put(ACTIONS.getCategoriesSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getCategoriesFail(error));
  }
}

function* CategorySaga() {
  yield takeEvery(TYPES.GET_CATEGORIES, fetchCategories);
}

export default CategorySaga;
