import React from "react";

import Header from "../Component/Header/Header";
import Profile from "../Pages/Profile/Profile";
import Footer from "../Component/Footer/Footer";

export default function Profiletheme() {
  return (
    <>
      <Header />
      <Profile />
      <Footer/>
    </>
  );
}
