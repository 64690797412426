import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";

export default function Safetyalertmodal(props) {
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod change_pass holdreveal_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={props.onhide}
            className="close-btn"
            style={{ top: 11, right: 22 }}
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>
          <div className="hold_key_modal login_inner position-relative">
            <h5>Safety alert </h5>
            <p>
            Screenshots aren't a safe way to keep truck of your Private Key. Store it somewhere that isn't backed up online to keep your account safe.
            </p>

            <div className="sinlge_page_btn">
              <Button className="cancel_Single" onClick={props.onhide}>Got it</Button>
              <Button className="buy_single">Learn More</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
