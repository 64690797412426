import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import RegisterSaga from "./auth/register/saga";

//User
import UserSaga from "./user/saga";

//Items
import Items from "./items/saga"

//Categories
import Category from "./category/saga"

//Collection
import Collection from "./collection/saga"

//File
import File from "./File/saga"

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    fork(RegisterSaga),
    fork(UserSaga),
    fork(Items),
    fork(Category),
    fork(Collection),
    fork(File)
  ]);
}
