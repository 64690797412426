import * as TYPES from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  searchLoading: false,
  fetchLatestBalance: "",
  items: [],
  totalItems: 0,
  item: {},
  itemDetails: null,
  activities: [],
  bids: [],
  loading2: false,
  totalCount: 0,
  totalBids: 0,
  swapLoading: false,
  totalEarning: [],
  searchData: [],
  favourite: [],
  totalfavourite: [],
  abuse: [],
  featuredItems: [],
  buyPirateCounts: 0,
  inventoryItems: [],
  totalInventoryItems: 0,
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Items */
    case TYPES.GET_ITEMS:
      return {
        ...state,
        items: [],
        totalItems: 0,
        loading: true,
      };

    case TYPES.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action?.payload?.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        items: [],
        loading: false,
      };

    case TYPES.GET_ITEMS2:
      return {
        ...state,
        loading2: true,
        items: []
      };

    case TYPES.GET_ITEMS2_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action?.payload?.totalCount || 0,
        loading2: false,
        error: "",
      };

    case TYPES.GET_ITEMS2_FAIL:
      return {
        ...state,
        error: action.payload,
        items: [],
        loading2: false,
      };
    /* Get Items END */

    /* Get Item */
    case TYPES.GET_ITEM:
      return {
        ...state,
        loading: true,
        item: {}
      }
    case TYPES.GET_ITEM_BY_TOKEN:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.GET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    // case TYPES.RESET_ITEM:
    //   return {
    //     ...state,
    //     item: {},
    //     itemDetails: [],
    //   };
    case TYPES.GET_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload.data,
        itemDetails: action.payload,
      };
    /* Add Item END */
    /* Post Item Buy */
    case TYPES.POST_ITEM_BUY:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.POST_ITEM_BUY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.POST_ITEM_BUY_SUCCESS:
      return {
        ...state,
        loading: false,
        itemDetails: action.payload,
        error: "",
      };
    /* Post Item Buy END */

    /**Add history Start */
    case TYPES.ADD_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchLatestBalance: action.payload,
      };
    case TYPES.ADD_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
      };
    /**Add history End */

    /**Add history Start */
    case TYPES.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionHistory: action.payload.data || [],
        totalCount: action.payload.totalCount,
      };
    case TYPES.GET_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
      };
    /**Add history End */

    /**Get ppt price Start */
    case TYPES.GET_PPT_PRICE:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PPT_PRICE_SUCCESS:
    case TYPES.GET_PPT_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };
    /**Get ppt price End */

    /**Swap api Start */
    case TYPES.SWAP_API:
      return {
        ...state,
        swapLoading: true,
      };
    case TYPES.SWAP_API_SUCCESS:
    case TYPES.SWAP_API_FAIL:
      return {
        ...state,
        swapLoading: false,
      };
    /**Swap api End */

    /**Get ppt price Start For Pancake */
    case TYPES.GET_PPT_PRICE_PANCAKE:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PPT_PRICE_PANCAKE_SUCCESS:
    case TYPES.GET_PPT_PRICE_PANCAKE_FAIL:
      return {
        ...state,
        loading: false,
      };
    /**Get ppt price End For Pancake */

    /**Swap api Start For Pancake */
    case TYPES.PANCAKE_SWAP_API:
      return {
        ...state,
        swapLoading: true,
      };
    case TYPES.PANCAKE_SWAP_API_SUCCESS:
    case TYPES.PANCAKE_SWAP_API_FAIL:
      return {
        ...state,
        swapLoading: false,
      };
    /**Swap api End For Pancake */
    /** Count pirates buy */
    case TYPES.COUNT_PIRATE_BUY:
      return {
        ...state,
        loading: true,
      };

    case TYPES.COUNT_PIRATE_BUY_SUCCESS:
      return {
        ...state,
        buyPirateCounts: action.payload.data || 0,
        loading: false,
        error: "",
      };

    case TYPES.COUNT_PIRATE_BUY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /** Count pirates buy End */
    /* Get Inventory Items */
    case TYPES.GET_INVENTORY_ITEMS_REQUEST:
      return {
        ...state,
        inventoryItems: [],
        totalInventoryItems: 0,
        loading: true,
      };

    case TYPES.GET_INVENTORY_ITEMS_SUCCESS:
      return {
        ...state,
        inventoryItems: action.payload.data || [],
        totalInventoryItems: action?.payload?.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_INVENTORY_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        inventoryItems: [],
        loading: false,
      };

    default:
      return state;
  }
};

export default Items;
