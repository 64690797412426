import React from "react";

import Header from "../Component/Header/Header";
import Privatekeypage from "Pages/Privatekeypage";
import Footer from "../Component/Footer/Footer";


export default function Privatekeytheme() {
  return (
    <>
      <Header />
      <Privatekeypage/>
      <Footer/>
    </>
  );
}
