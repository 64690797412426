import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
// import { Route, Routes } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useRouteMatch,
  Outlet,
  Navigate
} from "react-router-dom";

// importing all the themes
import Logintheme from "../themes/login-theme";
import Registertheme from "../themes/register-theme";
import ForgotPasswordtheme from "../themes/forgot-password-theme";
import wallet from "../hooks/metamask";
//
import Marketplacetheme from "../themes/marketplace-theme";
import ProductSingletheme from "../themes/productsingle-theme";
import Sendrecievetheme from "../themes/sendrecieve-theme";
import Inventorytheme from "../themes/inventory-theme ";
import Inventorysingletheme from "../themes/inventorysingle-theme";
import Paratestheme from "../themes/paratessingle-theme";
import Profiletheme from "../themes/profile-theme";
import Editprofiletheme from "../themes/edit-profile-theme";
import Privatekeytheme from "themes/privatekey-theme";
import Withdrawtheme from "themes/withdraw-theme";

import { useSelector, useDispatch } from "react-redux";

// Store
import { getSettings } from "../store/actions";
import Web3Intraction from "../utils/web3Intraction";
import PrivacyTheme from "../themes/Privacy";

//Google Analytic
const TRACKING_ID = "G-D8ES7BMPH1"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

export function ProtectedWrapper({ children }) {
  const {
    Login: { user },
  } = useSelector((state) => state);

  if (user.Token) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}

export default function MyRouts() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.Login);
  const userWallet = wallet();
  const {
    Login: { user },
    User: { userDetails },
  } = useSelector((state) => state);

  // const userWallet = wallet();
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("isMetamaskConnected") == "yes") {
      if (settings && settings?.blockchain) {
        let blockchain = settings?.blockchain?.filter(data => data.type == "BNB");
        blockchain = blockchain[0];
        let networkUrl = blockchain?.networkUrl?.filter(data => data.type == settings?.blockchainNetworkMode)
        networkUrl = networkUrl[0];
        userWallet.connect("metamask", networkUrl?.chainId);
      }
    }
  }, [localStorage.getItem("isMetamaskConnected"), settings]);

  // useEffect(() => {
  //   if (!user.settingData) return;
  //   userWallet.getAccountInfo();
  // }, [user]);

  // useEffect(() => {
  //   if (!userWallet.isActive) return;
  //   let web3Intraction = new Web3Intraction(
  //     "BNB",
  //     userWallet.pvtKey,
  //     user.settingData
  //   );
  //   console.log(web3Intraction, "web3Intraction")
  //   userWallet.setprovider(web3Intraction);
  //   userWallet.setBlockchain("BNB");
  // }, [userWallet.account]);

  return (
    <div>
      <Routes>
          {/* <Route path="/" element={<ProtectedWrapper />}> */}
          <Route exact path="/" element={<Marketplacetheme />} />
          <Route exact path="/marketplace" element={<Marketplacetheme />} />
          {!user.Token && (
            <>
              <Route exact path="/login" element={<Logintheme />} />
              <Route exact path="/register" element={<Registertheme />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPasswordtheme />}
              />
              <Route
                exact
                path="/privacy-policy/:type"
                element={<PrivacyTheme />}
              />
              <Route
                exact
                path="/terms-and-conditions/:type"
                element={<PrivacyTheme />}
              />
            </>
          )}
          {/* {user.Token && ( */}
            <>
              {/* <Route exact path="/" element={<Marketplacetheme />} /> */}
              <Route exact path="/inventory" element={<ProtectedWrapper><Inventorytheme /></ProtectedWrapper>} />
              <Route exact path="/profile" element={<ProtectedWrapper><Profiletheme /></ProtectedWrapper>} />
              <Route exact path="/edit-profile" element={<ProtectedWrapper><Editprofiletheme /></ProtectedWrapper>} />
              <Route exact path="/withdraw" element={<ProtectedWrapper><Withdrawtheme /></ProtectedWrapper>} />


              <Route
                exact
                path="/product-single37"
                element={<ProductSingletheme />}
              />

              <Route exact path="/item-detail/:_id" element={<ProtectedWrapper><Paratestheme /></ProtectedWrapper>} />

              <Route
                exact
                path="/inventory-single37"
                element={<ProtectedWrapper><Inventorysingletheme /></ProtectedWrapper>}
              />

              <Route exact path="/send-recieve" element={<ProtectedWrapper><Sendrecievetheme /></ProtectedWrapper>} />

              <Route exact path="/privatekey-steps" element={<ProtectedWrapper><Privatekeytheme /></ProtectedWrapper>} />
            </>
          {/* )} */}
        {/* </Route> */}
      </Routes>
    </div>
  );
}
