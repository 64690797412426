import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function Buymod(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={(event) => {
              event.preventDefault()
              props.onhide()
            }}
            className="close-btn"
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>

          <div className="modal_buy-cont">
            <h4>Buy Item</h4>
            <hr class="custom_hr"></hr>
            <p>{props?.title}</p>
            <p>Are you sure to buy this item?</p>
            <p>{parseFloat(Number(props?.item?.price).toFixed(3))} {"BNB"}</p>

            <div className="modal_buy_btn">
              <Button
                className="mod_cancelbtn"
                onClick={(e) => {
                  e.preventDefault()
                  props.onhide()
                }}
                disabled={props.internalLoading}
              >
                Cancel
              </Button>
              <Button
                className="mod_confirmbtn "
                onClick={(e) => {
                  e.preventDefault()
                  props.submit(e, 0)
                }
                }
                disabled={props.internalLoading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
