import * as TYPES from "./actionTypes";

/* Get Category */
export const getCategories = (data) => ({
  type: TYPES.GET_CATEGORIES,
  payload: data,
});

export const getCategoriesSuccess = (data) => ({
  type: TYPES.GET_CATEGORIES_SUCCESS,
  payload: data,
});

export const getCategoriesFail = (error) => ({
  type: TYPES.GET_CATEGORIES_FAIL,
  payload: error,
});
/* Get Category END */

