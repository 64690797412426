import React, { useEffect } from "react";

import Header from "../Component/Header/Header";
import Marketplace from "../Pages/Marketplace/Marketplace";
import Footer from "../Component/Footer/Footer";
import { useNavigate } from "react-router-dom";

export default function Marketplacetheme() {
const navigate = useNavigate();


useEffect(()=>{

  navigate("/marketplace")

},[])
  return (
    <>
      <Header />
      <Marketplace />
      <Footer/>
    </>
  );
}
