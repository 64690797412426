import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";

// Item Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

import * as API from "../../helpers/backend_helper";

function* fetchItems({ payload }) {
  try {
    const response = yield call(API.getItems, payload);
    if (response.status === "failure") {
      return yield put(ACTIONS.getItemsFail(response.message));
    }
    yield put(ACTIONS.getItemsSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getItemsFail(error));
  }
}
function* getPirateCountBuy({ payload }) {
  try {
    const response = yield call(API.buyPirateCount, payload);
    if (response.status === "failure") {
      return yield put(ACTIONS.countPirateBuyFail(response.message));
    }
    yield put(ACTIONS.countPirateBuySuccess(response));
  } catch (error) {
    yield put(ACTIONS.countPirateBuyFail(error));
  }
}
function* fetchItems2({ payload }) {
  try {
    const response = yield call(API.getItems, payload);
    if (response.status === "failure") {
      return yield put(ACTIONS.getItems2Fail(response.message));
    }
    yield put(ACTIONS.getItems2Success(response));
  } catch (error) {
    yield put(ACTIONS.getItems2Fail(error));
  }
}

function* onGetItemByToken({ payload }) {
  try {
    const response = yield call(API.getItemByToken, payload);

    if (response.status === "failure") {
      return yield put(ACTIONS.getItemFail(response.message));
    }

    yield put(ACTIONS.getItemSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getItemFail(error));
  }
}

function* fetchItem({ payload }) {
  console.log(payload, "payload");
  try {
    // return yield put(ACTIONS.getItemFail("response.message"));

    const response = yield call(API.getItem, payload);
    // console.log(response,"response");
    if (response.status === "failure") {
      return yield put(ACTIONS.getItemFail(response.message));
    }

    yield put(ACTIONS.getItemSuccess(response));
  } catch (error) {
    console.log("error12==>", error);
    yield put(ACTIONS.getItemFail(error));
  }
}
function* getPPTPrice({ callback }) {
  try {
    const response = yield call(API.getPPTPrice);
    if (response.status === "failure") {
      return yield put(ACTIONS.getPptPriceFail(response.message));
    }

    let myNumb = 1 / response?.data.pptPrice;
    myNumb = Number(myNumb)
      .toFixed(14)
      .replace(/\.?0+$/, ""); // 1e+21
    callback(myNumb, response?.data?.fee / 10000 + 0.01);
    yield put(ACTIONS.getPptPriceSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getPptPriceFail(error));
  }
}
function* swapAPI({ payload: { data, callback } }) {
  try {
    const response = yield call(API.uniswapAPI, data);
    if (response.status === "failure") {
      callback(response);
      return yield put(ACTIONS.swapAPIFail(response.message));
    }
    callback(response);
    yield put(ACTIONS.swapAPISuccess(response));
  } catch (error) {
    yield put(ACTIONS.swapAPIFail(error));
  }
}
function* getPPTPricePancake({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getPPTPricePancake, data);
    if (response.status === "failure") {
      return yield put(ACTIONS.getPptPricePancakeFail(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.getPptPricePancakeSuccess(response));
  } catch (error) {
    console.log(error, "error")
    yield put(ACTIONS.getPptPricePancakeFail(error));
  }
}
function* pancakeSwapAPI({ payload: { data, callback } }) {
  try {
    const response = yield call(API.pancakeswapAPI, data);
    if (response.status === "failure") {
      callback(response);
      return yield put(ACTIONS.pancakeSwapAPIFail(response.message));
    }
    callback(response);
    yield put(ACTIONS.pancakeSwapAPISuccess(response));
  } catch (error) {
    yield put(ACTIONS.pancakeSwapAPIFail(error));
  }
}

function* onPostItemBuy({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postItemBuy, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(ACTIONS.postItemBuyFail(response.message));
    }
    callback && callback(response);
    toast.success(response.message);

    yield put(ACTIONS.postItemBuySuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.postItemBuyFail("Internal Error!"));
  }
}

function* addTransaction({ payload: { data, callback, updateBalanceOf } }) {
  try {
    const response = yield call(API.addHistory, data);
    if (response.status === "failure") {
      callback && callback(response);
      return yield put(ACTIONS.addHistoryFail(response.message));
    }
    callback && callback(response);

    yield put(ACTIONS.addHistorySuccess(updateBalanceOf));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.addHistoryFail("Internal Server Error"));
  }
}

function* getTransaction({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getHistory, data);
    if (response.status === "failure") {
      callback && callback(response);
      return yield put(ACTIONS.getHistoryFail(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.getHistorySuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.getHistoryFail("Internal Server Error"));
  }
}

function* fetchInventoryItemsReq({ payload }) {
  try {
    const response = yield call(API.getItems, payload);
    if (response.status === "failure") {
      return yield put(ACTIONS.getInventoryItemsFail(response.message));
    }
    yield put(ACTIONS.getInventoryItemsSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getInventoryItemsFail(error));
  }
}

function* ItemsSaga() {
  yield takeEvery(TYPES.GET_ITEMS, fetchItems);
  yield takeEvery(TYPES.COUNT_PIRATE_BUY, getPirateCountBuy);
  yield takeEvery(TYPES.GET_ITEMS2, fetchItems2);
  yield takeEvery(TYPES.GET_ITEM_BY_TOKEN, onGetItemByToken);
  yield takeEvery(TYPES.GET_ITEM, fetchItem);
  yield takeEvery(TYPES.GET_PPT_PRICE, getPPTPrice);
  yield takeEvery(TYPES.SWAP_API, swapAPI);
  yield takeEvery(TYPES.GET_PPT_PRICE_PANCAKE, getPPTPricePancake);
  yield takeEvery(TYPES.PANCAKE_SWAP_API, pancakeSwapAPI);
  yield takeEvery(TYPES.POST_ITEM_BUY, onPostItemBuy);
  yield takeEvery(TYPES.ADD_TRANSACTION_HISTORY, addTransaction);
  yield takeEvery(TYPES.GET_TRANSACTION_HISTORY, getTransaction);
  yield takeLatest(TYPES.GET_INVENTORY_ITEMS_REQUEST, fetchInventoryItemsReq)
}

export default ItemsSaga;
