import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../store/actions";
import { toast } from "react-hot-toast";
import Withdrawhistory from "./history";
import Buycards from "./buycards";
import Buyhistory from "./buyhistory";
import Withdrawmod from "Component/Modals/Withdrawmod";

const Withdrawform = (props) => {
  const [withdrawmodal, setwithdrawmodal] = useState(false);

  const navigate = useNavigate();
  const [state, setState] = useState({ tab: "withdraw" });
  const {
    Register: { loading },
    User,
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [userBalance, setUserBalance] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);

  const [enteredAmt, setEnteredAmt] = useState("");
  const [recievedAmt, setRecievedAmt] = useState("");
  const { settings } = useSelector((state) => state.Login);
  const { userDetails } = useSelector((state) => state.User);

  const withdrawtoggle = (e) => {
    e.preventDefault();
    setwithdrawmodal(!withdrawmodal);
  };

  useEffect(() => {
    if (settings?.minigameSetting) {
      setConversionRate(settings?.minigameSetting?.crystalConversionRate);
    }
  }, [settings]);

  useEffect(() => {
    if (userDetails?._id) {
      const callback = (res) => {
        if (res.status == "success") {
          setUserBalance(res?.data?.amount);
        } else {
          toast.error(res.message);
        }
      };
      dispatch(getUserBalance({ user_id: userDetails?._id }, callback));
    }
  }, [userDetails]);

  const handleChange = (event) => {
    let value = event.target.value;
    setEnteredAmt(value);

    if (value) {
      let newValue = conversionRate * value;
      setRecievedAmt(newValue);
    }
  };

  return (
    <section className="Withdrawform_page">
      <Withdrawmod
        show={withdrawmodal}
        onhide={() => setwithdrawmodal(false)}
        enteredAmt={enteredAmt}
        recievedAmt={recievedAmt}
        crystalCommission={settings?.minigameSetting?.crystalCommission}
        walletAddress={userDetails?.walletAddress}
      />
      <div className="withdraw_tabbing">
        <div className="tab-frame withdraw_frame">
          <a
            href="javascript:void(0)"
            onClick={() => navigate(-1)}
            className="wallet_back"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g filter="url(#filter0_di_1_7208)">
                <path
                  d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                  fill="url(#paint0_linear_1_7208)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_di_1_7208"
                  x="0"
                  y="0"
                  width="28"
                  height="28"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1_7208"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1_7208"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="3" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_1_7208"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_1_7208"
                  x1="14"
                  y1="1"
                  x2="14"
                  y2="23"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#46C7A8" />
                  <stop offset="1" stop-color="#46C7A8" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </a>
          {/* state.tab == "withdraw" ? "border_wrap" : "boder_default" */}
          <div className={state.tab == "withdraw"}>
            <div className="clearfix">
              <input
                type="radio"
                name="tab"
                id="withdraw"
                checked={state.tab == "withdraw"}
                onClick={() => setState({ tab: "withdraw" })}
              />
              <label for="withdraw">Withdraw</label>

              <input
                type="radio"
                name="tab"
                id="buy"
                checked={state.tab == "buy"}
                onClick={() => setState({ tab: "buy" })}
              />
              <label for="buy">Buy</label>
            </div>

            {state.tab == "withdraw" && (
              <>
                <div className="Withdraw_tab">
                  <div className="balance_box lg-my-5 my-4">
                    <h6>Balance</h6>

                    <h5>
                      <div className="diamond_img">
                        <img
                          src="/images/diamond.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {userBalance}
                    </h5>
                  </div>

                  <div className="withdra_form">
                    <Form>
                      <FormGroup className="">
                        <Label for="exampleEmail">Enter amount</Label>

                        <div className="withdrawinput_wrap position-relative">
                          <Input
                            type="number"
                            name="email"
                            id="exampleEmail"
                            min="0"
                            value={enteredAmt}
                            onKeyDown={(e) =>
                              ["-", "+", "e"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={handleChange}
                          />
                          <div className="amont_icon">
                            <img
                              src="/images/diamond.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>

                        <div className="min_max d-flex align-items-center gap-4 mt-2">
                          <p>
                            Min:<span>10,000</span>
                          </p>
                          <p>
                            Max:<span>100,000</span>
                          </p>
                        </div>
                      </FormGroup>

                      <FormGroup className="mt-4">
                        <Label for="exampleEmail">You Get</Label>

                        <div className="withdrawinput_wrap position-relative">
                          <Input
                            type="number"
                            name="email"
                            id="exampleEmail"
                            readOnly
                            value={recievedAmt}
                          // placeholder="100"
                          />
                          <div className="amont_icon">
                            <img
                              src="/images/busd_icon.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>

                        <div className="min_max d-flex align-items-center gap-4 mt-2">
                          <p>
                            Min:<span>100</span>
                          </p>
                          <p>
                            Max:<span>1,000</span>
                          </p>
                        </div>
                      </FormGroup>

                      <div className="with_btn text-center lg-mt-5 mt-4">
                        <Button
                          disabled={enteredAmt >= 10000 && enteredAmt <= 100000 ? false : true}
                          className="btn_widraw" onClick={withdrawtoggle}>
                          Withdraw
                        </Button>
                        <p>
                          <div className="sm-dimanod">
                            <img
                              src="/images/diamond.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          1={conversionRate}$
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
          </div>

          {state.tab == "withdraw" && (
            <div className="Profile_amount_table mt-5">
              <Withdrawhistory />
            </div>
          )}

          {state.tab == "buy" && (
            <div className="buy_tab">
              <Buycards />
              <Buyhistory />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Withdrawform;
