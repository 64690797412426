import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { createWithdraw } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export default function Withdrawmod(props) {
  const dispatch = useDispatch();

  const {
    enteredAmt,
    recievedAmt,
    
    
    crystalCommission,
    walletAddress,
  } = props;
  const {
    Register: { loading },
    User,
  } = useSelector((store) => store);
  const { user } = useSelector((state) => state?.Login);
  const [finalValue, setFinalValue] = useState(0);

  const calculatedAmt = (crystalCommission * recievedAmt) / 100;

  const finalAmt = recievedAmt - calculatedAmt?.toFixed(2);

  const handleConfirm = (e) => {
    e.preventDefault();
    if (user?._id) {
      const callback = (res) => {
        if (res.status == "success") {
          toast.success("Withdraw created successfully");
          props.onhide();
          window.location.reload();
        } else {
          toast.error(res?.response?.data?.message);
          props.onhide();
        }
      };
      dispatch(
        createWithdraw(
          { user_id: user?._id, crystalAmount: Number(enteredAmt) },
          callback
        )
      );
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal profile_edit_mod change_pass"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={(event) => {
              event.preventDefault();
              props.onhide();
            }}
            className="close-btn"
            style={{ top: 11, right: 22 }}
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>

          <div className="modal_buy-cont login_inner mobile_pad">
            <h4 className="text-center">Confirm Withdraw</h4>
            <hr class="custom_hr"></hr>

            <div className="withdraw_info my-5">
              <ul>
                <li>
                  <p>Amount: </p>
                  <div className="span_wrap d-flex align-items-center gap-3">
                    <span className="color_diff d-flex align-items-center gap-1">
                      <div className="img_span">
                        <img
                          src="/images/diamond.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {enteredAmt}
                    </span>
                    <span className="color_diff">=</span>
                    <span className="color_diff d-flex align-items-center gap-1">
                      <div className="img_span">
                        <img
                          src="/images/busd_icon.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      {recievedAmt} BUSD
                    </span>
                  </div>
                </li>

                <li>
                  <p>Fee:</p>
                  <span className="text-white">{crystalCommission}%</span>
                </li>

                <li>
                  <p>You Get:</p>
                  <span className="color_diff d-flex align-items-center gap-1">
                    <div className="img_span">
                      <img
                        src="/images/busd_icon.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    {finalAmt} BUSD
                  </span>
                </li>

                <li>
                  <p>Address: </p>
                  <span className="text-white address_span">
                    {walletAddress}
                  </span>
                </li>
              </ul>
            </div>

            {/* <Form className="form_login form_registration mt-4">
              <FormGroup className="mb-4 d-flex align-items-center gap-10 justify-content-between">
                <h5 className="text-white">Amount: </h5>
                <Input
                  type="number"
                  name="amount"
                  id="idamount"
                  placeholder="0.00"
                  className="w-75"
                />
              </FormGroup>
            </Form> */}

            <div className="modal_buy_btn">
              <Button onClick={handleConfirm} className="mod_confirmbtn ">
                Confirm
              </Button>

              <Button
                className="mod_cancelbtn"
                onClick={(e) => {
                  e.preventDefault();
                  props.onhide();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}