import * as TYPES from "./actionTypes";

export const apiFail = (error) => ({
  type: TYPES.API_FAIL,
  payload: error,
});

export const uploadFile = (data, callback) => ({
  type: TYPES.UPLOAD_FILE,
  payload: { data, callback },
});

export const postIpfsFile = (data, callback) => ({
  type: TYPES.POST_IPFS_FILE,
  payload: { data, callback },
});
