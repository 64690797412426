export const CHECK_EMAIL = "CHECK_EMAIL"
export const CHECK_EMAIL_FAILED = "CHECK_EMAIL_FAILED"
export const CHECK_EMAIL_SUCCESSFUL = "CHECK_EMAIL_SUCCESSFUL"

export const REGISTER_USER_EMAIL_VERIFY = "REGISTER_USER_EMAIL_VERIFY"
export const REGISTER_USER_EMAIL_VERIFY_SUCCESSFUL = "REGISTER_USER_EMAIL_VERIFY_SUCCESSFUL"
export const REGISTER_USER_EMAIL_VERIFY_FAILED = "REGISTER_USER_EMAIL_VERIFY_FAILED"

export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"

// forget password
export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL"

// new password
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD"
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS"
export const SET_NEW_PASSWORD_FAIL = "SET_NEW_PASSWORD_FAIL"
