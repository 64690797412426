import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "../../../helpers/backend_helper";

function* checkEmail({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCheckEmail, data);

    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.checkEmailFailed(response.message));
    }

    yield put(ACTIONS.checkEmailSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.checkEmailFailed("Internal Server Error"));
  }
}

function* registerUserEmailVerify({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postRegisterEmailVerify, data);

    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.registerUserEmailVerifyFailed(response.message));
    }

    yield put(ACTIONS.registerUserEmailVerifySuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.registerUserEmailVerifyFailed("Internal Server Error"));
  }
}

function* registerUser({ payload: { data, callback } }) {
  try {
    // if (!user.policy) {
    //   window.scrollTo(0, 0);
    //   return yield put(
    //     ACTIONS.registerUserFailed("Please agree terms and condition!")
    //   );
    // }
    const response = yield call(API.postRegister, data);
    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.registerUserFailed(response.message));
    }

    yield put(ACTIONS.registerUserSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.registerUserFailed("Internal Server Error"));
  }
}


// forget password
function* passwordForget({ payload: { data, callback } }) {
  try {
    const response = yield call(API.forgetPassword, data);

    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.forgetPasswordFail(response.message));
    }

    yield put(ACTIONS.forgetPasswordSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.forgetPasswordFail("Internal Server Error"));
  }
}
// new password
function* setPassword({ payload: { data, callback } }) {
  try {
    const response = yield call(API.setnewPassword, data);

    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.setnewPasswordFail(response.message));
    }

    yield put(ACTIONS.setnewPasswordSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.setnewPasswordFail("Internal Server Error"));
  }
}

export function* watchUserRegister() {
  yield takeEvery(TYPES.CHECK_EMAIL, checkEmail);
  yield takeEvery(TYPES.REGISTER_USER_EMAIL_VERIFY, registerUserEmailVerify);
  yield takeEvery(TYPES.REGISTER_USER, registerUser);
  yield takeEvery(TYPES.FORGET_PASSWORD, passwordForget);
  yield takeEvery(TYPES.SET_NEW_PASSWORD, setPassword);
}

function* RegisterSaga() {
  yield all([fork(watchUserRegister)]);
}

export default RegisterSaga;
