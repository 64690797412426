import React from "react";
import { Container } from "react-bootstrap";
import Inventorydash from "./Inventorydash";

export default function Inventory() {
  return (
    <div className="mainpage-wrap position-relative">
      <div className="overlay_col"></div>
      <section className="inventory_main common_padding_main position-relative">
        <>
          <div className="Market_full">
            <Inventorydash />
          </div>
        </>
      </section>
    </div>
  );
}
