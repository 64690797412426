import * as TYPES from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  error: "",
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REGISTER_USER:
    case TYPES.REGISTER_USER_EMAIL_VERIFY:
      return {
        ...state,
        user: null,
        loading: true,
        registrationError: null,
      };

    case TYPES.REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      };

    case TYPES.REGISTER_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };

    // forget password
    case TYPES.FORGET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.FORGET_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    // new password
    case TYPES.SET_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.SET_NEW_PASSWORD_FAIL:
    case TYPES.REGISTER_USER_EMAIL_VERIFY_SUCCESSFUL:
    case TYPES.REGISTER_USER_EMAIL_VERIFY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default account;
