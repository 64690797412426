// LOGIN
export const POST_LOGIN = "/user/login";
export const LOGIN_WITH_WALLET = "/user/login-with-wallet"
export const GET_SETTINGS = "/setting/view/";
export const UPDATE_USER = "/user/updateProfile";

//Register
export const CHECK_EMAIL = "/user/checkEmail";
export const POST_REGISTER_EMAIL_VERIFY = "/user/sendEmailVerificationOtp";
export const POST_REGISTER = "/user/register";

// Forget password
export const FORGET_PASSWORD = "/user/sendForgotPasswordOtp";
export const SET_NEW_PASSWORD = "/user/newPassword";

// Users
export const GET_USERS = "/user/admin/list";
export const GET_USER = "/user/profile";
export const REMOVE_USER_PROFILE = "/user/profile/remove";
export const CHANGE_STATUS = "/user/change/status";
export const GET_USER_BALANCE = "/get-balance";
export const CREATE_WITHDRAW = "/withdraw-create";
export const BUY_PACKAGE = "/package-buy";

export const POST_WITHDRAWS_HISTORY = "/withdraw-list";
export const GET_CRYSTAL_PACKAGE = "/package-list";
export const POST_PACKAGE_HISTORY = "/package-purchase-history";

// File
export const POST_FILE = "/file/add";
export const POST_IPFS_FILE = "/file/ipfs";

// FAQS
export const GET_FAQS = "/faq/admin/list";
export const ADD_FAQ = "/faq/admin/add";
export const GET_FAQ = "/faq/admin/view";
export const PUT_FAQ = "/faq/admin/update";
export const DELETE_FAQ = "/faq/admin/remove";

// CONTENT_PAGES
export const GET_CONTENT_PAGES = "/pages/admin/list";
export const ADD_CONTENT_PAGE = "/pages/admin/add";
export const GET_CONTENT_PAGE = "/pages/view?type=";
export const PUT_CONTENT_PAGE = "/pages/admin/update";
export const DELETE_CONTENT_PAGE = "/pages/admin/remove";

// Lecture
export const ADD_LECTURE = "/lecture/add";
export const UPDATE_LECTURE = "/lecture/update";
export const VIEW_LECTURE = "/lecture/view";
export const LIST_LECTURE = "/lecture/list";
export const REMOVE_LECTURE = "/lecture/remove";
export const ASSIGN_LECTURE = "/lecture/assign/instructor";

// Course
export const ADD_COURSE = "/course/add";
export const UPDATE_COURSE = "/course/update";
export const VIEW_COURSE = "/course/view";
export const LIST_COURSE = "/course/list";
export const REMOVE_COURSE = "/course/remove";
export const ASSIGN_TO_STUDENT = "/course/assignby/admin";

//add transaction history
export const ADD_HISTORY = "/transaction/add/history";

//get transaction history
export const GET_HISTORY = "/transaction/list/history";

// Permission
export const LIST_AREA = "/admin/list/area";
export const LIST_PERMISSIONS = "/permission/list";
export const UPDATE_PERMISSION = "/permission/update";

// Items
export const GET_ITEMS = "/item/list";
export const COUNT_PIRATE_BUY = "/item/user/countPirateBuy";
export const GET_ITEM_BY_TOKEN = "/item/tokenhash/";
export const GET_ITEM = "/item/tokenhash/";

// Categories
export const GET_CATEGORIES = "/categoryes";

// GET PPT price
export const GET_PPT_PRICE = "/uniswap/get/ppt";
export const SWAP_API = "/uniswap/swap";

//Pancake
export const GET_PPT_PRICE_PANCAKE = "/pancake/calculateToken";
export const PANCAKE_SWAP_API = "/pancake/swap";

// Buy item
export const POST_ITEM_BUY = "/item/user/buy";

export const SEND_OTP = "/user/mpin/sendotp";
export const CHANGE_MPIN = "/user/mpin/change";
export const MPIN_VERIFY = "/user/mpin/verify";

//Collection
export const GET_COLLECTION = "/collection/view/";
