import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import wallet from "../../../hooks/metamask";

import { formatEther } from "ethers/lib/utils";
import { hashPassword, compareHashPassword } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function Tranferconfirm(props) {
  const userWallet = wallet();
  const { userDetails } = useSelector((state) => state.User);
  const [fields, setfields] = useState({
    loading: false,
    password: "",
    pvtKey: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // verify password
    let result = await compareHashPassword(
      fields.password,
      userDetails.password
    );
    if (!result) return toast.error("Invalid password!");
    setfields((prev) => ({ ...prev, pvtKey: userWallet?.pvtKey }));
  };

  return (
    <Modal
      {...props}
      onHide={() => {
        props.onhide();
        setfields((prev) => ({
          ...prev,
          password: "",
          pvtKey: "",
        }));
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal Tranferconfirm-modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <a
            href="javascript:void(0)"
            onClick={() => {
              props.onhide();
              setfields((prev) => ({
                ...prev,
                password: "",
                pvtKey: "",
              }));
            }}
            className="close-btn"
          >
            <img src="/images/close_img.png" alt="" className="img-fluid" />
          </a>

          <form className="send_to_form" onSubmit={handleSubmit}>
            <div className="modal_buy-cont">
              <h4>
                {!fields.pvtKey ? "Enter your password" : "Your private key"}
              </h4>
              <hr class="custom_hr"></hr>
              {fields.pvtKey ? (
                <p style={{ wordBreak: "break-all" }}>{fields.pvtKey}</p>
              ) : (
                <>
                  <input
                    type="password"
                    value={fields.password}
                    required
                    onChange={(e) =>
                      setfields((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                    placeholder="Enter your password"
                  />
                </>
              )}
              <div className="modal_buy_btn">
                <Button
                  className="mod_cancelbtn"
                  onClick={() => {
                    props.onhide();
                    setfields((prev) => ({
                      ...prev,
                      password: "",
                      pvtKey: "",
                    }));
                  }}
                >
                  Close
                </Button>
                {!fields.pvtKey && (
                  <Button className="mod_confirmbtn" type="submit">
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
