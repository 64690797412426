import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter } from "react-router-dom";

import MyRouts from "./Routers/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "css/style.css";

import { Provider, useDispatch } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast";

// import { WalletProvider } from "../src/utils/wallet";

import * as buffer from "buffer";
import { MetaMaskProvider } from "utils/metamask";
window.Buffer = buffer.Buffer;

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}
if (process.env.NODE_ENV === "development") {
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}

function App() {
  const wrapperRef = useRef(null);
  useEffect(() => {
    window.onclick = (event) => {
      if (
        event.target.contains(wrapperRef.current) &&
        event.target !== wrapperRef.current
      ) {
      } else {
        const time = 30 * 60 * 1000;
        setTimeout(() => {
          localStorage.removeItem("authUser");
          window.location.reload();
        }, time);
      }
    };
  }, []);

  const ScrollTop = () => {
    
     window.scrollTo({top : 0, behaviour: "smooth"})
  }

  function handleScroll() {
    const topbtn = document.getElementById("top-btn");
    // console.log("scroll", window.scrollY);
    if (window.scrollY > 0) {
      topbtn.style.opacity = 1; 
      topbtn.style.bottom = "100px" 
    } else {
      topbtn.style.opacity = 0; 
      topbtn.style.bottom = "-100px"
      topbtn.style.transform = "translate-x(-100px)"
    }
  }
  window.addEventListener("scroll", handleScroll);


  return (
    <div className="App" ref={wrapperRef}>
      {/* <div className="gradient-wrap position-relative">
        <div class="body_top_gradient"></div>
      </div> */}

      <Provider store={store}>
        <MetaMaskProvider>
          {/* <WalletProvider> */}
          <BrowserRouter>
            <Toaster position="top-right" reverseOrder={false} />
            <MyRouts />
          </BrowserRouter>
          {/* </WalletProvider> */}
        </MetaMaskProvider>

      </Provider>

      <div onClick={ScrollTop} id="top-btn" style={{"opacity": 0, bottom: "-100px", transform: "translate-x(-100px)" }}>
       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#fff" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z"/></svg>
      </div>
    </div>
  );
}

export default App;
