export const VIEW_PROFILE = "VIEW_PROFILE";
export const VIEW_PROFILE_FAILED = "VIEW_PROFILE_FAILED";
export const VIEW_PROFILE_SUCCESSFUL = "VIEW_PROFILE_SUCCESSFUL";

export const REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE";
export const REMOVE_PROFILE_IMAGE_FAILED = "REMOVE_PROFILE_IMAGE_FAILED";
export const REMOVE_PROFILE_IMAGE_SUCCESSFUL =
  "REMOVE_PROFILE_IMAGE_SUCCESSFUL";

// export const UPDATE_PROFILE = "UPDATE_PROFILE"
// export const UPDATE_PROFILE_SUCCESSFUL = "UPDATE_PROFILE_SUCCESSFUL"
// export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED"

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
export const SEND_OTP_SUCCESSFUL = "SEND_OTP_SUCCESSFUL";

export const CHANGE_MPIN = "CHANGE_MPIN";
export const CHANGE_MPIN_FAILED = "CHANGE_MPIN_FAILED";
export const CHANGE_MPIN_SUCCESSFUL = "CHANGE_MPIN_SUCCESSFUL";

export const VERIFY_MPIN = "VERIFY_MPIN";
export const VERIFY_MPIN_FAILED = "VERIFY_MPIN_FAILED";
export const VERIFY_MPIN_SUCCESSFUL = "VERIFY_MPIN_SUCCESSFUL";


export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const GET_USER_BALANCE_FAILED = "GET_USER_BALANCE_FAILED";
export const GET_USER_BALANCE_SUCCESS = "GET_USER_BALANCE_SUCCESS";

export const POST_CREATE_WITHDRAW = "POST_CREATE_WITHDRAW";
export const POST_CREATE_WITHDRAW_FAILED = "POST_CREATE_WITHDRAW_FAILED";
export const POST_CREATE_WITHDRAW_SUCCESS = "POST_CREATE_WITHDRAW_SUCCESS";


export const GET_WITHDRAW_HISTORY = "GET_WITHDRAW_HISTORY";
export const GET_WITHDRAW_HISTORY_SUCCESS = "GET_WITHDRAW_HISTORY_SUCCESS";
export const GET_WITHDRAW_HISTORY_FAIL = "GET_WITHDRAW_HISTORY_FAIL";

export const LIST_CRYSTAL_PACKAGE = "LIST_CRYSTAL_PACKAGE";
export const LIST_CRYSTAL_PACKAGE_FAIL = "LIST_CRYSTAL_PACKAGE_FAIL";
export const LIST_CRYSTAL_PACKAGE_SUCCESS = "LIST_CRYSTAL_PACKAGE_SUCCESS";

export const GET_PACKAGE_HISTORY = "GET_PACKAGE_HISTORY";
export const GET_PACKAGE_HISTORY_SUCCESS = "GET_PACKAGE_HISTORY_SUCCESS";
export const GET_PACKAGE_HISTORY_FAIL = "GET_PACKAGE_HISTORY_FAIL";


export const POST_BUY_PACKAGE = "POST_BUY_PACKAGE";
export const POST_BUY_PACKAGE_FAILED = "POST_BUY_PACKAGE_FAILED";
export const POST_BUY_PACKAGE_SUCCESS = "POST_BUY_PACKAGE_SUCCESS";
