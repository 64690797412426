import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { updateUser, uploadFile } from "../../store/actions";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.User);

  const [fields, setfields] = useState({
    name: "",
    profileImage: null,
  });
  const [images, setimages] = useState({
    profileImage: null,
  });

  useEffect(() => {
    setfields((prev) => ({
      ...prev,
      name: userDetails?.username,
      profileImage: userDetails?.profileImage?._id,
    }));
    setimages({ profileImage: userDetails?.profileImage?.link });
  }, [userDetails]);
  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    handleChange(name)({ target: { value: _id } });
  };

  const handleImageChange = (name) => (evt) => {
    const image = evt.target.files;
    if (image && image[0]) {
      dispatch(uploadFile({ image: image[0] }, uploadFileSuccess(name)));
      setimages((prevState) => ({
        ...prevState,
        [name]: URL.createObjectURL(image[0]),
      }));
    }
  };
  const handleChange = (name) => (event) => {
    setfields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const submit = (e) => {
    e.preventDefault();
    const callBack = (res) => {
      console.log(res, "hghghg");
      if (res.status == "failure") {
        return toast.error(res.message);
      } else {
        toast.success(res.message);
        navigate("/profile");
      }
    };

    const data = {
      username: fields.name,
      profileImage: fields.profileImage,
    };
    dispatch(updateUser(data, callBack));
  };

  console.log(images, "images");
  return (
    <div className="mainpage-wrap position-relative">
      <div className="overlay_col"></div>

      <section className="login-theme position-relative">
        <Container style={{ marginTop: "7rem" }}>
          <h5 className="log-head">Edit Profile</h5>

          <div className="login_inner">
            <>
              <Fade>
                <Form className="form_login" onSubmit={submit}>
                  <FormGroup>
                    <div className="uploding_img position-relative">
                      <img
                        src={images?.profileImage || "/images/user.png"}
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        onChange={handleImageChange("profileImage")}
                        className="opacity-0 cursor-pointer profile_pic"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Input
                      type="text"
                      name="name"
                      id="exampleName"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={fields.name}
                      required
                    />
                  </FormGroup>
                  {/* <FormGroup className="mb-4">
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      value={fields.email}
                      required
                      readOnly={true}
                    />
                  </FormGroup> */}

                  <div className="login-btn-wrap mt-5">
                    <Button className="btn-form" onSubmit={submit}>
                      Update
                    </Button>
                  </div>
                </Form>
              </Fade>
            </>
          </div>
        </Container>
      </section>
    </div>
  );
};
