import React from "react";

import Header from "../Component/Header/Header";
import SendRecive from "../Pages/SendRecive/SendRecive";
import Footer from "../Component/Footer/Footer";

export default function Sendrecievetheme() {
  return (
    <>
      <Header />
      <SendRecive />
      <Footer/>
    </>
  );
}
