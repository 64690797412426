import React, {useState} from 'react'
import { Button, Container } from 'react-bootstrap'

const Keytabbing = () => {
    const [state, setState] = useState({ tab: "tab1" });
    const [btncopy, setbtncopy] = useState(true);
  return (
    <section className='keytabbing mt-4'>
     <Container> 
        <div className="tab-frame key_tabbing">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="tab1"
                  checked={state.tab == "tab1"}
                  onClick={() => setState({ tab: "tab1" })}
                />
                <label for="tab1">TEXT</label>

                <input
                  type="radio"
                  name="tab"
                  id="tab2"
                  checked={state.tab == "tab2"}
                  onClick={() => setState({ tab: "tab2" })}
                />
                <label for="tab2">QR CODE</label>
              </div>

              {state.tab == "tab1" && (
                <div className="text_tabbing mt-4">
                    <h5>Your private key</h5>

                    <div className="private_key_box">
                          <p>gjgsgojgojgsjggijsodjgosjdgjsojgoggdggjgsgojgojgsjggijsodjgosjdgjsojgoggdg</p>
                          <Button className='copytoclip mt-4' onClick={() => setbtncopy(false)}>
                            {btncopy ? "Copy to clipboard" : "Copied"}
                          </Button>
                    </div>
                </div>
              )}

              {state.tab == "tab2" && (
                <div className="QR_code mt-4">
                    <div className="qr_imge">
                     <img src="/images/qr-code.png" alt="" className='img-fluid'/>
                    </div>
                </div>
              )}
            </div>

            <Button className='done_btn mt-4'>Done</Button>
     </Container>
    </section>
  )
}

export default Keytabbing