import React from "react";


import Header from "../Component/Header/Header";
import Inventorysingle from "../Pages/Inventorysingle/Inventorysingle";
import Footer from "../Component/Footer/Footer";



export default function Inventorysingletheme() {
  return (
    <>
      <Header />
      <Inventorysingle/>
      <Footer/>
    </>
  );
}
