import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import BuyPackagemod from 'Component/Modals/BuyPackagemod';
import { useDispatch, useSelector } from "react-redux";

import { listCrystalPackage } from "../../store/actions";
import { toast } from "react-hot-toast";
import {Spinner} from "reactstrap";


const Buycards = () => {
  const [buycardmod, setbuycardmod] = useState(false);
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.User);
  const [selectedPackage, setSelectedPackage] = useState();
  const { settings } = useSelector((state) => state.Login);
  const [conversionRate, setConversionRate] = useState(0);
  const [packageList, setPackageList] = useState([]);
  const [recievedAmt, setRecievedAmt] = useState("");
  const { loading } = useSelector((state) => state.User);


  useEffect(() => {
    if (settings?.minigameSetting) {
      setConversionRate(settings?.minigameSetting?.crystalConversionRate);
    }
  }, [settings]);

  useEffect(() => {

    if (userDetails?._id) {
      getPackages()
    }
  }, [])


  const getPackages = () => {
    const callback = (res) => {
      if (res.status == "success") {
        setPackageList(res?.data)
      } else {
        toast.error(res.message);
      }
    };
    dispatch(listCrystalPackage({ page: 0, limit: 10000,  orderBy: "date_created_utc",
    order: 1,}, callback));
  }



const handleBuyPackage = (e, item)=>{
  let newValue = conversionRate * item?.crystalAmount;
  setRecievedAmt(newValue);
  setSelectedPackage(item)
  setbuycardmod(true)
}




  return (
    <>
      <BuyPackagemod 
      show={buycardmod} 
      onhide={() => setbuycardmod(false)}
      selectedPackage={selectedPackage}
      crystalCommission={settings?.minigameSetting?.crystalCommission}
      recievedAmt={recievedAmt}
      walletAddress={userDetails?.walletAddress}
      adminWalletAddress={settings?.minigameSetting?.adminWalletAddress}
      
      />
      <section className='buy_card'>
      {loading && <Spinner color="primary" /> }

        <div className="buy_card_list">
          <ul>
            {packageList.length >0 && packageList.map((item, idx) => {
              return (
                <li>
                  <div className="buy_card_wrap">
                    <div className="buy_cardimgwrap">
                      <div className="imgcard-wrap">
                        <img
                          src={item.image}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="crystal_wrap d-flex align-items-center justify-content-center gap-2">
                        <div className="buy_diamond">
                          <img src="/images/diamond.png" alt="" className="img-fluid" />
                        </div>
                        <p className='crystals_items'>{item.crystalAmount}</p>
                      </div>
                    </div>
                    <div className="buy_btns_twos d-flex align-items-center justify-content-center gap-2 mt-3">
                      <Button className="btn_green">
                        <div className="btn_icon me-2">
                          <img src="/images/busd_icon.png" alt="" className='img-fluid' />
                        </div>
                        {item.busdAmount}$
                      </Button>
                      <Button className="btn_blue" onClick={(e)=>handleBuyPackage(e, item)}>Buy</Button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </>

  )
}

export default Buycards