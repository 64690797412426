import React, { useEffect, useRef, memo, useState } from "react";
import { Form } from "reactstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { levelFilter } from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { getItems2 } from "../../store/actions";
import { Spinner } from "react-bootstrap";

// const propsAreEqual = (preProp, nexProps) => {
//   if (JSON.stringify(preProp.filter) == JSON.stringify(nexProps.filter)) {
//     return true;
//   }
// };
function Shipfillter(props) {
  const { list, setfilter, filter } = props;
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const { loading2 } = useSelector((store) => store?.Items);

  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);

  const [value, setValues] = useState({
    minValue: "",
    maxValue: "",
  });

  const checkSelectedFilters = (selectedList, value) => {
    try {
      const result = !!(selectedList || [])?.includes(value);

      // console.log("=================>");
      // console.log("list,", selectedList, "value,", value, "result", result);
      return result;
    } catch (e) {
      return false;
    }
  };

  const onSelectFilters = (key, value, subcat) => {
    try {
      console.log("key", key)
      if(key === "subcategories"|| key === "shipsLevelEnd"|| key === "shipsLevelStart"){
        setfilter((prev) => ({
          ...prev,
          "isMore": false,
          "page": 1
        }));
      }
      if (!value) {
        setfilter((prev) => ({
          ...prev,
          [key]: [],
          category: "",
          shipsLevelStart: levelFilter[subcat?.catName]?.minValue,
          shipsLevelEnd: levelFilter[subcat?.catName]?.maxValue,
          subcat,
        }));
        return;
      }
     
      if (!filter[key]?.includes(value)) {
        setfilter((prev) => ({
          ...prev,
          [key]: [value],
          category: subcat.catName,
          shipsLevelStart: levelFilter[subcat?.catName]?.minValue,
          shipsLevelEnd: levelFilter[subcat?.catName]?.maxValue,
          subcat,
        }));
      } else {
        setfilter((prev) => ({
          ...prev,
          [key]: prev[key]?.filter((item) => item !== value),
          category: subcat.catName,
          shipsLevelStart: levelFilter[subcat?.catName]?.minValue,
          shipsLevelEnd: levelFilter[subcat?.catName]?.maxValue,
          subcat,
        }));
      }
    } catch (e) {
      return;
    }
  };

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    // setValues({
    //   minValue: e.minValue,
    //   maxValue: e.maxValue,
    // });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      setfilter((prev) => ({
        ...prev,
        shipsLevelStart: minValue,
        shipsLevelEnd: maxValue,
        level: undefined,
        isMore: false,
        page: 1
      }));
    }, 1000);
    return () => clearTimeout(getData);
  }, [minValue, maxValue]);

  // console.log("filters", filter);
  return (
    <section className="filter_here ship_filter">
      <Form>
        <div className="top_class_filter">
          <a
            href="javascript:void(0)"
            className="close_fillter"
            onClick={props.onClose}
          >
            <img src="/images/closere.png" alt="" className="img-fluid" />
          </a>
          <h5 className="filter-head">Filter</h5>
          <p className="subhead_class">Class</p>

          <div className="input_Wrap">
            <ul class="radio-a">
              {list.length > 0 &&
                list
                  .filter((e) => e.catName == "Ships")
                  .map((cat, idx) =>
                    cat.subcategories.map((subcat, index) => {
                      return (
                        <li key={index}>
                          <input
                            name="radio-a"
                            id={`radio-a0${subcat._id}`}
                            type="radio"
                            checked={
                              filter?.subcategories?.length
                                ? checkSelectedFilters(
                                    filter?.subcategories,
                                    subcat._id
                                  )
                                : subcat.catName == "All"
                            }
                            onChange={() =>
                              onSelectFilters(
                                "subcategories",
                                subcat.catName == "All" ? null : subcat._id,
                                subcat
                              )
                            }
                          />
                          <label
                            class="radio-a-label"
                            for={`radio-a0${subcat._id}`}
                          >
                            {subcat.catName}
                          </label>
                        </li>
                      );
                    })
                  )}
            </ul>
          </div>
        </div>

        <div className="bottom_filter_pirates mt-4">
          <h5 className="filter-head">Level</h5>

          <div className="range_slider">
            <MultiRangeSlider
              ref={sliderRef}
              min={levelFilter[filter?.subcat?.catName]?.minValue || 1}
              max={levelFilter[filter?.subcat?.catName]?.maxValue || 49}
              step={1}
              minValue={levelFilter[filter?.subcat?.catName]?.minValue || 1}
              maxValue={levelFilter[filter?.subcat?.catName]?.maxValue || 100}
              onInput={(e) => {
                // setfilter((prev) => ({
                //   ...prev,
                //   shipsLevelStart: minValue,
                //   shipsLevelEnd: maxValue,
                // }));
                handleInput(e);
              }}
            />

            <div className="value-max">
              <h5>
                {minValue}
              </h5>
              <h5>
                {maxValue}
              </h5>
            </div>
          </div>
        </div>
      </Form>
    </section>
  );
}
export default Shipfillter;
