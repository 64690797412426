import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Successmodal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_modal succes_modal"
    >
      <Modal.Body>
        <div className="modal_inner_main position-relative">
          <div className="success-mod_content">
            <p>Successfully purchased</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
