import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Progress } from "reactstrap";
import { Fade } from "react-reveal";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BuyConfirmation from "../../Component/Modals/Tranferconfirm";
import toastr from "toastr";
import { Spinner } from "reactstrap";

import Buymod from "../../Component/Modals/Buymod";
import Successmodal from "../../Component/Modals/Successmodal";

// Hooks
import wallet from "../../hooks/metamask";
import Web3Intraction from "utils/web3Intraction";

//actions
import {
  getItem,
  getItemByToken,
  getSettings,
  postItemBuy,
  getCollection,
  updateUser,
  addHistorySuccess,
  resetItem,
} from "../../store/actions";
import {
  barObj,
  levelObj,
  showNotification,
  stringShortner,
} from "../../helpers/constants";
import toast from "react-hot-toast";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p
      className={
        isReadMore && text?.length < 100 ? "text_noscrool" : "text_scrool"
      }
    >
      {isReadMore ? stringShortner(text, 50) : text}

      {text?.length > 100 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "read more" : " show less"}
        </span>
      )}
    </p>
  );
};

export default function Productsingle4(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  let paramsData = useParams();
  const [searchParams] = useSearchParams();
  const isLocFromInventory = searchParams.get("fromLocation") === "inventory";
  const hooks = wallet();
  const [sendconfirmed, setsendconfirmed] = useState(false);
  const userWallet = wallet();
  const { item, loading } = useSelector((state) => state.Items);
  const { settings, user } = useSelector((state) => state.Login);
  const { userDetails } = useSelector((state) => state.User);

  const [Buyshow, setBuyshow] = useState(false);
  const [Successshow, setSuccessshow] = useState(false);
  const [internalLoading, setinternalLoading] = useState(false);
  const [BuyButton, setBuyButton] = useState(false);

  useEffect(() => {
    if (paramsData?.token_uri_id) {
      dispatch(getItemByToken(paramsData?.token_uri_id));
    }
  }, [paramsData?.token_uri_id]);

  useEffect(() => {
    dispatch(getItem(params._id));
    return () => dispatch(resetItem());
  }, [params._id]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const togmodl = () => {
    // if (item?.blockchain != userWallet.blockchain) {
    //   setinternalLoading(false);
    //   toast.error("Please change blockchain!");
    //   return;
    // }
    setBuyshow(!Buyshow);
    // setTimeout(() => {
    //   setSuccessshow(!Successshow);
    // }, 400);
    setTimeout(() => {
      setSuccessshow(false);
      // navigate("/marketplace");
    }, 1000);
  };

  const onBuy = async (evt, buyType) => {
    evt.preventDefault();
    togmodl();
    if (settings && item && JSON.stringify(item) !== "{}") {

      const web3Intraction = new Web3Intraction(
        "BNB",
        settings
      );
      await userWallet.switchNetwork(web3Intraction.networkUrl.chainId);

      setinternalLoading(true);


      try {
        const receipt = await web3Intraction.sendTransaction(
          item,
          buyType,
          userWallet?.account
        );
        const data = {
          type: item.blockchain || "polygon",
          itemId: item._id,
          transactionHash: receipt.transactionHash,
          token_id: item.voucher.tokenId,
        };

        let updateBalanceOf = {
          name: buyType == 1 ? "PPT_balance" : "balance",
          value: buyType == 1 ? receipt.buyAmount : item.price,
        };

        showNotification(receipt.transactionHash, userWallet?.blockchain, settings?.blockchainNetworkMode);
        setSuccessshow(true);
        setTimeout(() => {
          setSuccessshow(false);
        }, 3000);
        dispatch(addHistorySuccess(updateBalanceOf));
        dispatch(
          postItemBuy(data, () => {
            dispatch(getItemByToken(params?._id));
            setinternalLoading(false);
          })
        );
        dispatch(getItem(params._id));
      } catch (error) {
        console.log("details error -------->", error)
        toast.error(error);
        setinternalLoading(false);
      }

    }
  };
  const connectWallet = async () => {
    hooks.connect("metamask", 97);
  };
  return (
    <>
      <Buymod
        show={Buyshow}
        title={item?.name}
        item={item}
        onhide={() => setBuyshow(false)}
        togmodl={togmodl}
        internalLoading={internalLoading}
        submit={onBuy}
      />
      <Successmodal show={Successshow} onHide={() => setSuccessshow(false)} />
      <div className="mainpage-wrap position-relative">
        <div className="overlay_col"></div>
        <Fade>
          <section className="single_product_main single_product_parates common_padding_main position-relative">
            <Container fluid>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <div className="single_big_img position-relative">
                    <div className="line_mobile">
                      <img
                        src="/images/line_mobile.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate(-1)}
                      className="back_arrow_single"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <g filter="url(#filter0_di_1_7208)">
                          <path
                            d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                            fill="url(#paint0_linear_1_7208)"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_di_1_7208"
                            x="0"
                            y="0"
                            width="28"
                            height="28"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1_7208"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1_7208"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="3" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect2_innerShadow_1_7208"
                            />
                          </filter>
                          <linearGradient
                            id="paint0_linear_1_7208"
                            x1="14"
                            y1="1"
                            x2="14"
                            y2="23"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#46C7A8" />
                            <stop
                              offset="1"
                              stop-color="#46C7A8"
                              stop-opacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </a>

                    <div className="single-big_img">
                      {item?.media?.link ? (
                        <img
                          key={`${item?._id}`}
                          src={item?.media?.link?.replace("piratepets.s3.eu-north-1.amazonaws.com", "d1ihmhsgmb4un6.cloudfront.net")}
                          alt="image"
                          className="img-fluid"
                        />
                      ) : (
                        <h3 className="text-white">Loading...</h3>
                      )}
                    </div>

                    <div className="lvel_bar-wrap">
                      <div className="single-level_37img position-relative">
                        {item.category_id?.catName == "Ships" ? (
                          <p className="levelsingle_page">
                            <img
                              src={`../images/ships/${item.attributes.level}.png`}
                              // width="40px"
                              height={"20px"}
                              style={{ height: 50 }}
                            />
                          </p>
                        ) : (
                          <>
                            {item?.attributes?.level ? (
                              <p className="levelsingle_page">
                                <img
                                  src={`../images/ranking/${item?.attributes?.level}.png`}
                                  alt="image"
                                // height={"20px"}
                                // style={{ height: 50 }}
                                // className="img-fluid"
                                />
                              </p>
                            ) : (
                              <p className="levelsingle_page">
                                <img
                                  src={`../images/ranking/1.png`}
                                  alt="image"
                                // height={"20px"}
                                // style={{ height: 50 }}
                                // className="img-fluid"
                                />
                              </p>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className={`vertical-barlevel37  ${item.category_id?.catName == "Ships"
                          ? barObj[item?.subcategory_id?.catName]
                          : barObj[item?.attributes?.level]
                          }  `}
                      ></div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="single-big-right">
                    <div className="name_productid">
                      <h3>{item.name || "Loading..."}</h3>
                      {/* <div className="center_line"></div>
                      <h3>#{item?.voucher?.tokenId || "1563590420"}</h3> */}
                    </div>
                    <hr className="custom_hr"></hr>

                    <div className="three-progressbar">
                      {item.category_id?.catName == "Ships" && (
                        <>
                          <div className="durability-wrap">
                            <p className="color_theme">Durability </p>
                            <div className="durability-bar">
                              <Progress value="100" />
                            </div>
                            <p>100%</p>
                          </div>

                          <hr className="custom_hr"></hr>

                          {/* <div className="strom-wrap">
                            <div className="strom_head">
                              <div className="icon-common">
                                <img
                                  src="/images/shield_silver.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <p className="color_theme">Storm </p>
                            </div>
                            <div className="strom-bar">
                              <Progress value="70" />
                            </div>
                            <p>7.0</p>
                          </div>

                          <div className="strom-wrap">
                            <div className="strom_head">
                              <div className="icon-common">
                                <img
                                  src="/images/cloud_Shiled.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <p className="color_theme">Ice Rain </p>
                            </div>
                            <div className="Rain_bar">
                              <Progress value="25" />
                            </div>
                            <p>3.0</p>
                          </div>

                          <div className="strom-wrap">
                            <div className="strom_head">
                              <div className="icon-common">
                                <img
                                  src="/images/cloud_Shiled.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <p className="color_theme">Fire Rain </p>
                            </div>
                            <div className="Fire_rain">
                              <Progress value="80" />
                            </div>
                            <p>8.0</p>
                          </div> */}
                        </>
                      )}
                      {item.receiptStatus !== "success" && !isLocFromInventory ?
                        <div className="price_wrap my-5">
                          <p>Price</p>
                          <p className="color_theme">
                            {item?.price}{" "}
                            {item?.blockchain == "polygon" ? "MATIC" : "BNB"}
                          </p>
                        </div>
                        : null}

                      {!isLocFromInventory ? (
                        <div className="sinlge_page_btn">
                          <Button
                            className="cancel_Single"
                            disabled={internalLoading || loading}
                            onClick={() =>
                              navigate(
                                item?.category_id?.catName == "Ships"
                                  ? "/?type=Ships"
                                  : "/"
                              )
                            }
                          >
                            Cancel
                          </Button>
                          {!hooks.account ? (
                            <Button
                              className="buy_single"
                              onClick={(e) => {
                                e.preventDefault();
                                // if (
                                //   item?.blockchain != userWallet.blockchain
                                // ) {
                                //   setinternalLoading(false);
                                //   toast.error("Please change blockchain!");
                                //   return;
                                // }
                                if (!userDetails || JSON.stringify(userDetails) == "{}") {
                                  setinternalLoading(false);
                                  toast.error(
                                    "Please login first!"
                                  );
                                  return;
                                }
                                if (!userWallet.isActive) {
                                  setinternalLoading(false);
                                  toast.error(
                                    "Please connect metamask first!"
                                  );
                                  return;
                                }
                                setBuyshow(true);
                              }}
                            >
                              Buy
                            </Button>
                          ) : user?._id == item?.current_owner?._id &&
                            item?.author_id?._id !== user?._id ? (
                            <Button
                              className="buy_single"
                              onClick={() => setSuccessshow(true)}
                            >
                              Buy
                            </Button>
                          ) : (
                            <Button
                              onClick={togmodl}
                              className="buy_single"
                              disabled={internalLoading || loading}
                              loading={internalLoading || loading}
                            >
                              Buy
                              {internalLoading && (
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </Button>
                          )
                          }
                        </div>
                      ) : null}
                      <div className="description_herer mt-4">
                        <ReadMore>{item.description}</ReadMore>
                      </div>
                      <div className="description_herer mt-4">
                        <p><b>Collection Address:-  </b><a className="collection-anchor" href={settings?.blockchainNetworkMode == "mainnet"
                          ? `https://bscscan.com/address/${item?.collection_id?.contractAddress}`
                          : `https://testnet.bscscan.com/address/${item?.collection_id?.contractAddress}`} target={"_blank"}>{item?.collection_id?.contractAddress}</a></p>
                        {item.receiptStatus == "success" && <p><b>Token Id:-  </b><a className="collection-anchor" href={
                          settings?.blockchainNetworkMode == "mainnet"
                            ? `https://bscscan.com/token/${item?.collection_id?.contractAddress}?a=${item?.token_id}`
                            : `https://testnet.bscscan.com/token/${item?.collection_id?.contractAddress}?a=${item?.token_id}`
                        } target={"_blank"}>{item?.token_id}</a></p>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Fade>
      </div>
    </>
  );
}
