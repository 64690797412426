import { post, del, get, put } from "./api_helper";
import { gamePost, gameDel, gameGet, gamePut } from "./game_api_helper";
import { mobileDel, mobileGet, mobilePost, mobilePut } from "./mobile_api_helper";

import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const loginWithWallet = (data) => post(url.LOGIN_WITH_WALLET, data);
export const getSettings = () => get(url.GET_SETTINGS);

export const updateUser = (data) => post(url.UPDATE_USER, data);

// Register
export const postCheckEmail = (data) => post(url.CHECK_EMAIL, data);
export const postRegisterEmailVerify = (data) =>
  post(url.POST_REGISTER_EMAIL_VERIFY, data);
export const postRegister = (data) => post(url.POST_REGISTER, data);

// forget password
export const forgetPassword = (data) => post(url.FORGET_PASSWORD, data);
export const setnewPassword = (data) => post(url.SET_NEW_PASSWORD, data);

//Users
export const getUsers = (data) => post(url.GET_USERS, data);
export const getUser = () => get(url.GET_USER);

export const getUserBalance = (data) => gamePost(url.GET_USER_BALANCE, data);
export const postCreateWithdraw = (data) => gamePost(url.CREATE_WITHDRAW, data);
export const postBuyPackage = (data) => gamePost(url.BUY_PACKAGE, data);


export const postWithdrawHistory = (data) => gamePost(url.POST_WITHDRAWS_HISTORY, data);

export const postPackageHistory = (data) => gamePost(url.POST_PACKAGE_HISTORY, data);


export const getCrystalPackage = (data) => gamePost(url.GET_CRYSTAL_PACKAGE, data);

export const removeUserProfileImage = (profileImgId) =>
  post(url.REMOVE_USER_PROFILE, profileImgId);
export const changeStatus = (data) => post(url.CHANGE_STATUS, data);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};

export const postIpfsFile = (data) => {
  const formData = new FormData();
  Object.entries(data).map(([key, value]) => formData.append(key, value));
  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_IPFS_FILE, formData, config);
};

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getFaq = (id) => get(url.GET_FAQ + "/" + id);
export const putFaq = (data) => put(url.PUT_FAQ, data);
export const deleteFaq = (data) => del(url.DELETE_FAQ, data);

// Content Page
export const getContentPages = (data) => post(url.GET_CONTENT_PAGES, data);
export const postContentPage = (data) => post(url.ADD_CONTENT_PAGE, data);
export const getContentPage = (id) => get(url.GET_CONTENT_PAGE + id);
export const putContentPage = (data) => put(url.PUT_CONTENT_PAGE, data);
export const deleteContentPage = (data) => del(url.DELETE_CONTENT_PAGE, data);

// Lecture
export const addLecture = (data) => post(url.ADD_LECTURE, data);
export const updateLecture = (data) =>
  put(url.UPDATE_LECTURE + "/" + data.id, data);
export const viewLecture = (id) => get(url.VIEW_LECTURE + "/" + id);
export const listLecture = (data) => post(url.LIST_LECTURE, data);
export const removeLecture = (data) => del(url.REMOVE_LECTURE, data);
export const assignLecture = (data) => post(url.ASSIGN_LECTURE, data);

// Course
export const addCourse = (data) => post(url.ADD_COURSE, data);
export const updateCourse = (data) =>
  put(url.UPDATE_COURSE + "/" + data.id, data);
export const viewCourse = (id) => get(url.VIEW_COURSE + "/" + id);
export const listCourse = (data) => post(url.LIST_COURSE, data);
export const removeCourse = (data) => del(url.REMOVE_COURSE, data);
export const assignCourseToStudent = (data) =>
  post(url.ASSIGN_TO_STUDENT, data);

// Permission
export const listarea = () => get(url.LIST_AREA);
export const listpermission = () => get(url.LIST_PERMISSIONS);
export const updatePermission = (data) => post(url.UPDATE_PERMISSION, data);

//Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const buyPirateCount = (data) => post(url.COUNT_PIRATE_BUY, data);
export const getItemByToken = (token) => get(url.GET_ITEM_BY_TOKEN + token);
export const getItem = (id) => get(url.GET_ITEM + id);

//Categories
export const getCategories = (data) => post(url.GET_CATEGORIES, data);

//Get PPT price
export const getPPTPrice = () => get(url.GET_PPT_PRICE);
export const getPPTPricePancake = (data) => post(url.GET_PPT_PRICE_PANCAKE, data);

//Uniswap
export const uniswapAPI = (data) => post(url.SWAP_API, data);
export const pancakeswapAPI = (data) => post(url.PANCAKE_SWAP_API, data);

//Buy item
export const postItemBuy = (data) => post(url.POST_ITEM_BUY, data);

//Add history
export const addHistory = (data) => post(url.ADD_HISTORY, data);
export const getHistory = (data) => post(url.GET_HISTORY, data);

//Collection
export const getCollection = (_id) => get(url.GET_COLLECTION + _id);

export const sendOTP = (data) => post(url.SEND_OTP, data);
export const changeOTP = (data) => post(url.CHANGE_MPIN, data);
export const verifyOTP = (data) => post(url.MPIN_VERIFY, data);
