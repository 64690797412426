import { call, put, takeEvery } from "redux-saga/effects";

// Customer Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

import * as API from "../../helpers/backend_helper";

function* onAddFile({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postFile, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.apiFail(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.apiFail(""));
  } catch (error) {
    yield put(ACTIONS.apiFail(error));
  }
}

function* onAddIpfsFile({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postIpfsFile, data);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.apiFail(response.message));
    }
    yield put(ACTIONS.apiFail(""));
  } catch (error) {
    callback && callback({ message: "Something went wrong...." });
    yield put(ACTIONS.apiFail(error));
  }
}

function* fileSaga() {
  yield takeEvery(TYPES.UPLOAD_FILE, onAddFile);
  yield takeEvery(TYPES.POST_IPFS_FILE, onAddIpfsFile);
}

export default fileSaga;
