import {
  VIEW_PROFILE,
  VIEW_PROFILE_SUCCESSFUL,
  VIEW_PROFILE_FAILED,
} from "./actionTypes";

import * as CONST from "./actionTypes";
export const viewProfile = () => {
  return {
    type: VIEW_PROFILE,
  };
};

export const viewProfileSuccessful = (user) => {

  // console.log(user, "<uuser")
  return {
    type: VIEW_PROFILE_SUCCESSFUL,
    payload: user,
  };
};

export const viewProfileFailed = (error) => {
  return {
    type: VIEW_PROFILE_FAILED,
    payload: error,
  };
};

export const removeProfileImage = (data, callback) => {
  return {
    type: CONST.REMOVE_PROFILE_IMAGE,
    payload: { data, callback },
  };
};

export const removeProfileImageSuccessful = (user) => {
  return {
    type: CONST.REMOVE_PROFILE_IMAGE_SUCCESSFUL,
    payload: user,
  };
};

export const removeProfileImageFailed = (error) => {
  return {
    type: CONST.REMOVE_PROFILE_IMAGE_FAILED,
    payload: error,
  };
};

export const sendOTP = (data, callback) => {
  return {
    type: CONST.SEND_OTP,
    payload: { data, callback },
  };
};

export const sendOTPFaild = (error) => {
  return {
    type: CONST.SEND_OTP_FAILED,
    payload: error,
  };
};

export const sendOTPSuccess = (data) => {
  return {
    type: CONST.SEND_OTP_SUCCESSFUL,
    payload: data,
  };
};
export const changeMPIN = (data, callback) => {
  return {
    type: CONST.CHANGE_MPIN,
    payload: { data, callback },
  };
};

export const changeMPINFaild = (error) => {
  return {
    type: CONST.CHANGE_MPIN_FAILED,
    payload: error,
  };
};

export const changeMPINSuccess = (data) => {
  return {
    type: CONST.CHANGE_MPIN_SUCCESSFUL,
    payload: data,
  };
};

export const verifyMpin = (data, callback) => {
  return {
    type: CONST.VERIFY_MPIN,
    payload: { data, callback },
  };
};

export const verifyMpinFaild = (error) => {
  return {
    type: CONST.VERIFY_MPIN_FAILED,
    payload: error,
  };
};

export const verifyMpinSuccess = (data) => {
  return {
    type: CONST.VERIFY_MPIN_SUCCESSFUL,
    payload: data,
  };

};

export const getUserBalance = (data, callback) => {
  return {
    type: CONST.GET_USER_BALANCE,
    payload: { data, callback },

  };
};

export const getUserBalanceSuccess = (data, callback) => {
  return {
    type: CONST.GET_USER_BALANCE_SUCCESS,
    payload: { data, callback },
  };
};

export const getUserBalanceFailed = (error) => {
  return {
    type: CONST.GET_USER_BALANCE_FAILED,
    payload: error,
  };
};

export const createWithdraw = (data, callback) => {
  return {
    type: CONST.POST_CREATE_WITHDRAW,
    payload: { data, callback },

  };
};

export const getCreateWithdrawSuccess = (data, callback) => {
  return {
    type: CONST.POST_CREATE_WITHDRAW_SUCCESS,
    payload: { data, callback },
  };
};

export const getCreateWithdrawFailed = (error) => {
  return {
    type: CONST.POST_CREATE_WITHDRAW_FAILED,
    payload: error,
  };
};


export const getWithdrawHistory = (data, callback) => ({
  type: CONST.GET_WITHDRAW_HISTORY,
  payload: {data, callback},
});

export const getWithdrawHistorySuccess = (data) => ({
  type: CONST.GET_WITHDRAW_HISTORY_SUCCESS,
  payload: data,
});

export const getWithdrawHistoryFail = (error) => ({
  type: CONST.GET_WITHDRAW_HISTORY_FAIL,
  payload: error,
});


export const listCrystalPackage = (data, callback) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE,
  payload:  {data, callback} ,
});

export const listCrystalPackageFail = (error) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_FAIL,
  payload: error,
});

export const listCrystalPackageSuccess = (data) => ({
  type: CONST.LIST_CRYSTAL_PACKAGE_SUCCESS,
  payload: data,
});

export const getPackageHistory = (data, callback) => ({
  type: CONST.GET_PACKAGE_HISTORY,
  payload: {data, callback},
});

export const getPackageHistorySuccess = (data) => ({
  type: CONST.GET_PACKAGE_HISTORY_SUCCESS,
  payload: data,
});

export const getPackageHistoryFail = (error) => ({
  type: CONST.GET_PACKAGE_HISTORY_FAIL,
  payload: error,
});


export const buyPackage = (data, callback) => {
  return {
    type: CONST.POST_BUY_PACKAGE,
    payload: { data, callback },

  };
};

export const buyPackageSuccess = (data, callback) => {
  return {
    type: CONST.POST_BUY_PACKAGE_SUCCESS,
    payload: { data, callback },
  };
};

export const buyPackageFailed = (error) => {
  return {
    type: CONST.POST_BUY_PACKAGE_FAILED,
    payload: error,
  };
};