import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Withdrawform from "./Withdrawform";

// modal

export default function Withdraw() {
  return (
    <div className="profile_main position-relative">
      <div className="overlay_col"></div>
      <section className="profile_section common_padding_main position-relative">
        <Container className="position-relative">
          <Row>
            <div className="withdraw_form">
              <Withdrawform />
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}
