import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Fade } from "react-reveal";
import ConfirmPass from "./ConfirmPass";
import { viewProfile, getItems } from "../../store/actions";
import wallet from "../../hooks/metamask";
import Profileeditmod from "../../Component/Modals/Profileeditmod";
import ChangePassword from "../../Component/Modals/ChangePassword";
import MpinModel from "../../Component/Modals/MpinModel";
import VerifyMPIN from "../../Component/Modals/VerifyMPIN";
import toast from "react-hot-toast";
import Privatekeymodal from "Component/Modals/Privatekeymodal";


export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPvtKey, setshowPvtKey] = useState(false);
  const userWallet = wallet();
  const [showModal, setshowModal] = useState(false);
  const [profileedit, setprofileedit] = useState(false);
  const [changePassword, setchangePassword] = useState(false);

  const [addEditMpin, setaddEditMpin] = useState(false);
  const [verifyPin, setverifyPin] = useState(false);
  const [privatekeysteps, setprivatekeysteps] = useState(false);
  const { userDetails } = useSelector((state) => state.User);
  const { items, loading } = useSelector((state) => state.Items);

  const [state, setState] = useState({ tab: "All" });
  const [itemList, setitemList] = useState([]);



  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "_id",
    order: 1,
    page: 1,
    levels: 4,
    subcategory_id: "",
    collectionName: "All",
    shipsLevelStart: 1,
    shipsLevelEnd: 49,
    price: "",
  });
  useEffect(() => {
    dispatch(viewProfile());
  }, []);
  useEffect(() => {
    if (!userDetails?._id) return;
    dispatch(getItems({ ...filter, current_owner: userDetails?._id }));
  }, [filter, userDetails?._id]);

  useEffect(() => {
    if (items.length < 1) return;
    setitemList(items);
  }, [items]);



  return (
    <div className="profile_main position-relative">
      <ConfirmPass show={showModal} onhide={() => setshowModal(!showModal)} />
      <ChangePassword
        show={changePassword}
        userDetails={userDetails?.email}
        onHide={() => setchangePassword(!changePassword)}
      />
    

      {/* <MpinModel
        show={addEditMpin}
        isMpinExist={userDetails?.mpin}
        onHide={() => setaddEditMpin(!addEditMpin)}
      />
      <VerifyMPIN
        show={verifyPin}
        type="showPvtKey"
        setshowPvtKey={setshowModal}
        isMpinExist={userDetails?.mpin}
        onHide={() => setverifyPin(!verifyPin)}
      /> */}
      <Profileeditmod show={profileedit} onhide={() => setprofileedit(false)} />

      {/* <Privatekeymodal show={privatekeysteps} onhide={() => setprivatekeysteps(false)} /> */}


      <div className="overlay_col"></div>
      <section className="profile_section common_padding_main position-relative">
        <Container>
          <div className="profile_bio">
            <div className="profile_user">
              <div className="user_img">
                <img
                  src={userDetails?.profileImage?.link || "/images/profileuser.png"}
                  alt="profile image"
                  className="img-fluid"
                />
              </div>
              <div className="bio_content">
                <h4 className="">
                  {userDetails?.username || "Unnamed"}
                </h4>
                <h4>{userDetails?.email || "N/A"}</h4>
                {/* <h6>{userDetails?.walletAddress || "N/A"}</h6> */}
                <div className="d-flex">
                  {/* <p
                    className="view_private_key"
                    onClick={() => {
                      userDetails?.mpin == "none"
                        ? toast.error("Please Set a MPIN first")
                        : setverifyPin(true);
                    }}
                    // onClick={() => navigate("/privatekey-steps")}
                  >
                    {showPvtKey ? "Hide" : "Show"} Private Key
                  </p> */}

                  {/* {
                    <p
                      className={`pvtKey ${
                        showPvtKey ? "visible" : "invisible"
                      }`}
                    >
                      {userWallet.pvtKey}{" "}
                    </p>
                  } */}
                  {/* <p
                    className="view_private_key"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setaddEditMpin(true)}
                  >
                    Mpin setting
                  </p> */}
                </div>
              </div>
            </div>

            <div className="edit_btn">
              <Link
                style={{ width: "136px", marginTop: 10 }}
                to=""
                className="copy_address edit-btn btn btn-primary"
                onClick={() => setprofileedit(true)}
              >
                Edit Profile
              </Link>{" "}
              <Link
                style={{ width: "max-content", marginTop: 10 }}
                to=""
                className="copy_address edit-btn btn btn-primary"
                onClick={() => setchangePassword(true)}
              >
                Change password
              </Link>
            </div>
          </div>

    

          {/* <div className="profile-tabbing">
            <div className="tab-frame market_tabs">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="All"
                  checked={state.tab == "All"}
                  onClick={() => (
                    setState({ tab: "All" }),
                    setfilter((prev) => ({
                      ...prev,
                      current_owner: userDetails?._id,
                    }))
                  )}
                />
                <label for="All">All</label>
                <input
                  type="radio"
                  name="tab"
                  id="Pirates"
                  checked={state.tab == "Pirates"}
                  onClick={() => (
                    setitemList([]),
                    setState({ tab: "Pirates" }),
                    setfilter((prev) => ({
                      ...prev,
                      collectionName: "Pirates",
                      current_owner: userDetails?._id,
                    }))
                  )}
                />
                <label for="Pirates">Pirates</label>

                <input
                  type="radio"
                  name="tab"
                  id="Ships"
                  checked={state.tab == "Ships"}
                  onClick={() => (
                    setState({ tab: "Ships" }),
                    setitemList([]),
                    setfilter((prev) => ({
                      ...prev,
                      collectionName: "Ships",
                      current_owner: userDetails?._id,
                    }))
                  )}
                />
                <label for="Ships" className="ship_label">
                  Ships
                </label>

                <input
                  type="radio"
                  name="tab"
                  id="Others"
                  checked={state.tab == "Others"}
                  onClick={() => (
                    setitemList([]),
                    setState({ tab: "Others" }),
                    setfilter((prev) => ({
                      ...prev,
                      collectionName: "Others",
                      current_owner: userDetails?._id,
                    }))
                  )}
                />
                <label for="Others">Others</label>
              </div>

              {state.tab == "All" && (
                <Fade>
                  <div className="">
                    {itemList?.length > 0 ? (
                      <div className="invenPirates_here common_profile_tab">
                        <div className="parates_list profile_parates_list">
                          <ul>
                            {itemList.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/item-detail/${item?._id}`)
                                  }
                                >
                                  <div className="parates_div_main position-relative">
                                    <div className="nft_nameidhere">
                                      <p>{item?.name}</p>
                                    </div>
                                    <img
                                      src={
                                        item?.image_url ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />
                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : loading ? (
                      <div className="text-white">Loading...</div>
                    ) : (
                      itemList.length < 1 && (
                        <div className="no_data">No data found</div>
                      )
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Pirates" && (
                <Fade>
                  <div className="">
                    {itemList?.length > 0 ? (
                      <div className="invenPirates_here common_profile_tab">
                        <div className="parates_list profile_parates_list">
                          <ul>
                            {itemList.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/item-detail/${item?._id}`)
                                  }
                                >
                                  <div className="parates_div_main position-relative">
                                    <div className="nft_nameidhere">
                                      <p>{item?.name}</p>
                                    </div>
                                    <img
                                      src={
                                        item?.image_url ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />
                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : loading ? (
                      <div className="text-white">Loading...</div>
                    ) : (
                      itemList.length < 1 && (
                        <div className="no_data">No data found</div>
                      )
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Ships" && (
                <Fade>
                  <div className="">
                    {itemList.length > 0 ? (
                      <div className="inventShips-main common_profile_tab">
                        <div className="parates_list ship_list">
                          <ul>
                            {itemList.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/item-detail/${item?._id}`)
                                  }
                                >
                                  <div className="parates_div_main position-relative">
                                    <div className="nft_nameidhere">
                                      <p>{item?.name}</p>
                                    </div>
                                    <img
                                      src={
                                        item.image_url ||
                                        "/images/ship_level37_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="no_data">No data found</div>
                    )}
                  </div>
                </Fade>
              )}

              {state.tab == "Others" && (
                <Fade>
                  <div className="">
                    <div className="profileother_tab Pirates_here common_profile_tab">
                      <div className="parates_list">
                        {!loading && itemList.length > 0 ? (
                          <ul>
                            {itemList.map((item) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/item-detail/${item?._id}`)
                                  }
                                >
                                  <div className="parates_div_main position-relative">
                                    <div className="nft_nameidhere">
                                      <p>{item?.name}</p>
                                    </div>
                                    <img
                                      src={
                                        item?.image_url ||
                                        "/images/pirate_level4_bgimg.png"
                                      }
                                      alt=""
                                      className="img-fluid level_bg"
                                    />

                                    <div className="inventory_id">
                                      <p>{item?.name}</p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : loading ? (
                          <div className="text-white">Loading...</div>
                        ) : (
                          itemList.length < 1 && (
                            <div className="no_data text-center">
                              No data found
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          </div> */}
        </Container>
    
      </section>
    </div>
  );
}
