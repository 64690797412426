import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "../../helpers/backend_helper";
import toastr from "toastr";

function* viewProfile() {
  try {
    const response = yield call(API.getUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.viewProfileFailed(response.message));
    }
    localStorage.setItem("profileData", response?.data?.walletData);
    yield put(ACTIONS.viewProfileSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.viewProfileFailed("Internal Server Error"));
  }
}

function* sendOTPsaga({ payload: { data, callback } }) {
  try {
    const response = yield call(API.sendOTP, data);
    if (response.status == "failure") {
      callback(response);
      return yield put(ACTIONS.sendOTPFaild(response.message));
    }
    callback(response);
    yield put(ACTIONS.sendOTPSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.sendOTPFaild("Internal Server Error"));
  }
}
function* chantMPINsaga({ payload: { data, callback } }) {
  try {
    const response = yield call(API.changeOTP, data);
    if (response.status == "failure") {
      callback(response);
      return yield put(ACTIONS.changeMPINFaild(response.message));
    }
    callback(response);
    yield put(ACTIONS.changeMPINSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.changeMPINFaild("Internal Server Error"));
  }
}
function* verifyMPINsaga({ payload: { data, callback } }) {
  try {
    const response = yield call(API.verifyOTP, data);
    if (response.status == "failure") {
      callback(response);
      return yield put(ACTIONS.verifyMpinFaild(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.verifyMpinSuccess(response));
  } catch (error) {
    console.log(error, '54545454');
    yield put(ACTIONS.verifyMpinFaild("Internal Server Error"));
  }
}

function* removeProfileImage({ payload: { data, callback } }) {
  try {
    const response = yield call(API.removeUserProfileImage, data);
    if (response.status == "failure") {
      callback(response);
      return yield put(ACTIONS.removeProfileImageFailed(response.message));
    }
    callback(response);
    yield put(ACTIONS.removeProfileImageSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.removeProfileImageFailed("Internal Server Error"));
  }
}



function* viewUserBalance({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getUserBalance, data);
    callback && callback(response);

    if (response.status == "failure") {

      return yield put(ACTIONS.getUserBalanceFailed(response.message));
    }
    yield put(ACTIONS.getUserBalanceSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(ACTIONS.getUserBalanceFailed("Internal Server Error"));
  }
}

function* createWithdraw({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCreateWithdraw, data);

    if (response.status == "failure") {

      return yield put(ACTIONS.getCreateWithdrawFailed(response.message));
      callback && callback(response);

    }
    yield put(ACTIONS.getCreateWithdrawSuccess(response));
    callback && callback(response);

  } catch (error) {
    yield put(ACTIONS.getCreateWithdrawFailed("Internal Server Error"));
    callback && callback(error);

  }
}

function* getWithdrawHistory({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postWithdrawHistory, data);
    callback && callback(response);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.getWithdrawHistoryFail(response.message));
    }
    yield put(ACTIONS.getWithdrawHistorySuccess(response));
  } catch (error) {
    yield put(ACTIONS.getWithdrawHistoryFail(error));
  }
}

function* fetchPackages({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getCrystalPackage, data);
    callback && callback(response);

    if (response.status == "failure") {
      return yield put(ACTIONS.listCrystalPackageFail(response.message));
    }

    yield put(ACTIONS.listCrystalPackageSuccess(response));
  } catch (error) {
    yield put(ACTIONS.listCrystalPackageFail(error));
  }
}


function* getPackageHistory({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postPackageHistory, data);
    callback && callback(response);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.getPackageHistoryFail(response.message));
    }
    yield put(ACTIONS.getPackageHistorySuccess(response));
  } catch (error) {
    yield put(ACTIONS.getPackageHistoryFail(error));
  }
}

function* postBuyPackage({ payload: { data, callback } }) {
  console.log("dataaaa", data)

  try {
    const response = yield call(API.postBuyPackage, data?.data);

    if (response.status == "failure") {

      return yield put(ACTIONS.buyPackageFailed(response.message));
      callback && callback(response);

    }
    yield put(ACTIONS.buyPackageSuccess(response));
    callback && callback(response);

  } catch (error) {
    yield put(ACTIONS.buyPackageFailed("Internal Server Error"));
    callback && callback(error);

  }
}



export function* watchUser() {
  yield takeEvery(TYPES.VIEW_PROFILE, viewProfile);
  yield takeEvery(TYPES.SEND_OTP, sendOTPsaga);
  yield takeEvery(TYPES.VERIFY_MPIN, verifyMPINsaga);
  yield takeEvery(TYPES.CHANGE_MPIN, chantMPINsaga);
  yield takeEvery(TYPES.REMOVE_PROFILE_IMAGE, removeProfileImage);
  yield takeEvery(TYPES.GET_USER_BALANCE, viewUserBalance);
  yield takeEvery(TYPES.POST_CREATE_WITHDRAW, createWithdraw);
  yield takeEvery(TYPES.GET_WITHDRAW_HISTORY, getWithdrawHistory);
  yield takeEvery(TYPES.LIST_CRYSTAL_PACKAGE, fetchPackages);
  yield takeEvery(TYPES.GET_PACKAGE_HISTORY, getPackageHistory);
  yield takeEvery(TYPES.POST_BUY_PACKAGE, postBuyPackage);


}

function* UserSaga() {
  yield all([fork(watchUser)]);
}

export default UserSaga;
