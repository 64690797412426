import React from "react";
import Marketdash from "./Marketdash";

export default function Marketplace(shipdata) {
  return (
    <div className="mainpage-wrap position-relative">
      <div className="overlay_col"></div>
      <section className="marketplace-main position-relative">
        <div className="Market_full">
          <Marketdash />
        </div>
      </section>
    </div>
  );
}
