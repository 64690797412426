import * as TYPES from "./actionTypes";

/* Get Collection */
export const getCollection = (id, callback) => ({
  type: TYPES.GET_COLLECTION,
  payload: { id, callback },
});

export const getCollectionByAddress = (address, callback) => ({
  type: TYPES.GET_COLLECTION_BY_ADDRESS,
  payload: { address, callback },
});

export const getCollectionFail = (error) => ({
  type: TYPES.GET_COLLECTION_FAIL,
  payload: error,
});

export const getCollectionSuccess = (data) => ({
  type: TYPES.GET_COLLECTION_SUCCESS,
  payload: data,
});
/* Get Collection END */
