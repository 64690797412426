import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Progress } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate, useParams } from "react-router-dom";
import Buymod from "../../Component/Modals/Buymod";
import Successmodal from "../../Component/Modals/Successmodal";
import { getPageContent } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

export default function Inventorysingle(props) {
  const navigate = useNavigate();
  const { type } = useParams();

  const [Buyshow, setBuyshow] = useState(false);
  const [Successshow, setSuccessshow] = useState(false);

  const dispatch = useDispatch();
  const { pageContent } = useSelector((store) => store.Login);
  const togmodl = () => {
    setBuyshow(!Buyshow);
    setTimeout(() => {
      setSuccessshow(!Successshow);
    }, 400);
    setTimeout(() => {
      setSuccessshow(false);
      navigate("/marketplace");
    }, 3000);
  };

  useEffect(() => {
    if (!type) return;
    dispatch(getPageContent(type));
  }, [type]);

  return (
    <>
      <Buymod
        show={Buyshow}
        onhide={() => setBuyshow(false)}
        togmodl={togmodl}
      />
      <Successmodal show={Successshow} onhide={() => setSuccessshow(false)} />
      <section className="single_product_main common_padding_main position-relative">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="single_big_img position-relative">
                <a
                  href="javascript:void(0)"
                  style={{ marginTop: "-5rem" }}
                  onClick={() => navigate(-1)}
                  className="inventory_back"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g filter="url(#filter0_di_1_7208)">
                      <path
                        d="M14 23C7.92339 23 3 18.0766 3 12C3 5.92339 7.92339 1 14 1C20.0766 1 25 5.92339 25 12C25 18.0766 20.0766 23 14 23ZM8.94798 12.754L14.9581 18.7641C15.375 19.181 16.0492 19.181 16.4617 18.7641L17.2157 18.0101C17.6327 17.5931 17.6327 16.919 17.2157 16.5065L12.7093 12L17.2157 7.49355C17.6327 7.07661 17.6327 6.40242 17.2157 5.98992L16.4617 5.23589C16.0448 4.81895 15.3706 4.81895 14.9581 5.23589L8.94798 11.246C8.53105 11.6629 8.53105 12.3371 8.94798 12.754Z"
                        fill="url(#paint0_linear_1_7208)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_di_1_7208"
                        x="0"
                        y="0"
                        width="28"
                        height="28"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.116493 0 0 0 0 0.23887 0 0 0 0 0.254167 0 0 0 0.41 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_7208"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_7208"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect2_innerShadow_1_7208"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_7208"
                        x1="14"
                        y1="1"
                        x2="14"
                        y2="23"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#46C7A8" />
                        <stop
                          offset="1"
                          stop-color="#46C7A8"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  Go back
                </a>
                <div
                  className="text-white"
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{ __html: pageContent?.content }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
