import React from "react";

import Header from "../Component/Header/Header";
import RegisterScreen from "../Pages/Register/Register";

export default function Loginregtheme() {
  return (
    <>
      <Header extraClass="no_menu"/>
      <RegisterScreen />
    </>
  );
}
