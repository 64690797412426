import { convertPriceToEther } from "../utils/web3Intraction";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import bcrypt from "bcryptjs-react";

export const BLOCKCHAIN_CURRENCY_CODE = {
  ethereum: "ETH",
  polygon: "MATIC",
  BNB: "BNB",
};

export const BLOCKCHAIN_ENUM = [
  { label: "Ethereum", value: "ethereum" },
  { label: "Polygon", value: "polygon" },
  { label: "Binance Smart Chain", value: "BNB" },
];

export const ETHERSCAN = {
  testnet: {
    ethereum: "https://goerli.etherscan.io/",
    BNB: "https://testnet.bscscan.com/",
    polygon: "https://mumbai.polygonscan.com/",
  },
  mainnet: {
    ethereum: "https://etherscan.io/",
    BNB: "https://bscscan.com/",
    polygon: "https://polygonscan.com/",
  },
};

export const COMPILER_VERSION = "v0.8.17%2bcommit.8df45f5f";

export const getNetworkUrl = (type, settings) => {
  if (settings?.blockchain?.length === 0) {
    return null;
  }

  let blockchain = settings?.blockchain.filter((ele) => {
    return ele.status === true && ele.type === type;
  });

  if (blockchain?.length === 0) {
    return null;
  }

  blockchain = blockchain[0];

  if (blockchain?.networkUrl?.length === 0) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  let blockchainNetworkUrl = blockchain?.networkUrl.filter((ele) => {
    return ele.type === settings?.blockchainNetworkMode;
  });

  blockchainNetworkUrl = blockchainNetworkUrl[0];

  if (!blockchainNetworkUrl.url) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return blockchainNetworkUrl;
};

export const getContractDetails = (type, settings) => {
  if (settings.token && settings.token.contractAddress.length === 0) {
    return null;
  }

  let contractAddress = settings.token.contractAddress.filter((ele) => {
    return ele.type === type;
  });

  if (contractAddress.length === 0) {
    return null;
  }

  contractAddress = contractAddress[0];

  if (!contractAddress[settings.blockchainNetworkMode]) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return {
    abi: settings.token.abi,
    contractAddress: contractAddress[settings.blockchainNetworkMode],
  };
};

// export const getFeeCalucations = (item, setting) => {
//   console.log(item, setting, "item54564564");
//   let addressArray = [];
//   let priceArray = [];
//   console.log(item.price, setting.adminCommission, "item.price");
//   let originalPrice = item.price;
//   let admin_royalties =
//     Number(originalPrice) * (Number(setting.adminCommission) / 100);

//   originalPrice -= admin_royalties;

//   let finalPrice = 0;
//   console.log(originalPrice, "originalPrice");

//   if (item.author_id?._id === item.current_owner?._id) {
//     finalPrice = Number(originalPrice).toFixed(6);
//     console.log(finalPrice, "finalPrice");
//     console.log(item.current_owner?.walletAddress, "item.current_owner?.walletAddress");

//     addressArray.push(item.current_owner?.walletAddress);
//     console.log(addressArray,"addressArray")
//     priceArray.push(convertPriceToEther(finalPrice.toString()));
//    console.log(priceArray,"priceArray")
//   } else {
//     let royalties = Number(originalPrice) * (Number(item.royalties) / 100);
//     finalPrice = originalPrice - royalties;
//     // current owner royalities
//     addressArray.push(item.current_owner?.walletAddress);
//     priceArray.push(
//       convertPriceToEther(Number(finalPrice).toFixed(6).toString())
//     );

//     // author royalities
//     addressArray.push(item.author_id?.walletAddress);
//     priceArray.push(
//       convertPriceToEther(Number(royalties).toFixed(6).toString())
//     );
//   }

//   //owner
//   addressArray.push(setting.walletAddress.publicKey);
//   console.log(setting.walletAddress.publicKey,"setting.walletAddress.publicKey")
//   priceArray.push(
//     convertPriceToEther(Number(admin_royalties).toFixed(6).toString())
//   );
//   return {
//     address: addressArray,
//     price: priceArray,
//   };
// };

export const getFeeCalucations = (item, setting) => {
  let addressArray = [];
  let priceArray = [];

  let originalPrice = item.price;
  let admin_royalties =
    Number(originalPrice) * (Number(setting.adminCommission) / 100);

  originalPrice -= admin_royalties;

  console.log(originalPrice, "originalPrice");

  let finalPrice = 0;

  if (item.author_id._id === item.current_owner._id) {
    if (item.current_owner.walletAddress == setting.walletAddress.publicKey) {
      console.log("checkinggggggg");
      originalPrice += admin_royalties;
    }

    finalPrice = Number(originalPrice).toFixed(6);

    console.log(finalPrice, "finalPrice");

    // finalPrice = Number(finalPrice).toFixed(6);

    addressArray.push(item.current_owner.walletAddress);
    priceArray.push(convertPriceToEther(finalPrice.toString()));

    console.log(item.current_owner.walletAddress, "addressArray");
  } else {
    let royalties =
      Number(originalPrice) * (Number(item.collection_id.royalityFee) / 100);
    finalPrice = originalPrice - royalties;
    console.log(royalties, "royalties");
    // current owner royalities
    addressArray.push(item.current_owner.walletAddress);
    priceArray.push(
      convertPriceToEther(Number(finalPrice).toFixed(6).toString())
    );

    // author royalities
    addressArray.push(item.collection_id.artist);
    priceArray.push(
      convertPriceToEther(Number(royalties).toFixed(6).toString())
    );

    //owner
    console.log(
      setting.walletAddress.publicKey,
      "setting.walletAddress.publicKey"
    );
    addressArray.push(setting.walletAddress.publicKey);
    priceArray.push(
      convertPriceToEther(Number(admin_royalties).toFixed(6).toString())
    );
  }

  return {
    address: addressArray,
    price: priceArray,
  };
};

export const downloadTxtFile = (downloadText) => {
  if (downloadText?.length > 0) {
    const element = document.createElement("a");
    const file = new Blob([downloadText], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Wallet-details.txt";
    document.body.appendChild(element);
    element.click();
  }
};

export const ACTIVITY_TYPE = {
  minted: "Minted",
  transfer: "Transfer",
  admin_comission: "Admin Comission",
  bids: "Bid",
  comission: "Royality",
};

export const copyToClipboard = (text) => {
  var textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy"); // Security exception may be thrown by some browsers.
  } catch (ex) {
    // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
  } finally {
    document.body.removeChild(textarea);
  }
};

export const CHAINBYID = {
  137: "POLYGON",
  80001: "MUMBAI",
};

export const SendTokenAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const isAddressValid = (address) => {
  try {
    let isAddress = ethers.utils.isAddress(address);
    return isAddress;
  } catch (error) {
    return null;
  }
};

export const isNumberValid = (e) => {
  ["+", "e", "E", "-"].includes(e.key) && e.preventDefault();
};
export const isNumberValidForMPIN = (e) => {
  console.log(e.key);
  ![
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "F12",
    "Enter",
  ].includes(e.key) && e.preventDefault();
};

export function requestAndShowPermission() {
  let permissions = Notification.permission;
  if (permissions === "granted") {
    showNotification();
  } else if (permissions == "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then(function (permission) {
      console.log("permiss", permission);
    });
  }
}

export function showNotification(txHash, blockchain = "", blockchainNetworkMode = "") {
  let explorerUrl;
  if (blockchain == "polygon") {
    explorerUrl = `https://mumbai.polygonscan.com/tx/${txHash}`;
  } else {
    if (blockchainNetworkMode == "mainnet") {
      explorerUrl = `https://bscscan.com/tx/${txHash}`;
    } else {
      explorerUrl = `https://testnet.bscscan.com/tx/${txHash}`;
    }
  }
  <div>
    {toast.custom((t) => (
      <div
        onClick={() => toast.dismiss(t.id)}
        className={`${t.visible
            ? "animate-enter animate-enter-active "
            : "animate-leave animate-leave-active"
          } notification_div `}
      >
        <div
          className="d-flex-1 w-0 p-4"
          onClick={() => window.open(explorerUrl)}
        >
          <div className="d-flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <img
                className="h-10 w-10 rounded-full"
                src="../images/logo.png"
                alt="close_icon"
              />
            </div>
            <p className="text-sm font-medium text-gray-900">
              {`Transaction confirmed! View on ${blockchain == "polygon" ? "Polygon" : "Binance Smart Chain"} explorer`}
            </p>
          </div>
        </div>
        <div className="d-flex border-l border-gray-200">
          <button className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
            <img
              src="../images/close_img.png"
              className="h-10 w-10 rounded-full"
            />
          </button>
        </div>
      </div>
    ))}
  </div>;
}

export const sendTypes = {
  MATIC: "balance",
  PPT: "PPT_balance",
};

export const hashPassword = async (password) => {
  const saltRounds = 10;
  const salt = await bcrypt.genSalt(saltRounds);
  const hashedPassword = await bcrypt.hash(password, salt);

  return {
    salt: salt.toString("hex"),
    hashedPassword: hashedPassword,
  };
};

export let compareHashPassword = async (password, hash) => {
  let currectPassword;
  return (currectPassword = await bcrypt.compare(password, hash));
};

export let customABI = {
  inputs: [
    {
      internalType: "address",
      name: "spender",
      type: "address",
    },
    {
      internalType: "uint256",
      name: "addedValue",
      type: "uint256",
    },
  ],
  name: "increaseAllowance",
  outputs: [
    {
      internalType: "bool",
      name: "",
      type: "bool",
    },
  ],
  stateMutability: "nonpayable",
  type: "function",
};

export const levelObj = {
  1: "singlelevel_one_bar",
  2: "singlelevel_two_bar",
  3: "singlelevel_three_bar",
  4: "singlelevel_four_bar",
  5: "singlelevel_five_bar",
};

export const levelFilter = {
  All: {
    minValue: 1,
    maxValue: 49,
  },
  Schooner: {
    minValue: 10,
    maxValue: 19,
  },
  Brigantine: {
    minValue: 20,
    maxValue: 29,
  },
  Galleon: {
    minValue: 30,
    maxValue: 39,
  },
  Sloop: {
    minValue: 1,
    maxValue: 9,
  },
  Frigate: {
    minValue: 40,
    maxValue: 49,
  },
};

export function startCountdown(seconds) {
  let counter = seconds;
  const interval = setInterval(() => {
    counter--;
    if (counter < 0) {
      clearInterval(interval);
      console.log("Ding!");
    }
    return counter;
  }, 1000);
  return counter;
}

export const barObj = {
  1: "singlelevel_one_line",
  2: "singlelevel_two_line",
  3: "singlelevel_three_line",
  4: "singlelevel_four_line",
  5: "singlelevel_five_line",
  Sloop: "singlelevel_one_line",
  Schooner: "singlelevel_two_line",
  Brigantine: "singlelevel_three_line",
  Galleon: "singlelevel_four_line",
  Frigate: "singlelevel_five_line",
};

export const stringShortner = (string, limit) => {

  try {
    if (string.length <= limit) {
      return string;
    }
    return string.slice(0, limit) + "...";
  } catch (e) {
    return string;
  }

}

export const busdLiveContractAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

export const busdTestNetContractAddress = "0x3681a42E747794D110a1F1Dad66D3cbac8653422";

export const usdtTestNetContractAddress = "0x0803812F68aEfEfF0df397f47EA5DaA8893a6127";

export const usdtLiveContractAddress = "0x55d398326f99059fF775485246999027B3197955";