import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <section className="footer-main position-relative">
      <div className="custom_container">
        <Row>
          <Col lg={6} md={12} sm={12}>
            <div className="footer_liks">
              {/* <div class="footerUl">
                <ul>
                  <li>
                    <a href="javascript:void(0)">Home</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" target="_blank">
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">How To Play</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="javascript:void(0)">Pitchdeck</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Roadmap</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Guide</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="javascript:void(0)">Marketplace</a>
                  </li>
                  <li>
                    <a class="scroll-link" href="javascript:void(0)">
                      Characters
                    </a>
                  </li>
                  <li>
                    <a class="scroll-link" href="javascript:void(0)">
                      Our Team
                    </a>
                  </li>
                </ul>
              </div> */}
              <div class="footerUl">
                <ul>
                  <li>
                    <a class="scroll-link" href="https://piratepets.io#home" target="_blank">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="https://whitepaper.piratepets.io/" target="_blank">
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a
                      class="scroll-link"
                      href="https://piratepets.io#howToPlay"
                      target="_blank"
                    >
                      How To Play
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="https://piratepets.io/pitchdeck.php" target="_blank">Pitchdeck</a>
                  </li>
                  <li>
                    <a class="scroll-link" href="https://piratepets.io#roadmap" target="_blank">
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a href="https://piratepets.io/guide.php" target="_blank">Guide</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/marketplace">Marketplace</Link>
                  </li>
                  <li>
                    <a
                      class="scroll-link"
                      href="https://piratepets.io/history.php"
                      target="_blank"
                    >
                      Characters
                    </a>
                  </li>
                  <li>
                    <a class="scroll-link" href="https://piratepets.io#ourteam" target="_blank">
                      Our Team
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <ul className="social-icons">
          <li>
            <a href="https://twitter.com/PiratePets_Club" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M21.81 6.227c.058-.08-.028-.185-.12-.149a8.489 8.489 0 0 1-2.05.513a4.13 4.13 0 0 0 1.72-2.02c.034-.086-.06-.163-.14-.118c-.727.401-1.51.69-2.325.857a.1.1 0 0 1-.093-.03a4.1 4.1 0 0 0-6.991 3.65a.102.102 0 0 1-.104.123a11.64 11.64 0 0 1-8.224-4.17a.098.098 0 0 0-.163.015a4.16 4.16 0 0 0-.48 1.943a4.09 4.09 0 0 0 1.82 3.41a4.05 4.05 0 0 1-1.709-.43c-.068-.035-.15.014-.147.09a4.23 4.23 0 0 0 .933 2.468A4.1 4.1 0 0 0 6.1 13.79a3.93 3.93 0 0 1-1.1.17a4.901 4.901 0 0 1-.606-.045c-.075-.01-.136.06-.11.13A4.11 4.11 0 0 0 8.06 16.73a8.22 8.22 0 0 1-5.625 1.741c-.106-.007-.155.134-.064.188a11.57 11.57 0 0 0 5.919 1.62A11.59 11.59 0 0 0 20 8.6v-.48a.1.1 0 0 1 .04-.08a8.433 8.433 0 0 0 1.77-1.813Z"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://t.me/+e3XvFKtqfNQ3ZjVi" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#fff"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01c-.378.15-.577.298-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294c.26.006.549-.1.868-.32c2.179-1.471 3.304-2.214 3.374-2.23c.05-.012.12-.026.166.016c.047.041.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817c-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088c.327.216.589.393.85.571c.284.194.568.387.936.629c.093.06.183.125.27.187c.331.236.63.448.997.414c.214-.02.435-.22.547-.82c.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315a.337.337 0 0 0-.114-.217a.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://discord.com/invite/D6gq9Uzf6p" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://piratepets.io/#home" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#fff"
                  d="M248 104a32 32 0 0 0-52.94-24.19c-16.75-8.9-36.76-14.28-57.66-15.53l5.19-31.17l17.72 2.72a24 24 0 1 0 2.87-15.74l-26-4a8 8 0 0 0-9.11 6.59l-6.87 41.48c-21.84.94-42.82 6.38-60.26 15.65a32 32 0 0 0-42.59 47.74A59 59 0 0 0 16 144c0 21.93 12 42.35 33.91 57.49C70.88 216 98.61 224 128 224s57.12-8 78.09-22.51C228 186.35 240 165.93 240 144a59 59 0 0 0-2.35-16.45A32.16 32.16 0 0 0 248 104Zm-64-80a8 8 0 1 1-8 8a8 8 0 0 1 8-8ZM72 128a16 16 0 1 1 16 16a16 16 0 0 1-16-16Zm91.75 55.07a76.18 76.18 0 0 1-71.5 0a8 8 0 1 1 7.5-14.14a60.18 60.18 0 0 0 56.5 0a8 8 0 1 1 7.5 14.14ZM168 144a16 16 0 1 1 16-16a16 16 0 0 1-16 16Z"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://piratepets.io/#home" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#fff"
                  d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236c-1.246 0-2.256-1.897-2.256-4.236c0-2.34 1.01-4.236 2.256-4.236c1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795c-.438 0-.793-1.7-.793-3.795c0-2.096.355-3.795.794-3.795c.438 0 .793 1.699.793 3.795z"
                />
              </svg>
            </a>
          </li>
        </ul>

        <div className="Copyright-fotter">
          <p>Copyright © 2020 Pirate Pets Team. All rights reserved.@2023</p>
          <div class="socialdown">
            <ul>
              <li>
                <a href="https://piratepets.io/#" target="_blank">Terms of Use</a>
              </li>
              <li>
                <a href="https://piratepets.io/#" target="_blank">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
