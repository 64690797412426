import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "../../actions";
import * as API from "../../../helpers/backend_helper";

import { Toast } from "react-bootstrap";

import { updateToken } from "../../../helpers/api_helper";
import { updateGameToken } from "../../../helpers/game_api_helper";

function* loginUser({ payload: { user, naviagte } }) {
  try {
    const response = yield call(API.postLogin, user);
    console.log(response, "res");

    if (response.status == "failure") {
      return yield put(ACTIONS.apiError(response.message));
    }

    localStorage.setItem("authUser", JSON.stringify(response));
    // localStorage.setItem("profileData", response.data.walletData);
    updateToken(response.data.Token);
    updateGameToken(response.data.Token);
    naviagte("/");
    yield put(ACTIONS.loginSuccess(response));
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* onLoginWithWallet({ payload: { user, naviagte } }) {
  try {
    const response = yield call(API.loginWithWallet, user);
    console.log(response, "res");

    if (response.status == "failure") {
      return yield put(ACTIONS.apiError(response.message));
    }

    localStorage.setItem("authUser", JSON.stringify(response));
    // localStorage.setItem("profileData", response.data);
    updateToken(response.data.Token);
    naviagte("/");
    yield put(ACTIONS.loginWithWalletSuccess(response));
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* onupdateUser({ payload: { data, callback } }) {
  try {
    const response = yield call(API.updateUser, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.updateUserFail(response.message));
    }
    yield put(ACTIONS.updateUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.updateUserFail(error));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(ACTIONS.logoutUserSuccess());
    history("/marketplace");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* onGetSettings({ payload }) {
  try {
    const response = yield call(API.getSettings);

    if (response.status === "failure") {
      return yield put(ACTIONS.getSettingsFail());
    }

    yield put(ACTIONS.getSettingsSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.getSettingsFail(error));
  }
}

function* onGetPageContent({ payload }) {
  try {
    const response = yield call(API.getContentPage, payload);

    if (response.status === "failure") {
      return yield put(ACTIONS.getPageContentFail());
    }
    yield put(ACTIONS.getPageContentSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getPageContentFail(error));
  }
}
function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.LOGIN_WITH_WALLET, onLoginWithWallet);
  yield takeEvery(TYPES.GET_PAGE_CONTENT, onGetPageContent);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
  yield takeEvery(TYPES.GET_SETTINGS, onGetSettings);
  yield takeEvery(TYPES.UPDATE_USER, onupdateUser);
}

export default authSaga;
